<div id="main">

    <div *ngIf="showTopBox">
        <h2>Summary</h2>
    </div>

    <div class="body">
        <div *ngIf="showItemDetails">
            <xion-cart-items-summary [isRemovable]="itemsRemovable"
                                     (buttonClicked)="buttonClicked.emit(true)"
                                     [userCart]="userCart">
            </xion-cart-items-summary>
        </div>

        <div class="relative">
            <xion-loader id="calc-loader" [isLoading]="true" [absolutePosition]="true" *ngIf="isCalculating"></xion-loader>
            <div id="calculations" [class.border-top]="showItemDetails" [class.invisible]="isCalculating">
                <div>
                    <h3 class="float-left">Subtotal</h3>
                    <h3 class="float-right">{{subtotal | currency:'USD'}}</h3>
                </div>
                <div *ngIf="discount && !isCartView">
                    <h3 class="float-left">Discount</h3>
                    <h3 class="float-right">{{discount | currency:'USD'}}</h3>
                </div>
                <div *ngIf="!isCartView">
                    <h3 class="float-left">Shipping</h3>
                    <h3 class="float-right">{{shipping | currency:'USD'}}</h3>
                </div>
                <div *ngIf="!isCartView">
                    <h3 class="float-left">Total before tax</h3>
                    <h3 class="float-right">{{subtotal + shipping - discount | currency:'USD'}}</h3>
                </div>
                <div *ngIf="!isCartView">
                    <h3 class="float-left">Tax</h3>
                    <h3 class="float-right">{{tax | currency:'USD'}}</h3>
                </div>
            </div>
        </div>

        <div id="total" *ngIf="!isCartView">
            <div class="bold">
                <h3 class="float-left">Order Total</h3>
                <h3 class="float-right">{{subtotal + shipping + tax - discount | currency:'USD'}}</h3>
            </div>
        </div>

        <button *ngIf="showButton"
                id="place-order-btn"
                type="submit"
                [disabled]="!formValid"
                [class.disabledButton]="placeOrderButton.disabled"
                #placeOrderButton
                (click)="buttonClicked.emit(true)">
            Place your order
        </button>
    </div>
</div>

