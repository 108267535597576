import { TalkService } from './../talk/talk.service';
import { Component, OnInit } from '@angular/core';
import { TalkjsBubblePromptService } from './talkjs-bubble-prompt.service';
import {
    trigger,
    state,
    style,
    transition,
    animate,
    keyframes,
} from '@angular/animations';

@Component({
    selector: 'xion-talkjs-bubble-prompt',
    template: `
        <div
            *ngIf="
                talkjsBubblePromptService.isOpen &&
                !talkjsBubblePromptService.alreadyHappenedOnce
            "
            [@wiggleOnInit]="
                talkjsBubblePromptService.isOpen &&
                !talkjsBubblePromptService.alreadyHappenedOnce
            "
            id="main"
        >
            <div class="upper-container">
                <button class="x" (click)="cancelBtn()">&#215;</button>
                <div class="message">
                    {{ talkjsBubblePromptService.message }}
                </div>
                <div
                    id="img"
                    [style.background-image]="
                        'url(' + talkjsBubblePromptService.pic + ')'
                    "
                ></div>
            </div>

            <button class="chat" mat-button (click)="openChatPopup()">
                Chat
            </button>
        </div>
    `,
    styles: [
        `
            #main {
                position: fixed;
                bottom: 95px;
                right: 20px;
                z-index: 10;
                border: 1px solid grey;
                border-radius: 8px;
                max-width: 355px;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
                    0 1px 2px rgba(0, 0, 0, 0.24);
                background: white;
            }
            div.upper-container {
                display: flex;
                padding: 11px;
            }
            button.x {
                color: white;
                background: #b7b7b7;
                border: none;
                position: relative;
                width: 30px;
                height: 30px;
                text-align: center;
                font-size: 27px;
                line-height: 29px;
                padding: 0;
                border-radius: 50%;
                align-self: auto;
                margin-right: 11px;
                flex-grow: 0;
                -webkit-flex-grow: 0;
                flex-shrink: 0;
                -webkit-flex-shrink: 0;
            }
            button.x:hover {
                background: #a2a2a2;
            }
            div.message {
                font-size: 14px;
                line-height: 1.3;
                font-weight: 500;
                margin-right: 8px;
            }
            div#img {
                align-self: center;
                overflow: hidden;
                background-color: #fff;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                position: relative;
                border: 1px solid #b7b7b7;
                width: 50px;
                border-radius: 100px;
                height: 50px;
                flex-shrink: 0;
                -webkit-flex-shrink: 0;
                flex-grow: 0;
                -webkit-flex-grow: 0;
            }
            button.chat {
                width: 100%;
                background: none;
                color: #1e92f1;
                border: none;
                border-top: 1px solid #c1c1c1;
                padding: 5px;
                font-size: 20px;
                font-weight: 600;
            }
            @media only screen and (max-width: 700px) {
                #main {
                    max-width: calc(100% - 40px);
                }
            }
        `,
    ],
    animations: [
        // TODO: make wiggle instead of fade in
        trigger('wiggleOnInit', [
            transition(':enter', [
                style({
                    opacity: 0,
                }),
                animate(
                    '300ms',
                    style({
                        opacity: 1,
                    })
                ),
                animate(
                    '1000ms ease-in',
                    keyframes([
                        style({ transform: 'rotate(-1deg)', offset: 0.1 }),
                        style({ transform: 'rotate(4deg)', offset: 0.2 }),
                        style({ transform: 'rotate(-6deg)', offset: 0.3 }),
                        style({ transform: 'rotate(6deg)', offset: 0.4 }),
                        style({ transform: 'rotate(-6deg)', offset: 0.5 }),
                        style({ transform: 'rotate(6deg)', offset: 0.6 }),
                        style({ transform: 'rotate(-6deg)', offset: 0.7 }),
                        style({ transform: 'rotate(4deg)', offset: 0.8 }),
                        style({ transform: 'rotate(-1deg)', offset: 0.9 }),
                    ])
                ),
                // animate('1000ms ease-in', keyframes([
                //     style({transform: 'translate3d(-1px, 0, 0)', offset: 0.1}),
                //     style({transform: 'translate3d(2px, 0, 0)', offset: 0.2}),
                //     style({transform: 'translate3d(-4px, 0, 0)', offset: 0.3}),
                //     style({transform: 'translate3d(4px, 0, 0)', offset: 0.4}),
                //     style({transform: 'translate3d(-4px, 0, 0)', offset: 0.5}),
                //     style({transform: 'translate3d(4px, 0, 0)', offset: 0.6}),
                //     style({transform: 'translate3d(-4px, 0, 0)', offset: 0.7}),
                //     style({transform: 'translate3d(2px, 0, 0)', offset: 0.8}),
                //     style({transform: 'translate3d(-1px, 0, 0)', offset: 0.9}),
                //   ])),
            ]),
            transition(':leave', [
                style({
                    opacity: 1,
                }),
                animate(
                    '300ms',
                    style({
                        opacity: 0,
                    })
                ),
            ]),
        ]),
        // trigger( 'wiggleOnInit', [
        //     transition(
        //         ':enter', [
        //             style({
        //                 opacity: 0,
        //             }),
        //             animate('300ms', style({
        //                 opacity: 1,
        //             }))
        //         ]
        //     ),
        //     transition(
        //         ':leave', [
        //             style({
        //                 opacity: 1,
        //             }),
        //             animate('300ms', style({
        //                 opacity: 0,
        //             }))
        //         ]
        //     )]
        // ),
    ],
})
export class TalkjsBubblePromptComponent implements OnInit {
    constructor(
        public talkjsBubblePromptService: TalkjsBubblePromptService,
        private talkService: TalkService
    ) {}

    ngOnInit() {}

    openChatPopup() {
        this.talkService.openCurrentChatPopup();
        this.talkjsBubblePromptService.alreadyHappenedOnce = true;
    }

    cancelBtn() {
        this.talkjsBubblePromptService.isOpen = false;
        this.talkjsBubblePromptService.alreadyHappenedOnce = true;
    }
}
