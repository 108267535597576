import { Injectable } from '@angular/core';
import * as Raven from 'raven-js';
import { GlobalEventsService } from '../../../shared/global-events.service';
import { XRDService, XRDEntity } from '../../../shared/xrd';
import * as _ from 'underscore';
import { ConstantsService } from '../../../shared/xion-constants.service';
import { ICartItemSummary } from '../cart-items-summary/cart-item-summary/cart-item-summary.model';
import { CartService } from '../../cart/cart-service/cart.service';
import { Cart } from '../../cart/cart.model';
import { XRDOrder } from '../../../shared/xrd/order/xrd-order.model';
import { XRDOrderItem } from '../../../shared/xrd/order/xrd-order-item.model';
import { state } from '@angular/animations';
import { EMPTY, Observable } from 'rxjs';

declare var FS: any;

declare var ga: any;

/** source: https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.12 */
declare var fbq: any;

/** source: https://developer.drip.com/?javascript#identifying-visitors */
declare var _dcq: any;

// Bing
declare var uetq: any;

// TODO: Kev, this can probably be removed. I commented out all the methods.
@Injectable()
export class ThirdPartySessionTrackingService {
    constructor(
        private geService: GlobalEventsService,
        private constantsService: ConstantsService,
        // private cartService: CartService,
        private xrdService: XRDService
    ) {}

    /**
     * Sets the session for 3rd party user tracking applications:
     * Sentry, Fullstory, Inspectlet
     */
    setSession(firstName: string, lastName: string, email: string, id): void {
        // let username = firstName + ' ' + lastName;
        // // Sentry
        // Raven.setUserContext({
        //     username: username,
        //     email: email,
        //     id: id,
        // });
        // // FullStory (prod only)
        // FS.identify(id, {
        //     displayName: username,
        //     email: email,
        // });
        // _dcq.push([
        //     'identify',
        //     {
        //         email: email,
        //         user_id: id,
        //         first_name: firstName,
        //         last_name: lastName,
        //         tags: ['New User'],
        //         success: function (response) {
        //             console.log('DRIP identify:', response);
        //             // Call a method with the response object
        //             // Success callback is optional
        //         },
        //     },
        // ]);
    }

    /**
     * clears the 3rd party user tracking on logout
     * (previously experienced problems with user identity on same browser logging on and off)
     */
    clearSession() {
        // Raven.setUserContext();
        // FullStory (prod only)
        // FS.identify(false);
        // TODO: This doesn't work. There isn't really an easy way to clear the drip session as of right now
        // _dcq.push(["identify", {}]);
    }

    trackPageChange(pageUrl: string): void {
        // if (!this.geService.isRunOnBrowser()) return;
        // // Hacky, I know. But the page title isn't accurate unless we use setTimout here.
        // setTimeout(() => {
        //     // GA
        //     ga('set', 'page', pageUrl);
        //     ga('send', 'pageview');
        //     // Bing
        //     uetq.push('event', 'page_view', { page_path: '/spa_page' });
        // }, 0);
    }

    sendGACustomEvent(category, action, label, value: number): void {
        // if (!this.geService.isRunOnBrowser()) return;
        // // console.log('category:', category);
        // // console.log('action:', action);
        // // console.log('label:', label);
        // // console.log('value:', value);
        // ga(
        //     'send',
        //     {
        //         hitType: 'event',
        //         eventCategory: category,
        //         eventAction: action,
        //         eventLabel: label,
        //         eventValue: value,
        //     },
        //     { nonInteraction: true }
        // ); // Because product page views aren't interactive (shouldn't be used to determine bounce rate)
    }

    setDripSessionEmailOnly(email: string) {
        // _dcq.push([
        //     'identify',
        //     {
        //         email: email,
        //         tags: ['New User'],
        //         success: function (response) {
        //             console.log('DRIP (email only) identify:', response);
        //             // Call a method with the response object
        //             // Success callback is optional
        //         },
        //     },
        // ]);
    }

    identifyDripRetailer(email: string, entitySeoName: string) {
        // _dcq.push([
        //     'identify',
        //     {
        //         email: email,
        //         entity_seoname: entitySeoName,
        //         tags: ['New Retailer on XION'],
        //         success: function (response) {
        //             console.log('DRIP retailer identify:', response);
        //         },
        //     },
        // ]);
    }

    // email is required
    setDripSessionReviewed(
        email,
        firstName,
        lastName,
        phoneNumber,
        reviewee,
        numOfStars: number
    ) {
        // let payload: any = {
        //     email: email,
        //     tags: [
        //         'New User',
        //         `Reviewed ${reviewee}`,
        //         `Reviewed ${reviewee} ${numOfStars} Star`,
        //     ],
        //     success: function (response) {
        //         console.log('DRIP (email only) review identify:', response);
        //     },
        // };
        // if (firstName) payload.first_name = firstName;
        // if (lastName) payload.last_name = lastName;
        // if (phoneNumber) payload.phone_number = phoneNumber;
        // _dcq.push(['identify', payload]);
    }

    addAbandonedCartDripTag(dripCart) {
        // // let payload = {
        // //     email: email,
        // //     tags: ["Abandoned Cart"],
        // //     success: function(response) {
        // //         console.log('DRIP abandoned cart tag applied:', response);
        // //         // Call a method with the response object
        // //         // Success callback is optional
        // //     }
        // // };
        // // _dcq.push(["identify", payload]);
        // this.xrdService.createUpdateDripCart(dripCart).subscribe();
    }

    orderCreated(
        email,
        entity: XRDEntity,
        order: XRDOrder,
        cartItems: ICartItemSummary[]
    ) {
        // // Add Google Analytics for the order and each orderItem
        // this.googleAnalyticsCreateOrder(entity, order);
        // // Bing Ad revenue tracking
        // let bingOrderTotal =
        //     Number(order.subtotal) + Number(order.shipping) + Number(order.tax);
        // bingOrderTotal = Math.round(bingOrderTotal * 100) / 100; // Nearest two decimal places
        // uetq.push({
        //     ec: 'Checkout',
        //     ea: 'Purchase',
        //     el: 'Purchase',
        //     gv: bingOrderTotal,
        // });
        // let entityPhone;
        // if (entity.phones && entity.phones.length) {
        //     entityPhone = entity.phones[0].phonenumber;
        // }
        // let entityEmail;
        // if (entity.emails && entity.emails.length) {
        //     entityEmail = entity.emails[0].emailaddress;
        // }
        // let dripOrder = {
        //     email: email,
        //     identifier: order.ordernumber,
        //     upstream_id: order.orderid,
        //     provider: 'xion',
        //     amount:
        //         this.pennyValueOf(order.subtotal) +
        //         this.pennyValueOf(order.tax) +
        //         this.pennyValueOf(order.shipping),
        //     tax: this.pennyValueOf(order.tax),
        //     fees: this.pennyValueOf(order.shipping),
        //     discount: 0, // TODO: when promo code is de-commented out, be sure to include here
        //     permalink:
        //         this.constantsService.getFrontendURL() +
        //         'your-orders/' +
        //         order.ordernumber,
        //     currency_code: 'USD',
        //     financial_state: 'authorized',
        //     fulfillment_state: 'not_fulfilled',
        //     properties: {
        //         shipping_type: order.ordershippingtype,
        //         entity_id: entity.entityid,
        //         entity_name: entity.name,
        //         entity_seoname: entity.seoname,
        //         entity_webstore_link:
        //             this.constantsService.getFrontendURL() + entity.seoname,
        //         entity_phone: entityPhone,
        //         entity_email: entityEmail,
        //     },
        //     items: [],
        // };
        // // Remember: shipping_address in Drip system is the entity's address in our system
        // if (entity.addresses && entity.addresses.length) {
        //     dripOrder['shipping_address'] = {
        //         name: entity.name,
        //         company: entity.name,
        //         address_1: entity.addresses[0].address1,
        //         address_2: entity.addresses[0].address2,
        //         city: entity.addresses[0].city,
        //         state: entity.addresses[0].state,
        //         zip: entity.addresses[0].zip,
        //     };
        //     if (entityPhone)
        //         dripOrder['shipping_address']['phone'] = entityPhone;
        //     if (entityEmail)
        //         dripOrder['shipping_address']['email'] = entityEmail;
        // }
        // // Remember: billing_address in Drip system is the customer's shipping address in our system
        // if (order.addresses && order.addresses.length) {
        //     dripOrder['billing_address'] = {
        //         name: order.addresses[0].fullname,
        //         address_1: order.addresses[0].address1,
        //         address_2: order.addresses[0].address2,
        //         city: order.addresses[0].city,
        //         state: order.addresses[0].state,
        //         zip: order.addresses[0].zip,
        //     };
        //     if (order.phones && order.phones.length)
        //         dripOrder['billing_address']['phone'] =
        //             order.phones[0].phonenumber;
        //     if (order.emails && order.emails.length)
        //         dripOrder['billing_address']['email'] =
        //             order.emails[0].emailaddress;
        // }
        // // for the cartItems
        // let i = 0;
        // for (let item of order.items) {
        //     // Set the other required fields
        //     for (let cartItem of cartItems) {
        //         if (item.productid === cartItem.productID) {
        //             item['productseoname'] = cartItem.seoName;
        //             item['productcategory'] = cartItem.category;
        //             item['productsubcategory'] = cartItem.subCategory;
        //             break;
        //         }
        //     }
        //     this.googleAnalyticsCreateOrderItem(order.orderid, item);
        //     dripOrder.items.push({
        //         product_id: item.productid,
        //         sku: 'XION.store_marketplace_order', // IMPORTANT: this is how we distinguish an online order from a Lightspeed generated order in the Drip workflows
        //         upstream_product_variant_id: item.productvariantid,
        //         amount: this.pennyValueOf(item.price),
        //         name:
        //             item.brand +
        //             ' ' +
        //             item.name +
        //             (item.modelyear ? ' ' + item.modelyear : ''),
        //         quantity: item.quantity,
        //         price: this.pennyValueOf(item.price),
        //         properties: {
        //             link:
        //                 this.constantsService.getFrontendURL() +
        //                 'product/' +
        //                 item['productseoname'],
        //             review_link:
        //                 this.constantsService.getFrontendURL() +
        //                 'product/' +
        //                 item['productseoname'] +
        //                 ';review=true',
        //             primary_image: item.primaryimage,
        //             shipping_type: order.ordershippingtype,
        //             brand: item.brand,
        //             category: item['productcategory'],
        //             sub_category: item['productsubcategory'],
        //             color: item.color,
        //             size: item.size,
        //         },
        //     });
        // }
        // console.log('DRIP Order:', dripOrder);
        // let retailerDripOrder = JSON.parse(JSON.stringify(dripOrder));
        // retailerDripOrder[
        //     'permalink'
        // ] = `${this.constantsService.getFrontendURL()}${
        //     entity.seoname
        // }/admin/orders/single-order/${order.orderid}`;
        // // Notify retailer of sale
        // this.dripCreateCustomEvent(
        //     entityEmail,
        //     'Received new order',
        //     retailerDripOrder
        // );
        // // Drip
        // this.xrdService.createUpdateDripOrder(dripOrder).subscribe();
        // this.dripUpdateSubscriber(email).subscribe();
        // this.removeAbandonedCartDripTag(email);
        // // Google Analytics
        // this.googleAnalyticsSend();
    }

    private pennyValueOf(value): number {
        return Math.round(Number(value) * 100);
    }

    dripCreateCustomEvent(email: string, action: string, properties: any) {
        // const event = {
        //     email: email,
        //     action: action,
        //     properties: properties,
        // };
        // this.xrdService.createDripCustomEvent(event).subscribe();
    }

    /**
     * financial_state valid values are as follows:
     * pending, authorized, partially_paid, paid, partially_refunded, refunded, voided
     */
    dripOrderFinancialUpdate(
        order: XRDOrder,
        financialState,
        notes?,
        entitySeoName?
    ) {
        // let dripOrder = {
        //     email: order.emails[0].emailaddress,
        //     upstream_id: order.orderid,
        //     provider: 'xion',
        //     financial_state: financialState,
        // };
        // if (financialState === 'voided') {
        //     dripOrder['cancelled_at'] = new Date().toISOString();
        // }
        // if (financialState === 'voided' || financialState === 'refunded') {
        //     dripOrder['properties'] = {
        //         entity_webstore_link:
        //             this.constantsService.getFrontendURL() + entitySeoName,
        //         shipping_type: order.ordershippingtype,
        //     };
        //     if (notes) dripOrder['properties']['notes'] = notes;
        // }
        // console.log('DRIP financial update:', dripOrder);
        // // Drip
        // this.xrdService.createUpdateDripOrder(dripOrder).subscribe();
    }

    dripOrderRefund(orderId, amount, note) {
        // let dripRefund = {
        //     provider: 'xion',
        //     order_upstream_id: orderId,
        //     amount: this.pennyValueOf(amount),
        // };
        // if (note) dripRefund['note'] = note;
        // console.log('DRIP refund:', dripRefund);
        // // Drip
        // this.xrdService.createUpdateDripRefund(dripRefund).subscribe();
    }

    /**
     * Only happens for "ground shipping" type when marked as "Shipped"
     * fulfillment_state valid values are as follows:
     * not_fulfilled, partially_fulfilled, fulfilled
     */
    dripOrderFulfillmentUpdate(
        order: XRDOrder,
        fulfillmentState,
        entitySeoName
    ) {
        // let dripFulfillment = {
        //     email: order.emails[0].emailaddress,
        //     upstream_id: order.orderid,
        //     provider: 'xion',
        //     fulfillment_state: fulfillmentState,
        // };
        // if (
        //     order.items &&
        //     order.items.length &&
        //     order.items[0].trackings &&
        //     order.items[0].trackings.trackingnumber &&
        //     order.items[0].trackings.shippingvendors &&
        //     order.items[0].trackings.shippingvendors.url
        // ) {
        //     dripFulfillment['properties'] = {
        //         entity_webstore_link:
        //             this.constantsService.getFrontendURL() + entitySeoName,
        //         shipping_type: order.ordershippingtype,
        //         shipping_tracking_courier:
        //             order.items[0].trackings.shippingvendors.name,
        //         shipping_tracking_number:
        //             order.items[0].trackings.trackingnumber,
        //         shipping_tracking_link: this.getTrackingUrl(
        //             order.items[0].trackings.shippingvendors.url,
        //             order.items[0].trackings.trackingnumber
        //         ),
        //     };
        // }
        // console.log('DRIP fulfillment:', dripFulfillment);
        // // Drip
        // this.xrdService.createUpdateDripOrder(dripFulfillment).subscribe();
    }

    // replace <%trackingnumber%> with trackingNumber in url:
    // EX: "https://www.fedex.com/apps/fedextrack/?action=track&tracknumbers=<%trackingnumber%>&action=track&language=english&cntry_code=us"
    private getTrackingUrl(url, trackingNumber): string {
        let result = url.replace(/<%.*%>/, trackingNumber);
        return result;
    }

    dripUpdateSubscriber(email): Observable<any> {
        // let dripSubscriberPayload = {
        //     email: email,
        //     custom_fields: {
        //         entity_name: 'XION', // TODO: Eventually, this will probably be the retailer (but for now, we want XION feedback)
        //     },
        //     tags: ['XION'],
        // };
        // console.log('DRIP subscriber:', dripSubscriberPayload);
        // // Drip
        // return this.xrdService.createUpdateDripSubscriber(
        //     dripSubscriberPayload
        // );
        return EMPTY;
    }

    removeAbandonedCartDripTag(email: string) {
        // let payload = {
        //     email: email,
        //     tags: ['Purchased'],
        //     remove_tags: ['Abandoned Cart'],
        //     success: function (response) {
        //         console.log('DRIP abandoned cart tag removed:', response);
        //         // Call a method with the response object
        //         // Success callback is optional
        //     },
        // };
        // _dcq.push(['identify', payload]);
    }

    completeRegistration() {
        // Facebook
        // fbq('track', 'CompleteRegistration', {});
    }

    /**
     * TODO: make the quantity take from the variant quantity instead of the product.quantity
     */
    viewContent(item: ICartItemSummary, quantity: string) {
        // if (!this.geService.isRunOnBrowser()) return;
        // let payload = {
        //     value: Number(item.itemPrice),
        //     currency: 'USD',
        //     content_name:
        //         item.brand +
        //         ' ' +
        //         item.name +
        //         (item.modelYear ? ' ' + item.modelYear : ''),
        //     content_type: 'product',
        //     contents: [
        //         {
        //             item_price: Number(item.itemPrice),
        //             id: item.productVariantID,
        //             quantity: Number(quantity),
        //         },
        //     ],
        // };
        // console.log('FB ViewContent payload:', payload);
        // // Facebook
        // fbq('track', 'ViewContent', payload);
    }

    addToCart(item: ICartItemSummary) {
        // Google Ecommerce Analytics
        // ga('send', {
        //     hitType: 'event',
        //     eventCategory: 'Ecommerce',
        //     eventAction: 'Item Added to Cart',
        //     eventLabel: 'Add to Cart',
        // });
        // let payload = {
        //     value: Number(item.itemPrice),
        //     currency: 'USD',
        //     content_name:
        //         item.brand +
        //         ' ' +
        //         item.name +
        //         (item.modelYear ? ' ' + item.modelYear : ''),
        //     content_type: 'product',
        //     contents: [
        //         {
        //             item_price: Number(item.itemPrice),
        //             id: item.productVariantID,
        //             quantity: Number(item.quantity),
        //         },
        //     ],
        // };
        // console.log('FB addtocart payload:', payload);
        // // Facebook
        // fbq('track', 'AddToCart', payload);
    }

    specialOrder() {
        // Google Ecommerce Analytics
        // ga('send', {
        //     hitType: 'event',
        //     eventCategory: 'Ecommerce',
        //     eventAction: 'Special Order Button Pressed',
        //     eventLabel: 'Special Order',
        // });
    }

    // TODO: handel returns
    // Source: https://www.optimizesmart.com/reverse-ecommerce-transaction-google-analytics/
    googleAnalyticsCreateOrder(entity: XRDEntity, order: XRDOrder) {
        // Add Google Analytics for the order
        // ga('ecommerce:addTransaction', {
        //     id: order.orderid,
        //     affiliation: entity.entityid,
        //     revenue: order.subtotal,
        //     shipping: order.shipping,
        //     tax: order.tax,
        // });
    }

    googleAnalyticsCreateOrderItem(orderId, item: XRDOrderItem) {
        // ga('ecommerce:addItem', {
        //     id: orderId,
        //     name:
        //         item.brand +
        //         ' ' +
        //         item.name +
        //         (item.modelyear ? ' ' + item.modelyear : ''),
        //     sku: item.productid,
        //     category: item.productvariantid,
        //     price: item.price,
        //     quantity: item.quantity,
        // });
        // let payload = {
        //     value: Number(item.price),
        //     currency: 'USD',
        //     content_name:
        //         item.brand +
        //         ' ' +
        //         item.name +
        //         (item.modelyear ? ' ' + item.modelyear : ''),
        //     content_type: 'product',
        //     contents: [
        //         {
        //             item_price: Number(item.price),
        //             id: item.productvariantid,
        //             quantity: Number(item.quantity),
        //         },
        //     ],
        // };
        // console.log('FB Purchase payload:', payload);
        // fbq('track', 'Purchase', payload);
    }

    googleAnalyticsSend() {
        // ga('ecommerce:send');
    }
}
