export class XRDAddress {
    "id": string; // This is used on response of POST
    "addressid": string;
    "addresstype": string;
    "addresstypeid": string;
    "fullname": string;
    "address1": string;
    "address2": string;
    "address3": string;
    "city": string;
    "state": string;
    "zip": string;
    "latitude": string;
    "longitude": string;
    "country": string;
    "region": string;

    constructor(){
        this.addressid = '';
        this.address1 = '';
        this.city = '';
        this.state = '';
        this.zip = '';
    }
}
