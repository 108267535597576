
import {map, flatMap} from 'rxjs/operators';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {XRDService} from "../shared/xrd/xrd.service";
import {GlobalEventsService} from "../shared/global-events.service";
import * as _ from "underscore";
import {Observable} from "rxjs";
import { XRDEntityCategory } from '../shared/xrd';


@Injectable()
export class MarketplaceMainNavService {

    public brands: any[];
    public categories: XRDEntityCategory;
    public stores: any[];
    public availableDealCategories: any[];

    /** Used to filter out other categories when bike brand is selected from menu */
    // bikeCategories = 'Bikes,Electric Bikes,Kid`s Cycling';
    // bikeSubCategories = 'Kid`s Bikes';

    constructor(private xrdService: XRDService,
                @Inject(PLATFORM_ID) protected platformId: Object,
                private globalEventsService: GlobalEventsService) {

        // if(!this.globalEventsService.isRunOnBrowser()){
        //     return;
        // }

        // console.log('cats before:', this.categories);
        if(!this.categories){
            this.getProductCategories()
                .subscribe(categories=>{
                    this.categories = categories;
                    // console.log('cats:', this.categories);
                });
        }

        if(!this.brands){
            this.getBrands()
                .subscribe(brands=>{
                    this.brands = brands;
                    // console.log('brands:', this.brands);
                }); 
        }

        if(!this.stores){
            this.getStores()
                .subscribe(stores=>{
                    this.stores = stores;
                    // console.log('stores:', this.stores);
                }); 
        }

        if(!this.availableDealCategories){
            this.getDealCategories()
                .subscribe(availableDealCategories=>{
                    this.availableDealCategories = availableDealCategories;
                    // console.log('availableDealCategories:', this.availableDealCategories);
                }); 
        }
    }

    getProductCategories(): Observable<any>{
        return this.xrdService.entity.getEntityProductCategoryList('0').pipe(flatMap(res1=>{
            let categories: XRDEntityCategory = res1;
            let categoriesWithoutNones: XRDEntityCategory = {departments:[]} as XRDEntityCategory;
            let existingDepartments = [];
            // Remove blanks, create comparable array of deps for populating the other departments (missing)
            categories.departments.forEach((dep, i)=>{
                categoriesWithoutNones.departments.push(dep);
                dep.categories.forEach((cat, ii)=>{
                    if(cat.name === 'None'){
                        categoriesWithoutNones.departments[i].categories.splice(ii, 1);
                    }
                    cat.subcategories.forEach((subcat, iii)=>{
                        if(subcat.name === 'None'){
                            categoriesWithoutNones.departments[i].categories[ii].subcategories.splice(iii, 1);
                        }
                    });
                });
                existingDepartments.push(dep.name);
            });
            return this.xrdService.product.getProductDepartments().pipe(map((res2:any[])=>{
                res2.forEach(item=>{
                    // Don't add "None"
                    // Add other departments that are missing
                    if(item.name !== 'None' && existingDepartments.indexOf(item.name) === -1){
                        categoriesWithoutNones.departments.push({
                            name: item.name,
                            departmentid: undefined,
                            categories: []
                        });
                    }
                });
                return categoriesWithoutNones;
            }));
        }))
    }

    getBrands(): Observable<any>{
        return this.xrdService.entity.getEntityBrandList('0');
    }

    getStores(): Observable<any>{
        return this.xrdService.entity.getPublishedEntities(0, 100, true).pipe(map((stores:any[])=>{
            let storesToDisplay = [];
            for(let store of stores){
                if(store.parententityid === '1') storesToDisplay.push(store);
            }
            return _.sortBy(storesToDisplay, (store)=> store.name.toLowerCase());
        }));
    }

    getDealCategories(){
        return this.xrdService.search.oneSearchCategoryToRuleThemAll({}, true).pipe(map((categories: XRDEntityCategory)=>{
            let availableCategories = [];
            categories.departments.forEach((dep, i)=>{
                dep.categories.forEach((cat, ii)=>{
                    availableCategories.push(cat.name);
                })
            })
            return availableCategories.sort();
        }));
    }

    brandSelected(): void{
        this.globalEventsService.mainSearchQuery = '';
        this.globalEventsService.mainSearchFilter = 'All';
    }

    categorySelected(): void{
        this.globalEventsService.mainSearchQuery = '';
    }

    dealsSelected(): void{
        this.globalEventsService.mainSearchQuery = '';
        this.globalEventsService.mainSearchFilter = 'All';
    }
}
