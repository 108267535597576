import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'xion-centered-image',
    templateUrl: './centered-image.component.html',
    styleUrls: ['./centered-image.component.css']
})
export class CenteredImageComponent implements OnInit {

    @Input() leftAlign: boolean = false;
    @Input() height: number;
    @Input() imagePath: string;
    @Input() padding: number = 0;
    @Input() imageName: string = 'xion image';
    /** Gets set to true if no height input */
    itsFlexBoxed: boolean = false;

    constructor() {
    }

    ngOnInit() {
        if(!this.height){
            this.itsFlexBoxed = true;
        }
    }
}
