import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MainHeaderNavDropdownMobileMenuComponent} from "./main-header-nav-dropdown-mobile-menu.component";
import {SharedModule} from "../../../shared.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        MainHeaderNavDropdownMobileMenuComponent
    ],
    exports: [
        MainHeaderNavDropdownMobileMenuComponent
    ]

})
export class MainHeaderNavDropdownMobileMenuModule { }
