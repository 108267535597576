<xion-simple-modal #modal>
    <div id="main">
        <h2>Select Store to Administrate</h2>
        <div class="scrollable">
            <!--Hard coded XION entity-->
            <a *ngIf="isSuperAdmin" (click)="swapAdminEntity('1'); modal.close();">
                XION
                <span class="not-pub">U</span>
            </a>
            <!--All other user entities-->
            <a *ngFor="let store of stores"
               (click)="swapAdminEntity(store.entityid); modal.close();">
                {{ store.name }}
                <span class="not-pub" *ngIf="store.publishstatus === 'Unpublish'">U</span>
                <span class="pub" *ngIf="store.publishstatus === 'Publish'">P</span>
            </a>
        </div>
    </div>
</xion-simple-modal>
