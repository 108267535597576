import { ErrorHandler, NgModule, Injectable } from '@angular/core';
import { ClientSessionService } from '../shared/session-service/client-session.service';
import { SESSION_SERVICE_TOKEN } from '../shared/session-service/index';
import { HttpLocalClient } from '../http-client.service';
import { AuthGuard } from '../auth-guard.service';
import { FadeOutNotificationService } from '../shared/fade-out-notification-stack/fade-out-notification/fade-out-notification.service';
import { GlobalEventsService } from '../shared/global-events.service';
import { XRDService } from '../shared/xrd/xrd.service';
import { XRDProductService } from '../shared/xrd/product/xrd-product.service';
import { XRDEntityService } from '../shared/xrd/entity/xrd-entity.service';
import { XRDUserService } from '../shared/xrd/user/xrd-user.service';
import { XRDSearchService } from '../shared/xrd/search/xrd-search.service';
import { XRDOrderService } from '../shared/xrd/order/xrd-order.service';
import { ConstantsService } from '../shared/xion-constants.service';
import { WindowRef } from '../WindowRef';
import { SharedModule } from '../shared/shared.module';
import { CartService } from '../marketplace/cart/cart-service/cart.service';
import { AuthenticationService } from '../marketplace/sign-in/authentication.service';
import { MarketplaceMainNavService } from '../marketplace/marketplace-main-nav.service';
import { HeaderModule } from '../shared/main-header/main-header.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeService } from '../marketplace/home/home.service';
import { HomeModule } from '../marketplace/home/home.module';
import { GeoLocationService } from '../shared/geo-location.service';
import { ProductBySEONameResolver } from '../marketplace/resolvers/product-seoname-resolver.service';
import { EntityBySEONameResolver } from '../marketplace/resolvers/entity-seoname-resolver.service';
import { DisabledGuard } from '../disabled-guard.service';
import { SessionExpiredAuthGuard } from '../shared/session-expired-auth-guard.service';
import { CanDeactivateGuard } from '../shared/can-deactivate-guard';
import { ExpiredSessionLoginModule } from '../shared/expired-session-login/expired-session-login.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SessionInterceptor } from '../shared/http-interceptors/session.interceptor';
import { ClientSessionTokenService } from '../shared/session-service/client-session-token.service';
import { SessionExpiredInterceptor } from '../shared/http-interceptors/session-expired.interceptor';
import { TimingInterceptor } from '../shared/http-interceptors/timing.interceptor';
import { CurrencyPipe } from '@angular/common';
import { LoadingChunkErrorHandler } from '../loading-chunk-error-handler';
import { GoogleStructuredDataService } from '../marketplace/shared/google-structured-data/google-structured-data.service';
import { ThirdPartySessionTrackingService } from '../marketplace/shared/third-party-session-tracking/third-party-session-tracking.service';
import { SessionCartGuard } from '../shared/session-cart-guard.service';
import * as Raven from 'raven-js';
import { MainFooterModule } from '../shared/main-footer/main-footer.module';
import { TimePipe } from '../shared/pipes/time.pipe';
import { GlobalStoreEventsService } from '../shared/global-store-events.service';
import { GlobalProductPageEventsService } from '../shared/global-product-page-events.service';

// TODO: Kev, this can probably be removed
// Raven.config(
//     'https://c2849ca1f729487a8fd899e2a395e173@sentry.io/157910'
// ).install();

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
    handleError(err: any): void {
        console.log('UNHANDLED ERROR:', err);
        // Raven.captureException(err);
    }
}

@NgModule({
    imports: [
        SharedModule,

        BrowserAnimationsModule,
        HttpClientModule,

        HeaderModule,
        ExpiredSessionLoginModule,
        HomeModule,
        MainFooterModule,
    ],
    declarations: [],
    exports: [
        SharedModule,

        HeaderModule,
        ExpiredSessionLoginModule,
        HomeModule,
        MainFooterModule,
    ],
    providers: [
        { provide: ErrorHandler, useClass: LoadingChunkErrorHandler },
        { provide: ErrorHandler, useClass: RavenErrorHandler },
        { provide: SESSION_SERVICE_TOKEN, useClass: ClientSessionService },
        ClientSessionTokenService,
        // { provide: ErrorHandler, useClass: RavenErrorHandler },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SessionInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SessionExpiredInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TimingInterceptor,
            multi: true,
        },
        XRDService,
        XRDProductService,
        XRDEntityService,
        XRDUserService,
        XRDSearchService,
        XRDOrderService,
        ConstantsService,
        AuthenticationService,
        FadeOutNotificationService,
        AuthGuard,
        SessionCartGuard,
        SessionExpiredAuthGuard,
        HttpLocalClient,
        CanDeactivateGuard,
        CartService,
        DisabledGuard,
        GlobalEventsService,
        GlobalStoreEventsService,
        GlobalProductPageEventsService,
        MarketplaceMainNavService,
        GeoLocationService,
        EntityBySEONameResolver,
        ProductBySEONameResolver,
        HomeService,
        WindowRef,
        CurrencyPipe,
        TimePipe,
        GoogleStructuredDataService,
        ThirdPartySessionTrackingService,
    ],
})
export class CoreModule {}
