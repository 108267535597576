/**
 * Created by kevbot on 10/17/16.
 */
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FadeOutNotificationComponent} from "./fade-out-notification/fade-out-notification.component";
import {FadeOutNotificationStackComponent} from "./fade-out-notification-stack.component";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        FadeOutNotificationComponent,
        FadeOutNotificationStackComponent
    ],
    exports: [
        FadeOutNotificationComponent,
        FadeOutNotificationStackComponent
    ]
})
export class FadeOutModule { }
