import {Component, OnInit, ViewChild, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'xion-main-header-search',
    templateUrl: './main-header-search.component.html',
    styleUrls: ['./main-header-search.component.css'],
    host: {
        '(document:click)': 'onClick($event)',
    }
})
export class MainHeaderSearchComponent implements OnInit {

    isFilterDropdownShowing: boolean = false;
    @Output() searchThis = new EventEmitter();
    @ViewChild('myButton', { static: true }) myButton;
    @ViewChild('mainSearchInput', { static: true }) mainSearchInput;
    @Input() placeholderText: string;
    @Input() filterText: string;
    @Input() filterDropdownWidth: string = 'auto';
    @Input() canSearchWithNoQueryText: boolean = false;

    /** Custom two-way data binding (this is so easy, why haven't I done this before?)*/
    inputTextValue: string = '';
    @Input()
    get inputText() {
        return this.inputTextValue;
    }
    @Output() inputTextChange = new EventEmitter();
    set inputText(val) {
        this.inputTextValue = val;
        this.inputTextChange.emit(this.inputTextValue);
    }

    constructor() {}

    ngOnInit() {
        // console.log('MainHeaderSearchComponent:ngOnInit()');
    }

    onClick(event) {
        if (!this.myButton.nativeElement.contains(event.target)) {
            this.isFilterDropdownShowing = false;
        }
    }

    onSearchButtonClick(event: any) {
        if(this.inputText || this.canSearchWithNoQueryText) this.search();
        else this.focusOnInput();
    }

    onSearchInputKeyUpForEnter() {
        this.search();
    }

    search(){
        // Only submit if there is something in the field
        if(this.inputText || this.canSearchWithNoQueryText) this.searchThis.emit(this.inputText);
    }

    toggleFilterDropdown(){
        this.isFilterDropdownShowing = !this.isFilterDropdownShowing;
    }

    focusOnInput(){
        this.mainSearchInput.nativeElement.focus();
    }
}
