import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'xion-user-actions',
    templateUrl: './user-actions.component.html',
    styleUrls: ['./user-actions.component.css']
})
export class UserActionsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
