import {Directive, ElementRef, HostListener, Inject, Input, Renderer2} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {GlobalEventsService} from "../../shared/global-events.service";

@Directive({
    selector: '[xionMaxHeightScrollApplier]',
})
export class MaxHeightScrollApplierDirective {

    @Input('xionMaxHeightScrollApplier') extraPad: number;
    // tslint:disable-next-line:no-input-rename
    @Input('setMaxHeightToNone') setToNone = false;

    constructor(private el: ElementRef, private renderer2: Renderer2,
                private geService: GlobalEventsService,
                @Inject(DOCUMENT) private document: any) {}

    /**
     * Set's the max-height of the host element if the host element has the class "fixed".
     */
    @HostListener('window:scroll', ['$event'])
    onWindowScroll($event) {

        // The browser's window height
        let browserHeight = this.document.body.clientHeight;
        // console.log('browserHeight:', browserHeight);

        // This tells us the distance between the top of the footer and the scrollTop position.
        let topOfBottom = this.geService.mainFooter.nativeElement.getBoundingClientRect().top;
        // console.log('topOfBottom:', topOfBottom);

        // Set the max-height
        let maxHeight:any;
        // console.log('classList', this.el.nativeElement.classList.contains('fixed'));
        if(this.el.nativeElement.classList.contains('fixed')){
            if((browserHeight - topOfBottom) > 0){
                let bottomMargin = browserHeight - topOfBottom;
                maxHeight = (browserHeight - bottomMargin - this.extraPad);
                // Set min-height
                if(maxHeight < 50) maxHeight = 50;
                maxHeight = maxHeight + 'px';
            }else{
                maxHeight = (browserHeight - this.extraPad) + 'px';
            }
        } else{
            if(this.setToNone) maxHeight = 'none';
        }

        this.renderer2.setStyle(this.el.nativeElement, 'max-height', maxHeight);
    }
}
