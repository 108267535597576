export class XRDProductVariant {
    productvariantid:string;
    productid:string;
    size:string;
    color:string;
    upc:string;
    mpn:string;
    quantity:string;

    constructor(){
        this.size = '';
        this.color = '';
        this.upc = '';
        this.mpn = '';
    }
}
