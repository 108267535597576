import { XRDLink } from '../shared';
import { XRDProduct } from '../product';

import * as _ from 'underscore';

export class XRDProductSearchResult {

    "id": string;
    "name": string;
    "brand": string;
    "model": string;
    "modelyear": string;
    "seoname": string;
    "price": string;
    "summary": string;
    "description": string;
    "score": number;
    "links": XRDLink[];
    "msrp": string;

    public static toXRDProduct(xrdProductSearchResult: XRDProductSearchResult): XRDProduct {
        let xrdProduct = new XRDProduct();

        xrdProduct.productid = xrdProductSearchResult.id;
        xrdProduct.name = xrdProductSearchResult.name;
        xrdProduct.msrp = xrdProductSearchResult.msrp;
        xrdProduct.modelyear = xrdProductSearchResult.model || xrdProductSearchResult.modelyear;
        xrdProduct.price = xrdProductSearchResult.price;
        xrdProduct.brand = xrdProductSearchResult.brand;
        xrdProduct.summary = xrdProductSearchResult.summary;
        xrdProduct.description = xrdProductSearchResult.description;
        xrdProduct.seoname = (<any>xrdProductSearchResult).seoname;
        xrdProduct.primaryimage = _.chain(xrdProductSearchResult.links)
            .filter((item: XRDLink) => {
                if (item.rel === "image") {
                    return true;
                } else{
                    return false;
                }
            })
            .first()
            .value()
            .href;
        return xrdProduct;
    };
}
