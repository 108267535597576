import { Observable } from 'rxjs/index';
import { Injectable } from '@angular/core';
import {XRDOrderService} from "./order/xrd-order.service";
import {ConstantsService} from "../xion-constants.service";
import {HttpLocalClient} from "../../http-client.service";
import {map} from "rxjs/operators";
import { XRDProductService } from './product/xrd-product.service';
import { XRDEntityService } from './entity/xrd-entity.service';
import { XRDUserService } from './user/xrd-user.service';
import { XRDSearchService } from './search/xrd-search.service';

@Injectable()
export class XRDService {

    private http;

    constructor(
        private constantsService: ConstantsService,
        public product: XRDProductService,
        public entity: XRDEntityService,
        public user: XRDUserService,
        public search: XRDSearchService,
        public order: XRDOrderService,
        private httpClient: HttpLocalClient) {
        this.http = this.httpClient;
    }

    lookup(lookupName: string){
        return this.http.get(this.constantsService.getBackendURL() + 'lookup/' + lookupName + '/item')
            .pipe(map((res:any) => {
                if(!res) return [];
                return res.items || [];
            }));
    }

    lookupById(lookupName: string, lookupId: string){
        return this.http.get(this.constantsService.getBackendURL() + 'lookup/' + lookupName + '/item/' + lookupId)
            .pipe(map((res:any) => {
                if(!res) return {};
                return res.items[0] || {};
            }));
    }

    sendSlackMessageToChannel(channel, message) {
        let payload = {
            channel: channel,
            message: message
        };
        return this.http.post(
            this.constantsService.getNodeBackendURL() + 'slack/custom',
            JSON.stringify(payload),
            false,
            {'Content-Type': 'application/json'}
        );
    }

    createUpdateDripCart(dripCart){
        let payload = dripCart;
        return this.http.post(
            this.constantsService.getNodeBackendURL() + 'drip/cart',
            JSON.stringify(payload),
            false,
            {'Content-Type': 'application/json'}
        );
    }

    createUpdateDripOrder(dripOrder) {
        let payload = {
            orders: [dripOrder]
        };
        return this.http.post(
            this.constantsService.getNodeBackendURL() + 'drip/order',
            JSON.stringify(payload),
            false,
            {'Content-Type': 'application/json'}
        );
    }

    createDripCustomEvent(event) {
        let payload = {
            events: [event]
        };
        return this.http.post(
            this.constantsService.getNodeBackendURL() + 'drip/customevent',
            JSON.stringify(payload),
            false,
            {'Content-Type': 'application/json'}
        );
    }

    createUpdateDripRefund(refund) {
        let payload = {
            refunds: [refund]
        };
        return this.http.post(
            this.constantsService.getNodeBackendURL() + 'drip/order/refund',
            JSON.stringify(payload),
            false,
            {'Content-Type': 'application/json'}
        );
    }

    createUpdateDripSubscriber(subscriberData) {
        let payload = {
            subscribers: [subscriberData]
        };
        return this.http.put(
            this.constantsService.getNodeBackendURL() + 'drip/subscriber',
            JSON.stringify(payload),
            {'Content-Type': 'application/json'}
        );
    }

    // Available params:
    // status, tags, subscribed_before, subscribed_after, page, per_page
    getDripSubscribers(tags:string, subscribed_after:string = ''): Observable<any>{
        return this.http.get(`${this.constantsService.getNodeBackendURL()}drip/subscribers?tags=${tags}&subscribed_after=${subscribed_after}`);
    }

    getEntityProductUpsellAnalytics(entityId:string, entityName:string, startDate:string = '', utmCampaign: string = ''): Observable<any>{
        return this.http.get(
            `${this.constantsService.getNodeBackendURL()}entity/${entityId}/upsellemailanalytics?entity_name=${entityName}&start_date=${startDate}&campaign_name=${utmCampaign}`, 
        );
    }

    getEntityWebstoreAnalytics(entityId:string, entitySeoName:string, pageName:string, startDate:string = ''): Observable<any>{
        return this.http.get(
            `${this.constantsService.getNodeBackendURL()}entity/${entityId}/webstoreanalytics?entity_seoname=${entitySeoName}&start_date=${startDate}&page_name=${pageName}`, 
        );
    }

    getEntityProductAnalytics(entityId:string, entitySeoName:string, startDate:string = '', csv=false): Observable<any>{
        if(csv){
            console.log('getting csv');
            return this.http.get(
                `${this.constantsService.getNodeBackendURL()}entity/${entityId}/productanalyticscsv?entity_seoname=${entitySeoName}&start_date=${startDate}`, 
                false,
                {'content-type': 'text/csv'}
            );
        }else{
            return this.http.get(
                `${this.constantsService.getNodeBackendURL()}entity/${entityId}/productanalytics?entity_seoname=${entitySeoName}&start_date=${startDate}`, 
            );
        }
    }

    getTalkJsUserSignature(userId): Observable<string>{
        return this.http.get(
            `${this.constantsService.getNodeBackendURL()}talkjs/user/${userId}/signature`, 
        );
    }
}
