<!-- MAIN BANNER -->
<section id="main-banner" [ngStyle]="{'background-image': homeService.backgroundImage?('url(\''+homeService.backgroundImage+'\')'):''}">
    <div class="gradient"></div>
    <div class="flex-my-children">
        <div>
            <h1 class="smaller">Shop Local Online</h1>
        </div>
        <div class="banner-img-container">
            <img src="/assets/img/svg/XION_Round_White_4.0.svg">
        </div>
        <div>
            <h1 class="smaller">Local Retailer Collective</h1>
        </div>
    </div>
</section>

<!-- BRAND LOGOS -->
<section id="brand-logos">
    <div class="flex-my-children">
        <div *ngFor="let brand of brandLogos">
            <a [routerLink]="[brand.routePath]">
                <img class="brand-logo" [src]="brand.imagePath">
            </a>
        </div>
    </div>
</section>

<!-- MAIN ACTIONS -->
<xion-user-actions></xion-user-actions>

<!-- PROMOTED ITEMS -->
<h2 #pp>PROMOTED ITEMS</h2>
<section class="relative" id="promoted-items">
    <xion-horizontal-scroller-wrapper>
        <xion-home-promoted-products></xion-home-promoted-products>
    </xion-horizontal-scroller-wrapper>
</section>

<!-- LOCAL SHOPS -->
<a class="header-link" [routerLink]="['/stores']" #shops>
    <h2>LOCAL SHOPS</h2>
</a>
<section class="relative" id="local-shops">
    <xion-horizontal-scroller-wrapper [padding]="'10px 0 21px'">
        <xion-local-shops></xion-local-shops>
    </xion-horizontal-scroller-wrapper>
</section>

<!-- DEALS (blank query required to load filters)-->
<a class="header-link" [routerLink]="['/deals', {query:''}]" #deals>
    <h2>DEALS</h2>
</a>
<section class="relative" id="local-deals">
    <xion-horizontal-scroller-wrapper>
        <xion-home-local-deals></xion-home-local-deals>
    </xion-horizontal-scroller-wrapper>
</section>

<!-- VENTURE BEYOND -->
<a class="header-link" [routerLink]="['/about-us']">
    <h2>VENTURE BEYOND</h2>
</a>
<section id="venture-beyond">
    <div id="video-container">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/u1EdOXyyvWY?rel=0&amp;showinfo=0" frameborder="0" allowfullscreen></iframe>
    </div>
</section>


<!-- EMAIL AND ADVENTURE SCENE -->
<section id="email-adventure">
    <div #animationReferenceElement class="animation-reference"></div>

    <img class="sun"
         [ngStyle]="{'top': sunTop+'px'}"
         [lazyLoad]="'assets/img/homepage/Sun.svg'"
         [offset]="400"
         alt="the sun">

    <img class="mtn-bike"
         [lazyLoad]="'assets/img/homepage/Mountain_Bike.svg'"
         [offset]="400"
         alt="a mountain bike">
         <!--[ngStyle]="{'top': bikeTop+'px',-->
                    <!--'left': bikeLeft+'px',-->
                    <!--'transform': 'rotate('+bikeAngle+'deg)'}">-->

    <!--<img class="beard-man"-->
         <!--[class.show-face]="showFace"-->
         <!--src="/assets/img/homepage/bearded-man-cartoon-md.png"-->
         <!--alt="beard man">-->

    <div class="grey-sky"></div>
    <div class="mountain-tree-path" [ngStyle]="{'background-image': ('url(/assets/img/homepage/Mountain_Trees_Path.svg)')}"></div>
    <div class="front-trees" [ngStyle]="{'background-image': ('url(/assets/img/homepage/front_trees.svg)')}"></div>
    <div class="green-grass"></div>

    <xion-email-subscription-form></xion-email-subscription-form>
</section>
