import { XRDPaymentMethod } from './../payment/xrd-payment-method.model';

import {
    forkJoin as observableForkJoin,
    Observable,
    throwError as observableThrowError,
} from 'rxjs';

import { map, flatMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as _ from 'underscore';

import { XRDEntity } from './xrd-entity.model';
import { XRDProductVariant, XRDEntityCategory } from '../product';

import { XRDAddress } from '../shared/xrd-address.model';
import { XRDEmail } from '../shared/xrd-email.model';
import { XRDPhone } from '../shared/xrd-phone.model';
import { XRDProductInventoryItem } from '../inventory/xrd-product-inventory-item.model';
import { ConstantsService } from '../../xion-constants.service';
import { XRDEntityProduct } from './xrd-entity-product.model';
import { HttpLocalClient } from '../../../http-client.service';
import { XRDEntityBilling } from './xrd-entity-billing.model';
import { XRDEntityProcessor } from './xrd-entity-processor.model';
import { XRDEntityPlan } from './xrd-entity-plan.model';
import { XRDPromotedProduct } from '../product/xrd-promoted-product.class';
import { XRDEntityExternal } from './xrd-entity-external.model';
import { XRDEntityPaymentMethod } from './xrd-entity-payment-method.model';

@Injectable()
export class XRDEntityService {
    private http;

    constructor(
        private constantsService: ConstantsService,
        private httpClient: HttpLocalClient
    ) {
        this.http = this.httpClient;
    }

    updateProductVariantPublishStatus(
        entityId: string,
        productId: string,
        publishStatus: string
    ) {
        let payload = {
            products: [
                {
                    publishstatus: publishStatus,
                },
            ],
        };
        console.log('updating publish status...', payload);
        return this.http
            .put(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/product/' +
                    productId,
                JSON.stringify(payload)
            )
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

    /**
     * Gets stores based on location. Pass 0 in to lat and long if you want to have the backend use the user's IP address for the search.
     */
    getEntitiesByLocation(
        numberOfEntities: number,
        lat: number,
        long: number,
        start?: number
    ) {
        if (!start) start = 0;
        console.log(
            'searching for ' +
                numberOfEntities +
                ' entities by location lat: ' +
                lat +
                ' long: ' +
                long
        );
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity?latitude=' +
                    lat +
                    '&longitude=' +
                    long +
                    '&start=' +
                    start +
                    '&count=' +
                    numberOfEntities +
                    '&published=true'
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.entities || [];
                })
            );
    }

    /**
     * Gets published and unpublished stores (Only used for Super Admin users)
     */
    getEntities(start: number, count: number) {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity?start=' +
                    start +
                    '&count=' +
                    count +
                    '&published=0&tiny=true'
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.entities || [];
                })
            );
    }

    getPublishedEntities(start: number, count: number, tiny = false) {
        return this.http
            .get(
                `${this.constantsService.getBackendURL()}entity?start=${start}&count=${count}${
                    tiny ? '&tiny=true' : ''
                }`
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.entities || [];
                })
            );
    }

    getEntityById(entityId: string): Observable<XRDEntity> {
        return this.http
            .get(this.constantsService.getBackendURL() + 'entity/' + entityId)
            .pipe(
                map((res: any) => {
                    if (!res) return undefined;
                    return res.entities[0] || undefined;
                })
            );
    }

    getEntityBySeoName(seoName: string): Observable<XRDEntity> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity?seoname=' +
                    seoName
            )
            .pipe(
                map((res: any) => {
                    if (!res) return undefined;
                    return res.entities[0] || undefined;
                })
            );
    }

    getEntityProducts(entityId: string, start: number, count: number) {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/product?start=' +
                    start +
                    '&count=' +
                    count
            )
            .pipe(
                map((res: any) => {
                    if (!res) return {};
                    return res.products || {};
                })
            );
    }

    getEntityProductByID(
        entityID: string,
        productID: string
    ): Observable<any[]> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityID +
                    '/product/' +
                    productID
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.products || [];
                })
            );
    }

    updateEntityProduct(entityProduct: XRDEntityProduct) {
        let payload = {
            products: [entityProduct],
        };

        return this.http
            .put(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityProduct.entityid +
                    '/product/' +
                    entityProduct.productid,
                JSON.stringify(payload)
            )
            .pipe(
                map((res) => {
                    if (!res) return {};
                    return res || {};
                })
            );
    }

    createEntityProduct(entityId, productId, entityProduct: XRDEntityProduct) {
        let payload = {
            products: [entityProduct],
        };

        return this.http
            .post(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/product',
                JSON.stringify(payload)
            )
            .pipe(
                map((res) => {
                    return res || {};
                })
            );
    }

    createDefaultEntityProduct(entityId, productId) {
        let payload = {
            products: [
                {
                    productid: productId,
                    // Default values
                    publishstatus: 'Publish',
                    availabletoorder: '1',
                    instorepickup: '1',
                    ondemandshipping: '1',
                    canship: '1',
                    instorepurchaseonly: '0',
                } as XRDEntityProduct,
            ],
        };

        return this.http
            .post(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/product',
                JSON.stringify(payload)
            )
            .pipe(
                map((res) => {
                    return res || {};
                })
            );
    }

    updateEntityProductFlags(
        entityID: string,
        productID: string,
        availableToOrder: string,
        inStorePickup: string,
        onDemandShipping: string,
        settings: any,
        inStorePurchaseOnly: string,
        publishStatus: string
    ) {
        let payload = {
            products: [
                {
                    availabletoorder: availableToOrder,
                    instorepickup: inStorePickup,
                    ondemandshipping: onDemandShipping,
                    settings: settings,
                    instorepurchaseonly: inStorePurchaseOnly,
                    publishstatus: publishStatus,
                    variants: [],
                },
            ],
        };

        return this.http
            .put(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityID +
                    '/product/' +
                    productID,
                JSON.stringify(payload)
            )
            .pipe(
                map((res) => {
                    if (!res) return {};
                    return res || {};
                })
            );
    }

    getEntityProductBySEOName(entityID: string, productSEOName: string) {
        // console.info('Getting product at this address: ' + this.constantsService.getBackendURL() + 'entity/' + entityID + '/product?seoname=' + productSEOName);
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityID +
                    '/product/' +
                    productSEOName
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.products || [];
                })
            );
    }

    getEntityProductQueryStartCount(
        entityID: string,
        productSEOName: string,
        start: number,
        count: number
    ) {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityID +
                    '/product?query=' +
                    productSEOName +
                    '&start=' +
                    start +
                    '&count=' +
                    count
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.products || [];
                })
            );
    }

    queryEntityProductsStartCount(
        entityId: string,
        params: any,
        start: number,
        count: number
    ): Observable<any> {
        let paramString = 'start=' + start + '&count=' + count + '&';

        // Concat params
        for (let param in params) {
            if (params.hasOwnProperty(param)) {
                paramString +=
                    param + '=' + encodeURIComponent(params[param]) + '&';
            }
        }
        // Get rid of last '&'
        paramString = paramString.substring(0, paramString.length - 1);

        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/product?' +
                    paramString
            )
            .pipe(
                map((res) => {
                    if (!res) return {};
                    return res || {};
                }),
                catchError(this.http.handleError)
            );
    }

    public createEntityProductDefaultVariants(
        entityId: string,
        productId: string,
        publishStatus: string,
        inStorePurchaseOnly: number,
        availableToOrder: number,
        inStorePickup: number,
        canShip: number,
        onDemandShipping: number,
        productVariantIds: { productvariantid: string; donotinclude: string }[]
    ) {
        let payload = {
            products: [
                {
                    productid: productId,
                    settings: {},
                    publishstatus: publishStatus,
                    instorepurchaseonly: inStorePurchaseOnly,
                    availabletoorder: availableToOrder,
                    instorepickup: inStorePickup,
                    canship: canShip,
                    ondemandshipping: onDemandShipping,
                    variants: productVariantIds,
                },
            ],
        };

        return this.http
            .post(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/product',
                JSON.stringify(payload)
            )
            .pipe(
                map((res: any) => {
                    if (!res) return {};
                    return res.entityproducts[0] || {};
                    // console.log('createPhoneForEntityId(): returning result: ' + JSON.stringify(result));
                })
            );
    }

    getEntityByIdDeep(entityId: string): Observable<XRDEntity> {
        return this.getEntityById(entityId).pipe(
            flatMap((entity) => {
                // Queue up calls to get the rest of the entity data.
                let observables = [];
                observables.push(this.getAddresses(entity.entityid));
                observables.push(this.getPhones(entity.entityid));
                observables.push(this.getEmails(entity.entityid));

                return observableForkJoin(observables).pipe(
                    map((results) => {
                        return { entity: entity, innerResults: results };
                    })
                );
            }),
            map((results: any) => {
                // console.log("Deep data loaded for entity: " + JSON.stringify(results));
                results.entity.DeepAddresses = results.innerResults[0];
                results.entity.DeepPhones = results.innerResults[1];
                results.entity.DeepEmails = results.innerResults[2];

                return results.entity;
            })
        );
    }

    getEntityProductCategoryList(entityID): Observable<XRDEntityCategory> {
        // console.log(this.constantsService.getBackendURL() + 'entity/' + entityID + '/category?start=0&count=1000');
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityID +
                    '/category?start=0&count=1000'
            )
            .pipe(
                map((res: any) => {
                    if (!res) return undefined;
                    return res || undefined;
                })
            );
    }

    getEntityBrandList(entityID): Observable<any> {
        // console.log(this.constantsService.getBackendURL() + 'entity/' + entityID + '/brand?start=0&count=1000');
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityID +
                    '/brand?start=0&count=1000'
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    let data = res.brands || [];
                    if (data.length === 0) return data;

                    let result = [];
                    let brandSet = new Set<string>();
                    for (let cat of data) {
                        brandSet.add(cat.brandname);
                    }
                    result = Array.from(<any>brandSet);
                    return result;
                })
            );
    }

    getEntityBrandListFiltered(entityID, filter: string): Observable<any> {
        // console.log(this.constantsService.getBackendURL() + 'entity/' + entityID + '/brand?start=0&count=1000');
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityID +
                    '/brand?filter=' +
                    filter +
                    '&start=0&count=1000'
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    let data = res.brands || [];
                    if (data.length === 0) return data;

                    let result = [];
                    let brandSet = new Set<string>();
                    for (let cat of data) {
                        brandSet.add(cat.brandname);
                    }
                    result = Array.from(<any>brandSet);
                    return result;
                })
            );
    }

    public getEntityPromotedProducts(
        entityId: string,
        count: number
    ): Observable<XRDPromotedProduct[]> {
        // console.log('Promoted products GET: entityId ' + entityId + ' count ' + count);
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/promoted?start=0&count=' +
                    count
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.promoteds || [];
                })
            );
    }

    public postEntityPromotedProducts(
        entityId: string,
        productId: any,
        orderNumber: number
    ) {
        // console.log('Promoted products POST: entityId ' + entityId + ' productId ' + productId);
        let payload = {
            promoteds: [
                {
                    entityid: entityId,
                    productid: productId,
                    order: orderNumber,
                },
            ],
        };
        return this.http
            .post(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/promoted',
                JSON.stringify(payload)
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.promoteds || [];
                })
            );
    }

    public putEntityPromotedProducts(
        entityId: string,
        promotedProductId: string,
        productId: string,
        orderNumber: number
    ) {
        // console.log('Promoted products PUT: entityId ' + entityId + ' promotedProductId ' + promotedProductId);
        let payload = {
            promoteds: [
                {
                    entityid: entityId,
                    productid: productId,
                    order: orderNumber,
                },
            ],
        };
        return this.http
            .put(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/promoted/' +
                    promotedProductId,
                JSON.stringify(payload)
            )
            .pipe(
                map((res) => {
                    return res || [];
                })
            );
    }

    public deleteEntityPromotedProducts(
        entityId: string,
        promotedProductId: string
    ) {
        // console.log('Promoted products DELETE: entityId ' + entityId + ' promotedProductId ' + promotedProductId);
        return this.http
            .delete(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/promoted/' +
                    promotedProductId
            )
            .pipe(
                map((res) => {
                    return res || [];
                })
            );
    }

    public getAddresses(entityId: string) {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/address?start=0&count=100'
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.addresses || [];
                })
            );
    }

    public updateAddressForEntityId(entityId: string, address: XRDAddress) {
        let payload = {
            addresses: [address],
        };
        return this.http.put(
            this.constantsService.getBackendURL() +
                'entity/' +
                entityId +
                '/address/' +
                address.addressid,
            JSON.stringify(payload)
        );
    }

    public updateEntityByEntityId(entityId: string, data: XRDEntity) {
        let payload = {
            entities: [data],
        };
        return this.http.put(
            this.constantsService.getBackendURL() + 'entity/' + entityId,
            JSON.stringify(payload)
        );
    }

    public updateEntityLogo(entityId, file: any) {
        const formData: FormData = new FormData();
        formData.append('image', file, file.name);

        return this.http.post(
            this.constantsService.getNodeBackendURL() +
                'entity/' +
                entityId +
                '/logo',
            formData
        );
    }

    public updateEntityHeroImage(entityId, file: any) {
        const formData: FormData = new FormData();
        formData.append('image', file, file.name);

        return this.http.post(
            this.constantsService.getNodeBackendURL() +
                'entity/' +
                entityId +
                '/storefront',
            formData
        );
    }

    public uploadEntityCsv(entityId, file: any) {
        const formData: FormData = new FormData();
        formData.append('csv', file, file.name);

        let newLocation;

        // Upload to S3 bucket
        return this.http
            .post(
                this.constantsService.getNodeBackendURL() +
                    'entity/' +
                    entityId +
                    '/csv',
                formData
            )
            .pipe(
                flatMap((location: string) => {
                    newLocation = location;
                    return this.getEntityExternals(entityId).pipe(
                        map((externals) => {
                            let externalsByType = _.object(
                                _.map(externals, (item) => {
                                    return [item.entityexternaltype, item];
                                })
                            );
                            // console.log('externalsByType:', externalsByType);
                            if (externalsByType['csvfile']) {
                                return externalsByType['csvfile'];
                            } else {
                                return undefined;
                            }
                        })
                    );
                }),
                flatMap((entityExternalInvImp: XRDEntityExternal) => {
                    // Save location to Entity External for auto import
                    if (
                        entityExternalInvImp &&
                        entityExternalInvImp.entityexternalid
                    ) {
                        return this.updateEntityExternalInventoryImport(
                            entityId,
                            newLocation,
                            entityExternalInvImp.entityexternalid
                        );
                    } else {
                        return this.createEntityExternalInventoryImport(
                            entityId,
                            newLocation
                        );
                    }
                })
            );
    }

    public getPhones(entityId: string) {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/phone?start=0&count=100'
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];

                    return res.phones || [];
                })
            );
    }

    public createPhoneForEntityId(
        entityId: string,
        phoneType: string,
        phoneNumber: string
    ) {
        // Defaults.
        let payload = {
            phones: [
                {
                    phonetype: phoneType,
                    phonenumber: phoneNumber,
                },
            ],
        };

        return this.http
            .post(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/phone',
                JSON.stringify(payload)
            )
            .pipe(
                map((res: any) => {
                    let result = res.phones[0] || [];
                    // console.log('createPhoneForEntityId(): returning result: ' + JSON.stringify(result));
                    return result;
                })
            );
    }

    public updatePhoneForEntityId(entityId: string, phone: XRDPhone) {
        let payload = {
            phones: [
                {
                    phonenumber: phone.phonenumber,
                    phonetype: phone.phonetype,
                },
            ],
        };
        return this.http.put(
            this.constantsService.getBackendURL() +
                'entity/' +
                entityId +
                '/phone/' +
                phone.phoneid,
            JSON.stringify(payload)
        );
    }

    public getEmails(entityId: string) {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/email?start=0&count=100'
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.emails || [];
                })
            );
    }

    public createEmailForEntityId(entityId: string, email: XRDEmail) {
        let payload = {
            emails: [
                {
                    emailaddress: email.emailaddress,
                    emailtype: email.emailtype,
                },
            ],
        };
        return this.http
            .post(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/email',
                JSON.stringify(payload)
            )
            .pipe(
                map((res: any) => {
                    let result = res.emails[0] || [];
                    // console.log('createEmail(): returning result: ' + JSON.stringify(result));
                    return result;
                })
            );
    }

    public updateEmailForEntityId(entityId: string, email: XRDEmail) {
        let payload = {
            emails: [email],
        };
        return this.http.put(
            this.constantsService.getBackendURL() +
                'entity/' +
                entityId +
                '/email/' +
                email.emailid,
            JSON.stringify(payload)
        );
    }

    /**
     * See http://leanfold.com/api/docs/entity/entity_doc.php#path__v1_entity.html for a full list of parameters that
     * can be included in params.
     */
    public create(
        name: string,
        officialName: string,
        SEOName: string,
        params: Object
    ) {
        // Defaults.
        let payload = {
            entities: [
                {
                    rootid: '0',
                    parententityid: '1',
                    entitystatus: 'Active',
                    entitytype: 'Storefront',
                    theme: 'Green',
                    keywords: '',
                    description: '',
                    // hours: " { \"AboutUs\":\"\", \"Services\":\"\" } ",
                    logo: '',
                    publishstatus: 'Unpublish',

                    name: name,
                    officialname: officialName,
                    seoname: SEOName,
                },
            ],
        };

        if (params) {
            Object.getOwnPropertyNames(params).forEach((value, index) => {
                payload.entities[0][value] = params[value];
            });
        }

        return this.http
            .post(
                this.constantsService.getBackendURL() + 'entity',
                JSON.stringify(payload)
            )
            .pipe(
                map((res: any) => {
                    return res.entities || [];
                })
            );
    }

    public createAddressForEntityId(
        entityId: string,
        fullName: string,
        address: XRDAddress
    ) {
        // Defaults.
        let payload = {
            addresses: [
                {
                    addresstype: 'Physical',
                    fullname: fullName,
                    address1: address.address1,
                    address2: address.address2,
                    city: address.city,
                    state: address.state,
                    zip: address.zip,
                    country: 'USA',
                    region: 'North America',
                    latitude: '0',
                    longitude: '0',
                },
            ],
        };

        return this.http
            .post(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/address',
                JSON.stringify(payload)
            )
            .pipe(
                map((res: any) => {
                    let result = res.addresses || [];
                    // console.log('createAddressForEntityId(): returning result: ' + JSON.stringify(result));
                    return result;
                })
            );
    }

    public removeVariantFromEntity(entityID: string, variantID: string) {
        console.log(
            'removing entity ' + entityID + ' variant ' + variantID + '...'
        );
        return this.http
            .delete(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityID +
                    '/variant/' +
                    variantID
            )
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    public updateEntityVariant(
        entityID: string,
        entityVariantID: string,
        variant
    ) {
        let payload = {
            variants: [variant],
        };

        console.log('PAY LOAD: ', payload);

        return this.http
            .put(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityID +
                    '/variant/' +
                    entityVariantID,
                JSON.stringify(payload)
            )
            .pipe(
                map((res) => {
                    return res || {};
                })
            );
    }

    public getEntityProductInventories(
        entityId: string,
        productId: string,
        start: number,
        count: number
    ) {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/product/' +
                    productId +
                    '/inventory?start=' +
                    start +
                    '&count=' +
                    count
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.inventories || [];
                })
            );
    }

    public addInventoriesToEntity(
        entityId: string,
        productId: string,
        inventories: XRDProductInventoryItem[]
    ) {
        // console.log('adding inventories');

        let payload = {
            inventories: inventories,
        };

        // console.log(payload);
        return this.http
            .post(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/product/' +
                    productId +
                    '/inventory',
                JSON.stringify(payload)
            )
            .pipe(
                map((res) => {
                    // console.log(res);
                    return res;
                })
            );
    }

    public updateInventories(productID: string, inventories: any) {
        let listOfObservables = [];

        for (let inv of inventories) {
            let payload = {
                inventories: [inv],
            };
            listOfObservables.push(
                this.http
                    .put(
                        this.constantsService.getBackendURL() +
                            'entity/' +
                            inv.entityid +
                            '/product/' +
                            productID +
                            '/inventory/' +
                            inv.inventoryid,
                        JSON.stringify(payload)
                    )
                    .pipe(
                        map((res) => {
                            return res;
                        })
                    )
            );
        }
        return observableForkJoin(...listOfObservables).pipe(
            map((res) => {
                console.log('Some updated Inventories: ', res);
                return res || {};
            })
        );
    }

    public removeInventoriesFromEntityProduct(
        entityID,
        inventoryIDs: string[]
    ) {
        let listOfObservables = [];

        for (let ID of inventoryIDs) {
            listOfObservables.push(
                this.http
                    .delete(
                        this.constantsService.getBackendURL() +
                            'entity/' +
                            entityID +
                            '/inventory/' +
                            ID
                    )
                    .pipe(
                        map((res) => {
                            if (!res) return {};
                            return res || {};
                        })
                    )
            );
        }
        return observableForkJoin(...listOfObservables).pipe(
            map((res) => {
                // console.log('Forked result: ', res);
            })
        );
    }

    public removeInventoryFromEntityProduct(inventoryID: string) {
        return this.http.delete(
            this.constantsService.getBackendURL() + 'inventory/' + inventoryID
        );
    }

    public createEntityMerchantAccount(entityId: string, processor: any) {
        let payload = {
            processors: [processor],
        };

        return this.http
            .post(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/processor',
                JSON.stringify(payload),
                true,
                {},
                true
            )
            .pipe(
                map((res: any) => {
                    return res.body.processors[0] || {};
                }),
                // Custom error handeling:
                catchError((error: any) => {
                    let warningHeader = error.headers.get('Warning');
                    if (!warningHeader) {
                        return observableThrowError(error);
                    }
                    let warningError = JSON.parse(warningHeader).resultmessage;
                    if (!warningError) {
                        return observableThrowError(error);
                    }
                    return observableThrowError(warningError);
                    // return this.http.handleError(error);
                })
            );
    }

    public getEntityProcessor(
        entityId: string
    ): Observable<XRDEntityProcessor[]> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/processor?start=0&count=10'
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.processors || [];
                })
            );
    }

    public getEntityPaymentMethod(
        entityId: string
    ): Observable<XRDEntityPaymentMethod[]> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/paymentmethod?start=0&count=10'
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.paymentmethods || [];
                })
            );
    }

    /** Only pass in entity 0, or 1 to get back a list of all plans */
    public getEntityPlans(entityId: string): Observable<XRDEntityPlan[]> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/plan?start=0&count=30'
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.billingplans || [];
                })
            );
    }

    public getEntityPlanById(
        entityId: string,
        planId: string
    ): Observable<XRDEntityPlan> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/plan/' +
                    planId
            )
            .pipe(
                map((res: any) => {
                    if (!res) return {};
                    return res.billingplans[0] || {};
                })
            );
    }

    public getEntityBillingPlan(
        entityId: string
    ): Observable<XRDEntityBilling[]> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/billing?start=0&count=10'
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.billings || [];
                })
            );
    }

    // public updateEntityBillingPlan( entityId: string, billingId: string, billingplanid: string ): Observable<any>{

    //     let payload = {
    //         billings: [
    //             {
    //                 billingplanid: billingplanid
    //             }
    //         ]
    //     }

    //     return this.http.put(this.constantsService.getBackendURL() + 'entity/' + entityId + '/billing/' + billingId, JSON.stringify(payload))
    //         .pipe(map(res => {
    //             return res;
    //         }));
    // }

    public createEntityPaymentMethod(
        entityId: string,
        paymentMethodId: string,
        contactId: string
    ): Observable<XRDEntityPaymentMethod> {
        let payload = {
            paymentmethods: [
                {
                    paymentmethodid: paymentMethodId,
                    contactid: contactId,
                },
            ],
        };

        return this.http
            .post(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/paymentmethod',
                JSON.stringify(payload)
            )
            .pipe(
                map((res: any) => {
                    return res.paymentmethods[0] || {};
                })
            );
    }

    public createEntityBillingPlan(
        entityId: string,
        planId: string,
        paymentMethodId: string
    ) {
        let payload = {
            billings: [
                {
                    billingstatusid: '1',
                    billingplanid: planId,
                    paymentmethodid: paymentMethodId,
                },
            ],
        };

        return this.http
            .post(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/billing',
                JSON.stringify(payload)
            )
            .pipe(
                map((res: any) => {
                    return res.billings[0] || {};
                })
            );
    }

    public updateEntityBillingPlan(
        entityId: string,
        planId: string,
        paymentMethodId: string,
        billingPlanId: string
    ) {
        let payload = {
            billings: [
                {
                    billingstatusid: '1',
                    billingplanid: planId,
                    paymentmethodid: paymentMethodId,
                },
            ],
        };

        return this.http
            .put(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/billing/' +
                    billingPlanId,
                JSON.stringify(payload)
            )
            .pipe(
                map((res: any) => {
                    return res || {};
                })
            );
    }

    public getEntityExternals(
        entityId: string
    ): Observable<XRDEntityExternal[]> {
        // Added filter as a temporary fix until Robert fixes this.
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/external?filter=&start=0&count=100'
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.externals || [];
                })
            );
    }

    public createEntityExternalInventoryImport(
        entityId: string,
        importPath: string
    ) {
        let payload = {
            externals: [
                {
                    entityexternaltype: 'csvfile',
                    entityexternalstatus: 'Active',
                    migrateprices: '1',
                    importpath: importPath,
                    importfiletype: 'CSV-DropBox',
                    importfiletypeid: '4',
                },
            ],
        };

        return this.http
            .post(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/external',
                JSON.stringify(payload)
            )
            .pipe(
                map((res) => {
                    return res || {};
                })
            );
    }

    public updateEntityExternalInventoryImport(
        entityId: string,
        importPath: string,
        externalId: string
    ) {
        let payload = {
            externals: [
                {
                    entityexternaltype: 'csvfile',
                    entityexternalstatus: 'Active',
                    migrateprices: '1',
                    importpath: importPath,
                    importfiletype: 'CSV-DropBox',
                    importfiletypeid: '4',
                },
            ],
        };

        return this.http
            .put(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/external/' +
                    externalId,
                JSON.stringify(payload)
            )
            .pipe(
                map((res) => {
                    return res || {};
                })
            );
    }

    public getEntityLightspeedIntegrationLatestSync(entityId: string) {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/pointofsale/lightspeed/sync?start=0&count=1'
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.syncs || [];
                })
            );
    }

    public getEntityCSVIntegrationLatestSync(entityId: string) {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/pointofsale/csvfile/sync?start=0&count=1'
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.syncs || [];
                })
            );
    }

    public createEntityLightspeedIntegration(
        entityId: string,
        tempToken: string
    ) {
        let payload = {
            pointofsales: [
                {
                    temptoken: tempToken,
                },
            ],
        };

        return this.http
            .post(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/pointofsale',
                JSON.stringify(payload)
            )
            .pipe(
                map((res) => {
                    return res || {};
                })
            );
    }

    public getEntityCouponById(entityId: string, couponIdOrCode: string) {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/coupon/' +
                    couponIdOrCode
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.coupons || [];
                })
            );
    }

    public setEntityOwner(entityId: string) {
        let payload = { owners: [{}] };

        return this.http
            .post(
                this.constantsService.getBackendURL() +
                    'entity/' +
                    entityId +
                    '/owner',
                JSON.stringify(payload)
            )
            .pipe(
                map((res: any) => {
                    return res.owners[0] || {};
                })
            );
    }

    getEntityReportGoogleExportCsv(entityId: string): Observable<any> {
        return this.http.get(
            this.constantsService.getBackendURL() +
                'entity/' +
                entityId +
                '/report?name=Google Export',
            false,
            { Accept: 'text/csv' }
        );
    }

    getEntityReportCsv(
        entityId: string,
        reportName: string,
        begin: string,
        end: string
    ): Observable<any> {
        let cachebuster = Math.round(new Date().getTime() / 1000);
        return this.http.get(
            `${this.constantsService.getBackendURL()}entity/${entityId}/report?name=${reportName}&begin=${begin}&end=${end}&cacheBust=${cachebuster}`,
            false,
            { Accept: 'text/csv' }
        );
    }

    getEntityReport(
        entityId: string,
        reportName: string,
        begin: string,
        end: string
    ): Observable<any> {
        let requestString = `${this.constantsService.getBackendURL()}entity/${entityId}/report?name=${reportName}`;
        if (begin && end) requestString += `&begin=${begin}&end=${end}`;
        return this.http.get(requestString);
    }

    getChildEntitiesFromParent(
        parentEntityId: string
    ): Observable<XRDEntity[]> {
        return this.http
            .get(
                `${this.constantsService.getBackendURL()}entity?parententityid=${parentEntityId}&start=0&count=100&published=true`
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.entities || [];
                })
            );
    }
}
