
import {takeUntil} from 'rxjs/operators';
import {Component, OnInit, ViewChild, Inject, Input, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {HttpLocalClient} from "../../http-client.service";
import {Router} from "@angular/router";
import {AuthenticationService} from "../../marketplace/sign-in/authentication.service";
import {SessionService} from "../session-service/session.service.interface";
import {SESSION_SERVICE_TOKEN} from "../session-service/index";
import {Subject} from "rxjs";
import {WindowRef} from "../../WindowRef";

@Component({
    selector: 'xion-expired-session-login',
    templateUrl: './expired-session-login.component.html',
    styleUrls: ['./expired-session-login.component.css']
})
export class ExpiredSessionLoginComponent implements OnInit, OnDestroy {

    @Input() isAdminPage: boolean = false;
    @ViewChild('mainModal', { static: true }) mainModal;
    @ViewChild('passwordInput', { static: true }) passwordInput;

    /** sign is either 'in' or 'up' for sign-in and sign-up components */
    sign: string = 'in';

    username: string;
    password: string;

    hasError = false;
    errorMessage: string;
    isLoading = false;

    isCloseDisabled = true;

    private sessionService: SessionService;
    private triedAgain: boolean = false;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(private httpClient: HttpLocalClient, private router: Router,
                private winRef: WindowRef,
                @Inject(SESSION_SERVICE_TOKEN) sessionService: SessionService,
                private changeDetectorRef: ChangeDetectorRef,
                private authService: AuthenticationService) {
        this.sessionService = sessionService;
    }

    /**
     * Subscribe to showModal observable that gets triggered when a 401 error code happens
     */
    ngOnInit() {
        // Subscribe to showing the modal on 401 errors
        this.httpClient.showModal.asObservable().pipe(
            takeUntil(this.ngUnsubscribe.asObservable()))
            .subscribe(()=>{

                // Set the username email if there is one
                this.username = this.sessionService.getUserUsername();

                this.mainModal.open();

                // Set focus to password field
                this.changeDetectorRef.detectChanges();
                this.passwordInput.nativeElement.focus();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    signIn() {
        this.isLoading = true;
        this.hasError = false;
        this.changeDetectorRef.detectChanges();
        let adminEntityId = this.sessionService.getAdminEntityId();
        this.authService.signIn(this.username, this.password).pipe(
            takeUntil(this.ngUnsubscribe.asObservable()))
            .subscribe(()=>{
                // Retry the request
                this.retryRequest();
                // set the adminEntity to the way it was before the session refreshed
                if(adminEntityId && (adminEntityId !== this.sessionService.getAdminEntityId())){
                    this.sessionService.loadAdminEntity(adminEntityId).subscribe();
                }
            }, error=>{
                console.log('error:', error);
                this.isLoading = false;
                this.hasError = true;
                this.errorMessage = 'The username and password combination you entered didn\'t work :(';
                this.changeDetectorRef.detectChanges();
            });
    }

    /**
     * Initiate the request again! Will only try the last failed request because of stopObservable function
     */
    retryRequest() {
        this.httpClient.goAgain.next(['trying request again']);
        this.triedAgain = true;
        this.closeThisThang();
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
    }

    /**
     * complete this observer when the modal is exited out of, sign out, and send to sign-in page
     */
    stopObservable(){
        if(!this.triedAgain){
            this.triedAgain = false;
            this.authService.signOut();
            if(this.isAdminPage){
                this.router.navigate(['/']);
            }else{
                // Reload current page
                this.winRef.nativeWindow.location.reload();
            }
        }
        this.httpClient.goAgain.complete();
    }

    /**
     * Enable close and close that thang
     */
    closeThisThang(){
        this.isCloseDisabled = false;
        this.changeDetectorRef.detectChanges();
        this.mainModal.close();
    }
}
