import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartItemsSummaryComponent } from './cart-items-summary.component';
import {CartItemSummaryModule} from "./cart-item-summary/cart-item-summary.module";
import {ValuesPipeModule} from "../../../shared/pipes/values-pipe.module";
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        CartItemSummaryModule,
        ValuesPipeModule,
    ],
    declarations: [CartItemsSummaryComponent],
    exports: [CartItemsSummaryComponent]
})
export class CartItemsSummaryModule { }
