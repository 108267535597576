
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {map, flatMap} from "rxjs/operators";
import {Injectable} from "@angular/core";

import {ConstantsService} from "../../xion-constants.service";
import {HttpLocalClient} from "../../../http-client.service";
import {XRDOrder} from "./xrd-order.model";
import {XRDOrderItem} from "./xrd-order-item.model";
import {environment} from "../../../../environments/environment";
import {XRDAddress} from "../shared";
import { XRDOrderPayment } from './xrd-order-payment.model';

@Injectable()
export class XRDOrderService{

    private http;

    constructor(private constantsService:ConstantsService, private httpClient: HttpLocalClient) {
        this.http = this.httpClient;
    }

    getOrder(orderId): Observable<XRDOrder>{
        return this.http.get(this.constantsService.getBackendURL() + 'order/' + orderId)
            .pipe(map((result:any)=>{
                if(!result) return {};
                return result.orders[0];
            }));
    }

    getRecentEntityOrders(entityID, start, count, params): Observable<XRDOrder[]>{

        let paramString = 'paid=true&tiny=true&start=' + start + '&count=' + count + '&';

        // Concat params
        for(let param in params){
            if(params.hasOwnProperty(param)){
                paramString += param + '=' + encodeURIComponent(params[param]) + '&';
            }
        }
        // Get rid of last '&'
        paramString = paramString.substring(0, paramString.length-1);

        return this.http.get(this.constantsService.getBackendURL() + 'entity/' + entityID + '/order?' + paramString)
            .pipe(map((result:any)=>{
                if(!result) return [];
                let orders;
                if(result.orders){
                    orders = result.orders;
                }else{
                    orders = result;
                }
                return orders;
            }));
    }

    getEntityOrderByID(entityID, orderID): Observable<XRDOrder>{
        return this.http.get(this.constantsService.getBackendURL() + 'entity/' + entityID + '/order/' + orderID)
            .pipe(map((result:any)=>{
                if(!result) return {};
                return result.orders[0];
            }));
    }

    getOrderByOrderNumber(orderNumber): Observable<XRDOrder>{
        return this.http.get(this.constantsService.getBackendURL() + 'order?ordernumber=' + orderNumber)
            .pipe(map((result:any)=>{
                if(!result) return {};
                return result.orders[0];
            }));
    }

    getOrderByID(orderID): Observable<XRDOrder>{
        return this.http.get(this.constantsService.getBackendURL() + 'order/' + orderID)
            .pipe(map((result:any)=>{
                if(!result) return {};
                return result.orders[0];
            }));
    }

    updateOrderStatus(entityID, orderID, orderStatus){

        let payload = {
            orders: [{
                orderstatus: orderStatus
            }]
        };

        return this.http.put(this.constantsService.getBackendURL() + 'entity/' + entityID + '/order/' + orderID, JSON.stringify(payload))
            .pipe(map((result:any)=>{
                return result;
            }));
    }

    updateOrder(orderID, order:XRDOrder){
        let payload = {
            orders: [
                order
            ]
        };

        return this.http.put(this.constantsService.getBackendURL() + 'order/' + orderID, JSON.stringify(payload))
            .pipe(map((result:any)=>{
                return result;
            }));
    }

    getOrderItems(orderID){
        return this.http.get(this.constantsService.getBackendURL() + 'order/' + orderID + '/item?start=0&count=1000')
            .pipe(map((result:any)=>{
                if(!result) return [];
                return result.items || [];
            }));
    }

    getDeepItemsByOrderID(orderID){
        return this.http.get(this.constantsService.getBackendURL() + 'order/' + orderID + '/item?start=0&count=1000')
            .pipe(flatMap((result:any) =>{
                let items = result.items;
                let oProducts = [];
                for(let i=0; i<items.length; i++){
                    oProducts.push(this.http.get(this.constantsService.getBackendURL() + 'product/' + items[i].productid)
                        .pipe(map((products:any)=>{
                            let product = products.products[0];
                            // Closure usage! i is preserved using closures.
                            items[i].product = product;
                            console.log('i:', i);
                            return items[i];
                        }))
                    );
                }
                return observableForkJoin(...oProducts);
            }));
    }

    createOrder(entityID: string, options?: any){
        let payload = {
            'orders': [{
                "ordertype": 'WebSite',
                "entityid": entityID,
            }]
        };

        if (options) {
            Object.getOwnPropertyNames(options).forEach((value, index) => {
                payload.orders[0][value] = options[value];
            });
        }

        return this.http.post(this.constantsService.getBackendURL() + 'order', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                if(!res) return undefined;
                return res.orders[0] || undefined;
            }));
    }

    createOrderItem(orderID: string, productID: string, productVariantID: string, amount: number, quantity: number, color: string, size: string){
        let payload = {
            'items': [{
                "orderid": orderID,
                "productid": productID,
                "productvariantid": productVariantID,
                "amount": amount,
                "quantity": quantity,
                "color" : color?color:'',
                "size" : size?size:'',
                "subtotal": amount * quantity,
            }]
        };
        return this.http.post(this.constantsService.getBackendURL() + 'order/' + orderID + '/item', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res.items || [];
            }));
    }

    updateOrderItem(orderID: string, orderItemID: string, order: XRDOrderItem){
        let payload = {
            'items': [
                order
            ]
        };
        return this.http.put(this.constantsService.getBackendURL() + 'order/' + orderID + '/item/' + orderItemID, JSON.stringify(payload))
            .pipe(map((res:any)=> {
                return res || [];
            }));
    }

    removeOrderItem(orderID: string, orderItemID: string){
        return this.http.delete(this.constantsService.getBackendURL() + 'order/' + orderID + '/item/' + orderItemID)
            .pipe(map((res:any)=> {
                return res || [];
            }));
    }

    createOrderPayment(orderID: string, paymentMethodID: string, cvv: string, ipAddress: string, invoice: string) {
        let payload = {
            'orderpayments': [{
                "paymentmethodid": paymentMethodID,
                "cvv": cvv,
                "ipaddress": ipAddress,
                "invoice": invoice,
                "orderpaymenttype": "Credit", // orders from the web are always credit
            }]
        };
        return this.http.post(this.constantsService.getBackendURL() + 'order/' + orderID + '/payment', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                if(!res) return {};
                return res.orderpayments[0] || {};
            }));
    }

    createOrderAddress(orderId, address:XRDAddress): Observable<XRDAddress>{
        let payload = {
            addresses: [
                {
                    addresstype: 'WebSite',
                    fullname: address.fullname,
                    address1: address.address1,
                    address2: address.address2,
                    city: address.city,
                    state: address.state,
                    zip: address.zip,
                    country: 'USA',
                    region: 'North America',
                    latitude: '0',
                    longitude: '0',
                }
            ]
        };

        return this.http.post(this.constantsService.getBackendURL() + 'order/' + orderId + '/address', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                if(!res) return undefined;
                let result = res.orders[0].addresses[0] || undefined;
                return result;
            }));
    }

    getOrderPayment(orderID: string, paymentID: string){
        return this.http.get(this.constantsService.getBackendURL() + 'order/' + orderID + '/payment/' + paymentID)
            .pipe(map((res:any)=> {
                if(!res) return {};
                return res.orderpayments[0] || {};
            }));
    }

    getOrderPayments(orderID: string): Observable<XRDOrderPayment[]>{
        return this.http.get(this.constantsService.getBackendURL() + 'order/' + orderID + '/payment?start=0&count=100')
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res.payments || [];
            }));
    }

    captureOrderPayment(orderID: string, paymentID: string, paymentMethodID: string, amount){
        let payload = {
            'orderpayments': [{
                "paymentmethodid": paymentMethodID,
                "amount": amount,
            }]
        };
        // console.log('capturing...');
        // console.log(this.constantsService.getBackendURL() + 'order/' + orderID + '/payment/' + paymentID + '/capture');
        // console.log('payload', JSON.stringify(payload));
        return this.http.post(this.constantsService.getBackendURL() + 'order/' + orderID + '/payment/' + paymentID + '/capture', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                if(!res) return {};
                return res.orderpayments[0] || {};
            }));
    }

    refundOrderPayment(order: XRDOrder, type: string, notes?: string){
        let payload = {
            'orderpayments': [{
                "paymentmethodid": order.payments[0].paymentmethodid,
                // "amount": order.payments[0].amount, // Don't pass in amount so Robert knows it's a full refund
                "orderpaymenttype": type,
            }]
        };

        if(notes) payload.orderpayments[0]['notes'] = notes;

        return this.http.post(this.constantsService.getBackendURL() + 'order/' + order.orderid + '/payment/' + order.payments[0].paymentid + '/refund', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                if(!res) return {};
                return res.orderpayments[0] || {};
            }));
    }

    voidOrderPayment(order:XRDOrder, notes?: string){
        let payload = {
            'orderpayments': [{
                "paymentmethodid": order.payments[0].paymentmethodid,
                // "amount": order.payments[0].amount,
                "orderpaymenttype": "Credit", 
            }]
        };

        if(notes) payload.orderpayments[0]['notes'] = notes;

        return this.http.post(this.constantsService.getBackendURL() + 'order/' + order.orderid + '/payment/' + order.payments[0].paymentid + '/void', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                if(!res) return {};
                return res.orderpayments[0] || {};
            }));
    }

    getOrderCoupons(orderID: string){
        return this.http.get(this.constantsService.getBackendURL() + 'order/' + orderID + '/coupon?start=0&count=20')
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res.coupons || [];
            }));
    }

    createOrderCoupon(orderID: string, couponCode: string){
        let payload = {
            "coupons": [{
                "couponcode": couponCode
            }]
        };
        return this.http.post(this.constantsService.getBackendURL() + 'order/' + orderID + '/coupon', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res.coupons || [];
            }));
    }

    removeOrderCoupon(orderID: string, couponId: string){
        return this.http.delete(this.constantsService.getBackendURL() + 'order/' + orderID + '/coupon/' + couponId)
            .pipe(map((res:any)=> {
                if(!res) return {};
                return res || {};
            }));
    }

    createOrderTracking(orderID: string, trackingNumber: string, shippingVendor: string){
        let payload = {
            "trackings": [{
                "trackingnumber": trackingNumber,
                "shippingvendor": shippingVendor
            }]
        };
        return this.http.post(this.constantsService.getBackendURL() + 'order/' + orderID + '/tracking', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                if(!res) return undefined;
                return res.trackings[0].ordertrackingid || undefined;
            }));
    }

    updateOrderItemWithTrackingId(orderID: string, orderItemID: string, orderTrackingId:string){
        let payload = {
            'items': [{
                ordertrackingid: orderTrackingId
            }]
        };
        return this.http.put(this.constantsService.getBackendURL() + 'order/' + orderID + '/item/' + orderItemID, JSON.stringify(payload))
            .pipe(map((res:any)=> {
                return res || [];
            }));
    }

    notifySlackAboutOrder(order:XRDOrder){
        return this.http.post(
            this.constantsService.getNodeBackendURL() + 'slack/order',
            JSON.stringify(order),
            false,
            {'Content-Type': 'application/json'}
        );
    }

}
