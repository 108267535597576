import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ComponentRef, OnDestroy, Renderer2 } from '@angular/core';

@Component({
    selector: 'xion-fade-out-notification',
    templateUrl: './fade-out-notification.component.html',
    styleUrls: [ './fade-out-notification.component.css' ]
})
export class FadeOutNotificationComponent implements OnInit {

    show: boolean = false;
    fadeOut: boolean = false;
    @Input() timeLength: number = 2000;
    @Input() displayText: string = 'Saved';
    /** If any theme is passed in, right now I'm just defaulting to 'warning' */
    @Input() theme: string = '';
    /** Tell stacked component that this notification is ready to be popped off the array */
    @Output() fadeFinished = new EventEmitter();

    constructor() {}

    ngOnInit(){
        this.showNotification();
    }

    public showNotification(message?: string, theme?: string, length?: number){
        // If provided with message or theme, use those
        // if(message) this.displayText = message;
        // if(theme) this.theme = theme;
        // else this.theme = ''; // Default

        if(this.show) return;
        this.show = true;

        // Handle fading out after so many seconds
        setTimeout(()=>{
            this.fadeOut = true;
            // console.log('fadeOut ',this.displayText);
            setTimeout(()=>{
                this.show = false;
                this.fadeOut = false;
                // console.log('done ',this.displayText);
                this.fadeFinished.emit('finished'); // Pop off from array
            }, 1000);
        }, length || this.timeLength);
    }
}
