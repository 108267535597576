import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CartItemSummaryComponent} from "./cart-item-summary.component";
import {HoverReplaceRemoveModule} from "../../../../shared/hover-replace-remove/hover-replace-remove.module";
import {CenteredImageModule} from "../../../../shared/centered-image/centered-image.module";

@NgModule({
    imports: [
        CommonModule,
        HoverReplaceRemoveModule,
        CenteredImageModule,
    ],
    declarations: [
        CartItemSummaryComponent
    ],
    exports: [
        CartItemSummaryComponent
    ]
})
export class CartItemSummaryModule { }
