import {Component, OnInit, ViewChild} from '@angular/core';

@Component({
    selector: 'xion-main-header-rotating-messages',
    templateUrl: './main-header-rotating-messages.component.html',
    styleUrls: ['./main-header-rotating-messages.component.css']
})
export class MainHeaderRotatingMessagesComponent implements OnInit {

    @ViewChild('mainModal', { static: true }) mainModal;

    selectedIndex: number = 0;

    rotatingMessages = [
        {
            message: 'Free shipping on orders over $75*',
            messageId: 'shipping',
            modalBody: `
            <h2>Free shipping on orders over $75</h2>
            <p>
                If your order totals $75 or more you may qualify for free ground shipping.
            </p>
            <h3>How it works:</h3>
            <ul>
            	<li>Order totals are calculated on a per retailer basis.</li>
                <li>Some items may not be available to be shipped.</li>
                <li>Ground shipping may take 5 to 10 business days to arrive once shipped.</li>
                <li>Retailers individually choose which courier to use for ground shipping including but not limited to Fedex, UPS, or USPS.</li>
                <li>Orders under $75 may qualify for ground shipping, which will cost $10.</li>
                <li>All items on an order must use the same shipping method which may limit the shipping options to the item with the most shipping restrictions.</li>
            </ul>
            `
        },
        {
            message: 'Free next day local delivery on orders over $75*',
            messageId: 'delivery',
            modalBody: `
            <h2>
                Free next day local delivery on orders over $75
            </h2>
            <p>
                If your order totals $75 or more you may qualify for free next day delivery.
            </p>
            <h3>How it works:</h3>
            <ul>
            	<li>Order totals are calculated on a per retailer basis.</li>
                <li>Some items may not be available to be delivered.</li>
                <li>Retailers individually set the max distance they are willing to deliver. Most retailers set the max distance to 50 miles driving distance.</li>
                <li>Orders under $75 may qualify for next day local delivery, which will cost $10.</li>
                <li>All items on an order must use the same shipping method which may limit the shipping options to the item with the most shipping restrictions.</li>
            </ul>
            `
        },
        {
            message: 'Free in-store pickup*',
            messageId: 'pickup',
            modalBody: `
            <h2>Free in-store pickup</h2>
            <p>
                One of the advantages of shopping local is being able to pickup your 
                items yourself at your local retailers.
            </p>
            <h3>How it works:</h3>
            <ul>
                <li>
                     Most items are available to be picked up immediately and 
                     don’t require contacting the retailer before arrival, but it’s not 
                     a bad idea to check with the retailers first to make sure the item(s) 
                     is ready to be picked up.
                </li>
                <li>
                    Some items may not be available to be picked up in-store, such as special order items.
                </li>
                <li>
                    All items on an order must use the same shipping method which may limit the shipping 
                    options to the item with the most shipping restrictions.
                </li>
            </ul>
            `
        },

    ];

    constructor() { }

    ngOnInit() {}

    showModal(index){
        this.selectedIndex = index;
        this.mainModal.open();
    }
}
