import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'xion-popup',
    template: `
        <div class="background" (click)="closeModal()"></div>

        <div class="modal">
            <router-outlet></router-outlet>
        </div>
    `,
    styles: [`
        .background {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 4;
        }
        .modal{
            z-index: 5;
            position: absolute;
            top: 156px;
            width: 90%;
            max-width: 500px;
            margin: 0 auto;
            left: 0;
            right: 0;
            background: white;
            border-radius: 8px;
            padding: 0;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
        }
    `]
})
export class PopupComponent implements OnInit {

    constructor(private router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
    }

    // Source: https://www.bennadel.com/blog/3351-closing-secondary-router-outlet-views-from-within-the-named-route-view-components-in-angular-4-4-4.htm
    closeModal(){
        this.router.navigate(
            [
                {
                    outlets: {modal: null}
                }
            ],
            {
                relativeTo: this.route.parent
            }
        )
    }

}
