import {Component, OnInit, Input, ElementRef, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {GlobalEventsService} from "../../../global-events.service";
import {MarketplaceMainNavService} from "../../../../marketplace/marketplace-main-nav.service";
import { GlobalStoreEventsService } from '../../../global-store-events.service';

@Component({
  selector: 'xion-main-header-search-filter',
  templateUrl: './main-header-search-filter.component.html',
  styleUrls: ['./main-header-search-filter.component.css']
})
export class MainHeaderSearchFilterComponent implements OnInit {
    isLoading: boolean;

    // selectedProductCategory: any;
    @Output() initiateSearch = new EventEmitter();
    @Output() focusOnInput = new EventEmitter();

    constructor(public marketplaceMainNavService: MarketplaceMainNavService,
                private changeDetectorRef: ChangeDetectorRef,
                public gseService: GlobalStoreEventsService,
                private globalEventsService: GlobalEventsService) {}

    ngOnInit() {
        // console.log('MainHeaderSearchFilterComponent:ngOnInit()', this.globalEventsService.mainSearchFilter);
    }

    onClickXionFilterItem(chosenCat) {
        this.gseService.useStoreSearch.next(false);
        this.changeDetectorRef.detectChanges();
        this.handleChosenFilterItem(chosenCat);
    }

    onClickStoreFilterItem(chosenCat) {
        this.gseService.useStoreSearch.next(true);
        this.changeDetectorRef.detectChanges();
        this.handleChosenFilterItem(chosenCat);
    }

    private handleChosenFilterItem(chosenCat){
        this.globalEventsService.mainSearchFilter = chosenCat;
        // If there is something in the search field when the item is clicked, search it!
        if(this.globalEventsService.mainSearchQuery){
            this.initiateSearch.emit(true);
        // If empty, just set focus on input
        }else{
            this.focusOnInput.emit(true);
        }
    }
}
