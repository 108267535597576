import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'time'
})
export class TimePipe implements PipeTransform {
    /**
     * Coming in format: '20:30'
     * Going out format: '8:30 PM'
     */
    transform(value: string):any {
        let hour = Number(value.substring(0, value.indexOf(':')));
        let mins = value.substring(value.indexOf(':'));
        let ampm: string;

        // Set PM
        if(hour > 12){
            ampm = ' PM';
            hour = hour - 12;
        }else{ // Set AM
            ampm = ' AM';
        }
        // Don't display '00' for mins
        if(mins === ':00'){
            mins = '';
        }

        return hour+mins+ampm;
    }
}
