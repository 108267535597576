import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { MAIN_NAV_EXTRA_ITEMS } from '../main-header-extra-menu-items/nav-side-bar-items';

@Component({
    selector: 'xion-main-header-xion-menu',
    template: `
    <button type="button" class="no-style-btn" (click)="toggleDropdown()">
        XION Menu
        <img src="/assets/img/svg/down_arrow.svg">
    </button>

    <div id="lil-modal" class="shadowed" *ngIf="isOpenFlag">
        <div class="lil-carrot">▾</div>
        <ul>
            <li *ngFor="let item of extraItems">
                <a *ngIf="item.title === 'Blog' || item.title === 'Sell on XION'" (click)="toggleDropdown()" [href]="item.routePath" [tabindex]="isOpenFlag?'0':'-1'">
                    {{item.title}}
                </a>
                <a *ngIf="item.title !== 'Blog' && item.title !== 'Sell on XION'" (click)="toggleDropdown()" [routerLink]="[item.routePath]" [tabindex]="isOpenFlag?'0':'-1'">
                    {{item.title}}
                </a>
            </li>
        </ul>
    </div>
  `,
    styles: [`
        :host{
            display: block;
            position: relative;
        }
        button{
            font-size: 14px;
            letter-spacing: 0.8px;
            color: #152438;
            line-height: 1.2;
            margin-top: 20px;
            padding: 0;
        }
        button img{
            width: 11px;
        }
        .lil-modal{

        }
        .lil-modal {
            position: relative;
            top: 0;
            margin: 0;
            border-radius: 3px;
            width: 190px;
        }
        ul{
            padding: 10px 0;
            width: 168px;
            margin: 0;
            border-radius: 3px;
            background-color: white;
            color: #686a6b;
            list-style-type: none;
        }
        li{

        }
        li a {
            display: inline-block;
            width: 100%;
            font-size: 17px;
            margin: 0;
            padding: 5px 10px;
            color: #686a6b;
            letter-spacing: 0.8px;
            line-height: 23px;
        }
        
        li a:hover, li a:focus {
            background-color: #CCCCCC;
            outline: none;
        }
        
        .lil-carrot {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            position: absolute;
            right: 35px;
            top: -18px;
            z-index: -1;
            color: white;
            text-shadow: 0 -1px 14px rgba(0,0,0,0.25);
        }
        .shadowed{
            /*box-shadow: 0px 2px 14px rgba(0,0,0,0.25), 0 8px 10px rgba(0,0,0,0.22);*/
            box-shadow: 0px 4px 10px rgba(0,0,0,0.25), 0 0px 10px rgba(0,0,0,0.22);
            right: -23px;
            top: 49px;
            z-index: 2;
            border-radius: 3px;
            display: block;
            position: absolute;
        }
    `]
})
export class MainHeaderXionMenuComponent implements OnInit {

    extraItems = MAIN_NAV_EXTRA_ITEMS;
    isOpenFlag = false;
    
    constructor(private _eref: ElementRef) { }

    ngOnInit() {
    }

    // onClick of window anywhere but this component and myButton
    @HostListener('document:click', ['$event'])
    onClick(event) {
        if (!this._eref.nativeElement.contains(event.target)) {
            this.isOpenFlag = false;
        }
    }

    toggleDropdown() {
        this.isOpenFlag = !this.isOpenFlag;
    }

}
