import {Component, OnInit, ElementRef, Input} from '@angular/core';

@Component({
  selector: 'xion-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.css'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class PopoverComponent implements OnInit {

    @Input() showPopover: { value: boolean } = { value: false };
    @Input() myButton: any;
    @Input() hasLilCarrot: boolean = false;
    @Input() top: number = 0;
    @Input() right: number = 0;

    constructor(private _eref: ElementRef) {}

    ngOnInit() {
        // console.log('MainHeaderYourAccountComponent:ngOnInit()');
    }

    // onClick of window anywhere but this component and myButton
    onClick(event) {
        if (!this._eref.nativeElement.contains(event.target) && !this.myButton.contains(event.target)) {
            this.showPopover.value = false;
        }
    }

    toggleDropdown() {
        this.showPopover.value = !this.showPopover.value;
    }
}
