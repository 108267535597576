<footer #mainFooter>

    <!--GENERAL PRODUCT PAGE FOOTER-->
    <div id="gen-product-footer" *ngIf="gppeService.genProdPageHasSellerInfo">
        <p>Contact <a [routerLink]="['/'+gppeService.genProdPageSellerSeoName]">{{gppeService.genProdPageSellerName}}</a> for questions about this product:</p>
        <a *ngIf="gppeService.genProdPagePhone" [href]="'tel:'+gppeService.genProdPagePhone">
            <i class="fa fa-phone" aria-hidden="true"></i>
            <p>{{gppeService.genProdPagePhone}}</p>
        </a>
        <a *ngIf="gppeService.genProdPageEmail" [href]="'mailto:'+gppeService.genProdPageEmail">
            <i class="fa fa-envelope" aria-hidden="true"></i>
            <p>{{gppeService.genProdPageEmail}}</p>
        </a>
    </div>

    <!--RETAILER FOOTER-->
    <div id="retailer-footer" *ngIf="gseService.isStorePage && !gseService.dontShowStoreFooter">

        <h6 class="seller-title">{{gseService.storeNameForFooter}}</h6>

        <div class="flex-container">
            <div class="phone column">
                <i class="fa fa-phone" aria-hidden="true"></i>
                <div>
                    <a [href]="'tel:'+gseService.storePhone">
                        <p class="heavier">Call {{gseService.storeNameForFooter}}</p>
                        <p *ngIf="gseService.storePhone">{{gseService.storePhone}}</p>
                        <p *ngIf="!gseService.storePhone">unknown</p>
                    </a>
                </div>
            </div>
            <div class="email column">
                <div>
                    <p class="heavier center">Store Hours</p>
                    <p *ngIf="!gseService.storeHours">unknown</p>
                    <xion-store-hours-display-list *ngIf="gseService.storeHours"
                                                   [hoursData]="gseService.storeHours">
                    </xion-store-hours-display-list>
                </div>
            </div>
            <div class="email column" *ngIf="gseService.storeEmail">
                <i class="fa fa-envelope" aria-hidden="true"></i>
                <div>
                    <a [href]="'mailto:'+gseService.storeEmail">
                        <p class="heavier">Email {{gseService.storeNameForFooter}}</p>
                    </a>
                    <p *ngIf="gseService.storeEmail">{{gseService.storeEmail}}</p>
                    <p *ngIf="!gseService.storeEmail">unknown</p>
                </div>
            </div>
        </div>
    </div>

    <hr *ngIf="(gseService.isStorePage && !gseService.dontShowStoreFooter) || gppeService.genProdPageHasSellerInfo">

    <!--MAIN FOOTER-->
    <div id="main-footer">

        <div class="fancy-font">
            <h6>Shop Local Online</h6>
        </div>

        <img id="main-logo" src="/assets/img/new_logos/xion_dot_store_white.png" alt="xion logo">

        <div id="bottom">
            <div class="bottom-flex">
                <div class="social-media">
                    <a href="https://www.facebook.com/shopxion/" target="_blank">
                        <i class="fa fa-facebook-square" aria-hidden="true"></i>
                    </a>
                    <a href="https://twitter.com/XIONretail" target="_blank">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                    <a href="https://www.youtube.com/channel/UCb_lGDnFCF8v6p7c7-glGgg" target="_blank">
                        <i class="fa fa-youtube-play" aria-hidden="true"></i>
                    </a>
                    <a href="https://www.instagram.com/xion.store/" target="_blank">
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                </div>
                <p class="rights-reserved">2021. XION Retail, Inc. All rights reserved.</p>
            </div>

            <div class="links">
                <a [routerLink]="['/policies']">Return Policy</a>
                <a [routerLink]="['/resources/privacy-policy']">Privacy Policy</a>
                <a [routerLink]="['/about-us']">About XION</a>
                <a [routerLink]="['/contact-us']">Contact XION</a>
                <a href="https://retail.xion.store">Sell on XION</a>
            </div>
        </div>
    </div>

</footer>
