import { Injectable } from "@angular/core";
import {environment} from "../../environments/environment";

@Injectable()
/**
 * A global service used primarily to access the API URL.
 */
export class ConstantsService {

    /** CONSTANTS */
    // Backend PHP server
    private prodBackendURL = 'https://papi.xion.store/api/v1/'; // PRODUCTION
    private betaBackendURL = 'https://bapi.xion.store/api/v1/'; // BETA
    private devBackendURL = 'http://www.robertivie.com/api/v1/'; // BACKEND DEVELOPMENT

    // Backend Node server
    private prodNodeBackendURL = 'https://imagepapi.xion.store/api/v1/';
    private betaNodeBackendURL = 'https://imagebapi.xion.store/api/v1/';
    // private betaNodeBackendURL = 'http://localhost:5009/api/v1/';

    // Frontend Node server
    private prodFrontEndDomain = 'https://xion.store/';
    private betaFrontEndDomain = 'https://beta.xion.store/';

    // ProPay server
    private propayBetaURL = 'https://protectpaytest.propay.com/';
    private propayProdURL = 'https://protectpay.propay.com/';

    // TalkJS
    private betaTalkJsAppId = 'tMn9bg01';
    private prodTalkJsAppId = 'yZMsMwoF';

    /** URLS IN USE */
    private nodeBackendURL:string;
    private frontendDomainURL:string;
    private backendURL:string;
    private propayURL:string;
    private talkJsAppId:string;

    constructor() {
        // CHANGE THIS LINE TO SET THE URL FOR THE ENTIRE APP TO USE.
        if(environment.production){
            // Use beta backend with production build
            console.info('PRODUCTION');
            this.backendURL = this.prodBackendURL;
            this.nodeBackendURL = this.prodNodeBackendURL;
            this.propayURL = this.propayProdURL;
            this.frontendDomainURL = this.prodFrontEndDomain;
            this.talkJsAppId = this.prodTalkJsAppId;
        } else{
            console.info('BETA');
            this.backendURL = this.betaBackendURL;
            this.nodeBackendURL = this.betaNodeBackendURL;
            this.propayURL = this.propayBetaURL;
            this.frontendDomainURL = this.betaFrontEndDomain;
            this.talkJsAppId = this.betaTalkJsAppId;
        }
    }

    // Returns the URL being used to access the API.
    public getBackendURL(){
        return this.backendURL;
    }

    public getNodeBackendURL(){
        return this.nodeBackendURL;
    }

    public getPropayURL(){
        return this.propayURL;
    }

    public getFrontendURL(){
        return this.frontendDomainURL;
    }
    public getTalkJsAppId(){
        return this.talkJsAppId;
    }
}
