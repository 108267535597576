export class XRDEntityCategory {
    departments: {
        name: string;
        departmentid: string;
        categories: {
            name: string;
            categoryid: string;
            subcategories: {
                name: string;
                subcategoryid: string;
            }[]
        }[]
    }[];
}
