<div *ngIf="isShowing || isShowingObj.value" [class.vertical-center]="verticalCenter">
    <div class="overlay" (click)="close()" [class.overlay-darken]="darkOverlay === true">
    </div>
    <div class="container"
         [style.width.px]="modalWidth"
         [style.max-width.%]="modalMaxWidth"
         [style.top.px]="modalTop"
         [style.bottom.px]="modalBottom"
         [class.border]="border"
         [class.twilight]="getStyle()"
         [class.relative]="position === 'relative'"
         [class.bottomless]="bottomless"
         [class.container-relative]="containerRelative"
         [class.box-shadow]="boxShadow"
         [class.vertical-center-me]="verticalCenter">
        <div id="xion-modal-scroll-to"></div>
        <ng-content></ng-content>
    </div>
</div>

