import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MainHeaderSearchComponent} from "./main-header-search.component";
import {SharedModule} from "../../shared.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    declarations: [
        MainHeaderSearchComponent
    ],
    exports: [
        MainHeaderSearchComponent
    ]
})
export class MainHeaderSearchModule { }
