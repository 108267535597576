import {Component, OnInit, ElementRef, HostListener} from '@angular/core';
import {MarketplaceMainNavService} from "../../../marketplace/marketplace-main-nav.service";
import {MAIN_NAV_EXTRA_ITEMS} from "../main-header-extra-menu-items/nav-side-bar-items";


@Component({
    selector: 'xion-main-header-nav-dropdown',
    templateUrl: './main-header-nav-dropdown.component.html',
    styleUrls: ['./main-header-nav-dropdown.component.css'],
})
export class MainHeaderNavDropdownComponent implements OnInit {

    mainDropdownIsOpen: boolean = false;
    extraItems = MAIN_NAV_EXTRA_ITEMS;

    private menuDebounceOpener = false;

    constructor(private _eref: ElementRef, public marketplaceMainNavService: MarketplaceMainNavService) {}

    ngOnInit() {}

    @HostListener('document:click', ['$event'])
    onClick(event) {
        if (!this._eref.nativeElement.contains(event.target)) {
            this.mainDropdownIsOpen = false;
        }
    }

    toggleMainDropdown(){
        this.mainDropdownIsOpen = !this.mainDropdownIsOpen;
    }

    showDropDown(){
        console.log('hover!');
        this.menuDebounceOpener = true;
        setTimeout(()=>{
            if(this.menuDebounceOpener){
                this.mainDropdownIsOpen = true;
            }
        }, 150);
    }

    cancelShowDropDown() {
        this.menuDebounceOpener = false;
    }

    hideDropDown(){
        console.log('hide main dd');
        this.mainDropdownIsOpen = false;
    }

}
