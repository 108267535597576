import {MainNavExtraItem} from "./main-nav-extra-item.class";

export var MAIN_NAV_EXTRA_ITEMS: MainNavExtraItem[] = [
    { title: 'About XION', routePath: '/about-us', },
    { title: 'Contact Us', routePath: '/contact-us', },
    { title: 'Policies', routePath: '/policies', },
    { title: 'Stores on XION', routePath: '/stores', },
    { title: 'Sell on XION', routePath: 'https://retail.xion.store', },
    { title: 'Blog', routePath: 'https://magazine.xion.store', },
];
