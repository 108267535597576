//import * as xrdService from './xrd.service';
//import * as xrdProductService from './product';
export * from './xrd.service';
export * from './product';
export * from './entity';
export * from './user';
export * from './shared';
export * from './search';

//export const XRD_SERVICE_PROVIDERS: Array<any> = [xrdService, xrdProductService];

