import {Injectable} from "@angular/core";

@Injectable()
export class GlobalProductPageEventsService {
    
    /** Gen product page stuff */
    genProdPageHasSellerInfo: boolean = false;
    genProdPageSellerName: string;
    genProdPageSellerSeoName: string;
    genProdPageEmail: string;
    genProdPagePhone: string;

    constructor() {}
}
