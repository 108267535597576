import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {Cart} from "../../cart/cart.model";
import { ICartItemSummary } from './cart-item-summary/cart-item-summary.model';
import { TalkService } from '../../../shared/talk/talk.service';

@Component({
    selector: 'xion-cart-items-summary',
    template: `
        <div *ngFor="let entityOrder of userCart.entities | values:'key':true">
            <h4>
                Items from 
                <span *ngIf="isRemovable">
                    <a *ngIf="entityOrder.orderItems[0].parentEntityID === '1'" 
                       (click)="buttonClicked.emit(true)"
                       [routerLink]="[entityOrder.orderItems[0].entitySeoName]">
                            {{entityOrder.orderItems[0].entity}}
                    </a>
                    <a *ngIf="entityOrder.orderItems[0].parentEntityID !== '1'" 
                       [routerLink]="[entityOrder.orderItems[0].parentEntitySeoName]"
                       (click)="buttonClicked.emit(true)" 
                       [queryParams]="{'storefront':entityOrder.orderItems[0].entityID}">
                            {{entityOrder.orderItems[0].entity}}
                    </a>
                </span>
                <span *ngIf="!isRemovable">{{entityOrder.orderItems[0].entity}}</span>
            </h4>
            <!--<div class="button-container">
                <button type="button" 
                        *ngIf="!isRemovable" 
                        class="no-style-btn message-seller"
                        (click)="loadChatForEntity(entityOrder.orderItems)">
                    Message Seller
                </button>
            </div>-->
            <xion-cart-item-summary
                *ngFor="let orderItem of entityOrder.orderItems"
                [item]="orderItem"
                [isRemovable]="isRemovable">
            </xion-cart-item-summary>
        </div>
    `,
    styles: [`
        h4, 
        h4 a{
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.8px;
            line-height: 1.3;
            margin: 13px 0 5px;
        }
        h4 a{
            color: black;
        }
        h4 a:hover{
            color: #00a3ff;
        }
        xion-cart-item-summary{
            display: block;
            border-top: solid 1px #e4e4e4;
        }
        xion-cart-item-summary:first-of-type{
            border-top: none;
        }
        .button-container{
            display: flex;
        }
        button.message-seller{
            font-size: 15px;
            color: #00a3ff;
            margin: 0;
            line-height: 1;
            padding: 0;
        }
    `]
})
export class CartItemsSummaryComponent implements OnInit {

    @Input() userCart: Cart;
    @Input() isRemovable = false;
    @Output() buttonClicked = new EventEmitter()

    constructor(private talkService: TalkService) { }

    ngOnInit() {
        
    }

    loadChatForEntity(cartItems:ICartItemSummary[]){
        console.log('loading chat for entity:', cartItems[0].entityID);
        let chatConversationSubject = '';
        let chatConversationId = '';
        for(let cartItem of cartItems){
            chatConversationSubject += `${cartItem.brand} ${cartItem.name}${cartItem.modelYear?' '+cartItem.modelYear:''}, `;
            chatConversationId += cartItem.productID;
        } 
        // Remove trailing ', ';
        chatConversationSubject = chatConversationSubject.slice(0, -2);

        if(cartItems[0].parentEntityID && cartItems[0].parentEntityID !== '1'){
            console.log('loading chat for parententity:', cartItems[0].parentEntityID);
            this.talkService.openChatPopupForEntityId(cartItems[0].parentEntityID, {
                welcomeMessage:'Hi, how can I help you?',
                showImmediately: true,
                subject: chatConversationSubject,
                subjectAddOnId: chatConversationId,
            }).subscribe();
        }else{
            console.log('loading chat for entity:', cartItems[0].entityID);
            this.talkService.openChatPopupForEntityId(cartItems[0].entityID, {
                welcomeMessage:'Hi, how can I help you?',
                showImmediately: true,
                subject: chatConversationSubject,
                subjectAddOnId: chatConversationId,
            }).subscribe();
        }
    }

}
