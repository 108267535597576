<div id="main">
    <button (click)="toggleDropdown()">
        Your Account<span class="down-arrow"></span>
    </button>
    <div class="shadowed" [class.double-column]="(storesCount >= 1)"
        [style.display]="isDropdownShowing?'block':'none'">
        <div class="lil-carrot" *ngIf="showCarrot">▾</div>

        <div *ngIf="sessionService.isUserSignedIn(); then loggedIn else notLoggedIn"></div>

        <ng-template #loggedIn>

            <div *ngIf="storesCount >= 1; then hasStore else noStore"></div>

            <ng-template #hasStore>
                <ul>
                    <h2>Your Account</h2>
                    <li><a [routerLink]="['/account']" (click)="toggleDropdown()">Your Account</a></li>
                    <!-- <li><a [routerLink]="['/your-messages']" (click)="toggleDropdown()">Your Messages</a></li> -->
                    <li><a [routerLink]="['/your-orders']" (click)="toggleDropdown()">Your Orders</a></li>
                    <li><a (click)="signOut()">Sign Out</a></li>
                </ul>
                <div class="vertical-line"></div>
                <ul>
                    <h2 *ngIf="sessionService.getAdminEntitySEOName()">{{sessionService.getAdminEntityName()}}</h2>
                    <li *ngIf="sessionService.getAdminEntitySEOName()">
                        <a [routerLink]="['/', sessionService.getAdminEntitySEOName()]" (click)="toggleDropdown()">Store Homepage</a>
                    </li>
                    <li *ngIf="sessionService.getAdminEntitySEOName()">
                        <a [routerLink]="['/' + sessionService.getAdminEntitySEOName() + '/admin']"
                           (click)="toggleDropdown()">Store Admin</a>
                    </li>
        
                    <li *ngIf="storesCount > 1">
                        <a (click)="toggleDropdown(); storesModal.open()">Switch Stores</a>
                    </li>
                </ul>
            </ng-template>

            <ng-template #noStore>
                <ul class="your-account-dropdown">
                    <li><a [routerLink]="['/account']" (click)="toggleDropdown()">Your Account</a></li>
                    <!-- <li><a [routerLink]="['/your-messages']" (click)="toggleDropdown()">Your Messages</a></li> -->
                    <li><a [routerLink]="['/your-orders']" (click)="toggleDropdown()">Your Orders</a></li>
                    <li><a (click)="signOut()">Sign Out</a></li>
                </ul>
            </ng-template>

        </ng-template>

        <ng-template #notLoggedIn>
            <ul class="your-account-dropdown">
                <li><a [routerLink]="['/sign-in']" (click)="toggleDropdown()">Sign In</a></li>
                <li><a [routerLink]="['/create-account']" (click)="toggleDropdown()">Create Account</a></li>
            </ul>
        </ng-template>

    </div>
</div>

<xion-store-admin-modal #storesModal [stores]="stores"></xion-store-admin-modal>
