import { Component, OnInit, Input, OnDestroy, HostListener, Inject, Renderer2 } from '@angular/core';
import {FadeOutNotificationService} from "./fade-out-notification/fade-out-notification.service";
import {FadeOutNotification} from "./fade-out-notification/fade-out-notification.class";
import {WindowRef} from "../../WindowRef";
import {DOCUMENT} from "@angular/common";

@Component({
    selector: 'xion-fade-out-notification-stack',
    templateUrl: './fade-out-notification-stack.component.html',
    styleUrls: [ './fade-out-notification-stack.component.css' ],
})
export class FadeOutNotificationStackComponent implements OnInit, OnDestroy {

    /** flag for position: fixed; */
   fixedPosition: boolean;
    /** window scroll event listener. Defaults to an empty function */
    private globalListenFunc: any = ()=>{};
    /**
     * notifications must be unshiftted, not pushed.
     * EX: this.fadeOutNotificationService.notifications.unshift(new FadeOutNotification(this.product.Name+' Saved Successfully'));
     */
    @Input() notifications: FadeOutNotification[] = [];
    /** Position to scroll to before this component becomes position: fixed; */
    @Input() scrollTo = 18;
    /** The top position */
    @Input() top = 0;

    constructor(private fadeOutNotificationService: FadeOutNotificationService,
                @Inject(DOCUMENT) private document: any,
                private winRef: WindowRef,
                private renderer: Renderer2) {}

    /**
     * Create a window scroll event listener
     */
    ngOnInit() {
        // console.log('FadeOutNotificationComponent:ngOnInit()');
        this.checkScroll();
    }

    /**
     * Handles all scroll events
     */
    @HostListener('window:scroll', ['$event'])
    onScroll(){
        // console.log('scroll',evt.target.scrollingElement.scrollTop);
        let scrollTop = this.document.body.scrollTop || this.document.documentElement.scrollTop;
        if (scrollTop < this.scrollTo) {
            this.fixedPosition = false;
        } else {
            this.fixedPosition = true;
        }
    }

    /**
     * Check initial page scrollTop when notification is shown
     */
    private checkScroll(){
        // console.log('window',window.pageYOffset);
        if (this.winRef.nativeWindow.pageYOffset < this.scrollTo) {
            this.fixedPosition = false;
        } else {
            this.fixedPosition = true;
        }
    }

    /**
     * Called when the fadeout has completed.
     */
    public popNotificationOff(event){
        // console.log('popping');
        this.fadeOutNotificationService.notifications.pop();
    }

    /**
     * Debouce the scroll event listener
     * Source: https://davidwalsh.name/javascript-debounce-function
     */
    // private debounce(func, wait:number, immediate?:boolean) {
    //     let timeout;
    //     return function() {
    //         let context = this, args = arguments;
    //         let later = function() {
    //             timeout = null;
    //             if (!immediate) func.apply(context, args);
    //         };
    //         let callNow = immediate && !timeout;
    //         clearTimeout(timeout);
    //         timeout = setTimeout(later, wait);
    //         if (callNow) func.apply(context, args);
    //     };
    // }

    /**
     * Remove the window scroll event listener
     */
    ngOnDestroy() {
        this.globalListenFunc();
    }
}
