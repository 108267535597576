
import {iif as observableIif, throwError as observableThrowError, defer as observableDefer, Observable, Subject} from 'rxjs';

import {mergeMap, catchError, retryWhen} from 'rxjs/operators';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import * as _ from 'underscore';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {WindowRef} from "./WindowRef";

@Injectable()
export class HttpLocalClient {

    public goAgain = new Subject<any>();
    public showModal = new Subject<any>();

    constructor(private http: HttpClient,
                private winRef: WindowRef) {
    }

    get(url, dontHandleError?: boolean, additionalHeaders = {}) {
        // console.log('request session', this.session);
        // console.log('request url', url);

        return observableDefer(()=> {
                // Handle different response Types (for csv from reports)
                if(!_.isEmpty(additionalHeaders)){
                    let headers = new HttpHeaders();
                    headers = this.addMoreToHeader(headers, additionalHeaders);
                    return this.http.get(url, { headers, responseType: 'text' });
                }else{
                    return this.http.get(url);
                }
            }).pipe(
            retryWhen(e => e.pipe(
                mergeMap(err => {
                    if(err.status === 401 && !dontHandleError){
                        this.showModal.next(['yep, show it']);
                        this.goAgain = new Subject<any>();
                    }
                    return observableIif(
                        // Returns the first stream if true, second if false
                        () => err.status === 401 && !dontHandleError,
                        this.goAgain, // A stream that will emit once authenticated
                        observableThrowError(err) // Rethrow the error
                    );
                }))
            ),
            catchError(error=>{
                return dontHandleError?
                    observableThrowError(error)
                    :this.handleError(error);
            }));
    }

    post(url, data, dontHandleError: boolean = false, additionalHeaders = {}, observeWholeResponse: boolean = false) {
        return observableDefer(()=> {
                if(!_.isEmpty(additionalHeaders)){
                    let headers = new HttpHeaders();
                    headers = this.addMoreToHeader(headers, additionalHeaders);
                    return this.http.post(url, data, { headers, responseType: 'text' });
                }else if(observeWholeResponse){
                    return this.http.post(url, data, {observe: 'response'});
                }else{
                    return this.http.post(url, data);
                }
            }).pipe(
            retryWhen(e => e.pipe(
                mergeMap(err => {
                        if(err.status === 401 && !dontHandleError){
                            this.showModal.next(['yep, show it']);
                            this.goAgain = new Subject<any>();
                        }
                        return observableIif(
                            // Returns the first stream if true, second if false
                            () => err.status === 401 && !dontHandleError,
                            this.goAgain, // A stream that will emit once authenticated
                            observableThrowError(err) // Rethrow the error
                        );
                    }))
                ),
            catchError(error=>{
                return dontHandleError?
                    observableThrowError(error)
                    :this.handleError(error);
            }));
    }

    put(url, data, additionalHeaders = {}) {
        return observableDefer(()=> {
                if(!_.isEmpty(additionalHeaders)){
                    let headers = new HttpHeaders();
                    headers = this.addMoreToHeader(headers, additionalHeaders);
                    return this.http.put(url, data, { headers, responseType: 'text' });
                }else{
                    return this.http.put(url, data);
                }
            }).pipe(
            retryWhen(e => e.pipe(
                mergeMap(err => {
                    if(err.status === 401){
                        this.showModal.next(['yep, show it']);
                        this.goAgain = new Subject<any>();
                    }
                    return observableIif(
                        // Returns the first stream if true, second if false
                        () => err.status === 401,
                        this.goAgain, // A stream that will emit once authenticated
                        observableThrowError(err) // Rethrow the error
                    );
                }))
            ),
            catchError(error=>{
                return this.handleError(error);
            }));
    }

    delete(url) {
        return observableDefer(()=> {
                // let headers = new Headers();
                // this.createAuthorizationHeader(headers);
                // console.log('trying again with headers:', headers);
                return this.http.delete(url);
            }).pipe(
            retryWhen(e => e.pipe(
                mergeMap(err => {
                    if(err.status === 401){
                        this.showModal.next(['yep, show it']);
                        this.goAgain = new Subject<any>();
                    }
                    return observableIif(
                        // Returns the first stream if true, second if false
                        () => err.status === 401,
                        this.goAgain, // A stream that will emit once authenticated
                        observableThrowError(err) // Rethrow the error
                    );
                }))
            ),
            catchError(error=>{
                return this.handleError(error);
            }));
    }

    addMoreToHeader(headers: HttpHeaders, headerKeyValue: any): HttpHeaders{
        for (let key in headerKeyValue) {
            if (headerKeyValue.hasOwnProperty(key)) {
                headers = headers.set(key, headerKeyValue[key]);
            }
        }
        return headers;
    }

    /**
     * Handles errors for each REST HTTP request
     */
    handleError(error: any) {
        // In a real world app, we might use a remote logging infrastructure
        // We'd also dig deeper into the error to get a better message
        console.log('HTTP error: ', error);

        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error(errMsg); // log to console instead
        if(error.status === 403){
            this.winRef.nativeWindow.alert('Whoa, easy there partner. Looks like you don\'t have permission to take that action.');
        }else if(error.status === 401){
            console.log('401 handleError');
            // window.alert("Uh oh, looks like your session has expired. Please log out and log in again.");
        }
        return observableThrowError(errMsg);
    }
}
