<xion-rotating-messages [messages]="rotatingMessages" (messageClicked)="showModal($event)"></xion-rotating-messages>

<xion-modal #mainModal
            [boxShadow]="true"
            [modalWidth]="452"
            [scrollToOnOpen]="false"
            [darkOverlay]="false"
            [modalTop]="145">

    <div class="modal-body">
        <div [innerHTML]="rotatingMessages[selectedIndex].modalBody"></div>
        <p>
            The terms and conditions of each retailer's shipping policy may vary.
            Each retailer's shipping policy can be found on their retailer page under the Policies section.
        </p>
        <p>Have questions? Contact us at support@xion.store.</p>
    </div>

</xion-modal>
