import { Subject } from 'rxjs';
import { Component, OnInit, ElementRef, Inject, Input, ViewChild, HostListener, OnDestroy } from '@angular/core';
import {SessionService} from "../../session-service/session.service.interface";
import {SESSION_SERVICE_TOKEN} from "../../session-service/index";
import {GlobalEventsService} from "../../global-events.service";
import {AuthenticationService} from "../../../marketplace/sign-in/authentication.service";
import {flatMap} from "rxjs/operators";
import * as _ from "underscore";

@Component({
  selector: 'xion-main-header-your-account',
  templateUrl: './main-header-your-account.component.html',
  styleUrls: ['./main-header-your-account.component.css']
})
export class MainHeaderYourAccountComponent implements OnInit, OnDestroy {
    isDropdownShowing: boolean;
    selectedOption: string;
    @Input() showCarrot = true;

    /** Observable containing the stores the user has access to */
    stores;
    storesCount:number = 0;

    getStores = new Subject<boolean>();

    constructor(private _eref: ElementRef, @Inject(SESSION_SERVICE_TOKEN) public sessionService: SessionService,
                public globalEventsService: GlobalEventsService, private authService: AuthenticationService) {

        this.initStoresData();
    }
    ngOnInit() {
        // console.log('MainHeaderYourAccountComponent:ngOnInit()');
        this.isDropdownShowing = false;
    }

    ngOnDestroy(){
        this.getStores.next();
        this.getStores.complete();
    }

    initStoresData(){
        this.getStores.asObservable()
            .pipe(flatMap(()=>this.sessionService.getUserStores()))
            .subscribe((res:any[])=>{
                this.storesCount = res.length;
                this.stores = _.sortBy(res, (store)=> store.name.toLowerCase());
            });
        // Get stores on load
        this.getStores.next(true);
        // Get stores again when there's a change in the user session entities
        this.sessionService.getLoadedStoresObservable()
            .subscribe(res=>{
                this.getStores.next(true);
            });
    }

    // onClick of window anywhere but this component and myButton
    @HostListener('document:click', ['$event'])
    onClick(event) {
        if (!this._eref.nativeElement.contains(event.target)) {
            this.isDropdownShowing = false;
        }
    }

    toggleDropdown() {
        this.isDropdownShowing = !this.isDropdownShowing;
    }

    signOut(){
        this.authService.signOut();
    }

    onDropdownClick() {
        this.toggleDropdown();
    }
}
