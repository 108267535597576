<!--<div class="overlay" *ngIf="showPopover" (click)="clickOverlayEventHandler()"></div>-->
<button class="cart-button no-style-btn" (click)="clickCartButtonEventHandler()" #cartButton>
  <img src="assets/img/svg/cart_icon_blue.svg" />
  <span>{{cartService.itemCount || '0'}}</span>
</button>

<xion-popover
    [showPopover]="geService.showCartPopover"
    [myButton]="cartButton"
    [top]="55"
    [right]="28">

    <div class="popover-triangle"></div>
    <div class="popover-title">Cart</div>
    <div class="popover-content">

        <!--<scrollable-container>-->
        <div class="light-text" *ngIf="!cartService.itemCount">
            <p>Nothing here yet!</p>
        </div>

        <div class="popover-cart-items"
             [style.max-height.px]="maxContentHeight"
             *ngIf="cartService.userCart && cartService.userCart.entities">
            <!--TODO: get estimated shipping and tax for the cart items-->
            <xion-order-summary-box
                [showTopBox]="false"
                [subtotal]="cartService.subtotal"
                [userCart]="cartService.userCart"
                [itemsRemovable]="true"
                [isCartView]="true"
                [shipping]="0"
                [isCalculating]="false"
                [tax]="0"
                (buttonClicked)="geService.showCartPopover.value = false;"
                [showButton]="false">
            </xion-order-summary-box>
            <!--<div *ngFor="let entityOrder of cartService.userCart.entities | values:'key':true">-->
                <!--<xion-cart-item-summary-->
                    <!--*ngFor="let item of entityOrder.orderItems"-->
                    <!--[item]="item">-->
                <!--</xion-cart-item-summary>-->
                <!--&lt;!&ndash;TODO: move the xion-hover-replace-remove here instead of inside the xion-cart-item-summary&ndash;&gt;-->
            <!--</div>-->
        </div>
        <!--</scrollable-container>-->
    </div>
    <div class="popover-footer" [class.less-pad]="!cartService.itemCount">
        <a *ngIf="cartService.itemCount" class="checkout-btn non-mobile"
           (click)="geService.showCartPopover.value = false;"
           [routerLink]="[ { outlets: { modal: 'checkout/return-customer' } } ]">
            Checkout
        </a>
        <a *ngIf="cartService.itemCount" class="checkout-btn mobile"
           (click)="geService.showCartPopover.value = false;"
           [routerLink]="['/cart/checkout/return-customer']">
            Checkout
        </a>
    </div>

</xion-popover>

