<div *ngIf="!isStorePage" class="lil-carrot"></div>

<nav id="main" [class.store-page]="isStorePage" [class.store-home-page]="isStoreHomePage">
    <div class="nav-column" id="products-col">
        <div class="dep">
            <h2>Departments</h2>
            <ul>
                <li *ngFor="let department of categories?.departments">
                    <a (click)="itemSelected.emit(true); categorySelected.emit(true);"
                            [routerLink]="department.departmentid?(isStorePage?['/'+storeSeoName+'/search']:['/department/'+department.name]):(['/department-coming-soon/'+department.name])"
                            [queryParams]="department.departmentid?(isStorePage?{department: department.name}:{}):{}"                            
                            (mouseover)="displayCategories(department.name, department.categories)"
                            [class.selected]="department.name == departmentBeingDisplayed"
                            [tabindex]="isOpenFlag?'0':'-1'">
                        {{department.name}}
                        <i *ngIf="department.categories.length" class="fa fa-angle-right right-carrot" aria-hidden="true"></i>
                    </a>
                </li>
            </ul>
        </div>
        <div class="cat">
            <h2>Categories</h2>
            <ul>
                <li *ngFor="let category of displayedCategories">
                    <a (click)="itemSelected.emit(true); categorySelected.emit(true);"
                       [routerLink]="isStorePage?['/'+storeSeoName+'/search']:['/department/'+departmentBeingDisplayed+'/category/'+category.name]"
                       [queryParams]="isStorePage?{category: category.name}:{}"
                       (mouseover)="displaySubCategories(category.name, category.subcategories)"
                       [class.selected]="category.name == categoryBeingDisplayed" 
                       [tabindex]="isOpenFlag?'0':'-1'">
                        {{category.name}}
                        <i *ngIf="category.subcategories.length" class="fa fa-angle-right right-carrot" aria-hidden="true"></i>
                    </a>
                </li>
            </ul>
        </div>
        <div class="subcat">
            <h2>Subcategories</h2>
            <ul>
                <li *ngFor="let subCategory of displayedSubCategories">
                    <a (click)="itemSelected.emit(true); categorySelected.emit(true);"
                        [routerLink]="isStorePage?['/'+storeSeoName+'/search']:['/department/'+departmentBeingDisplayed+'/category/'+categoryBeingDisplayed+'/subcategory/'+subCategory.name]"
                        [queryParams]="isStorePage?{subcategory: subCategory.name}:{}"
                        [tabindex]="isOpenFlag?'0':'-1'">{{subCategory.name}}</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="nav-column" id="brands-col">
        <h2>Brands</h2>
        <ul>
            <li *ngFor="let brand of brands">
                <a (click)="itemSelected.emit(true); brandSelected.emit(true);"
                    [routerLink]="isStorePage?['/'+storeSeoName+'/search']:['/brand/'+brand ]"
                    [queryParams]="isStorePage?{brand: brand}:{}"
                    [tabindex]="isOpenFlag?'0':'-1'">{{brand}}</a>
            </li>
        </ul>
    </div>
    <div class="nav-column" id="stores-col" *ngIf="!isStorePage">
        <h2>Stores</h2>
        <ul>
            <li>
                <a (click)="itemSelected.emit(true)" [routerLink]="['/stores']" [tabindex]="isOpenFlag?'0':'-1'">Closest to me</a>
            </li> 
            <li *ngFor="let store of stores">
                <a (click)="itemSelected.emit(true)"
                    [routerLink]="['/'+store.seoname]"
                    [tabindex]="isOpenFlag?'0':'-1'">{{store.name}}</a>
            </li>
        </ul>
    </div>
    <div class="nav-column" id="xion-info-col">
        <h2>Deals (10%+ off)</h2>
        <ul>
            <li>
                <a (click)="itemSelected.emit(true)" [routerLink]="isStorePage?['/'+storeSeoName+'/deals', {query:''}]:['/deals', {query:''}]" [tabindex]="isOpenFlag?'0':'-1'">All Deals</a>
            </li>
            <li *ngFor="let cat of availableDealCategories">
                <a (click)="itemSelected.emit(true)"
                    [routerLink]="isStorePage?['/'+storeSeoName+'/deals']:['/deals']"
                    [queryParams]="{ category: cat }"
                    [tabindex]="isOpenFlag?'0':'-1'">{{cat}}</a>
            </li>
        </ul>
    </div>
</nav>
