
import {takeUntil} from 'rxjs/operators';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Subject} from "rxjs";
import {SESSION_SERVICE_TOKEN, SessionService} from "../../shared/session-service";
import {ThirdPartySessionTrackingService} from "../shared/third-party-session-tracking/third-party-session-tracking.service";
import {GlobalEventsService} from "../../shared/global-events.service";
import { TalkService } from '../../shared/talk/talk.service';

@Component({
    selector: 'xion-checkout-new',
    template: `
        <div class="background" (click)="closeModal()"></div>
        
        <div class="modal" [class.confirmation-view]="isConfirmationView">
            <router-outlet></router-outlet>
        </div>
    `,
    styles: [`
        .background {
            background-color: rgba(0, 0, 0, 0.68);
            /*background: rgba(58, 58, 58, 0.25);*/
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 4;
        }
        .modal{
            z-index: 5;
            position: absolute;
            top: 50px;
            width: 90%;
            max-width: 960px;
            margin: 0 auto;
            left: 0;
            right: 0;
            background: white;
            border-radius: 8px;
            padding: 0;
        }
        .modal.confirmation-view{
            max-width: 760px;
        }
    `]
})
export class CheckoutNewComponent implements OnInit, OnDestroy {

    private ngUnsubscribe: Subject<void> = new Subject<void>();
    isConfirmationView = false;

    constructor(private router: Router,
                private geService: GlobalEventsService,
                private talkService: TalkService,
                private route: ActivatedRoute) { }

    ngOnInit() {

        // scroll to top
        this.geService.goTo('main-app-container');

        // Hacky fix
        // Remove login modal if it's still up (it's usually still up because it only disappears when navigateByUrl is called, and this modal view uses navigate)
        if(this.router.url.includes('loginModal')){
            this.router.navigate(
                [
                    {
                        outlets: {loginModal: null}
                    }
                ],
                {
                    relativeTo: this.route.parent,
                    replaceUrl: true
                }
            )
        }

        // TalkJS
        this.loadChatWidget();

        // Make confirmation page a bit skinnier
        this.router.events.pipe(
            takeUntil(this.ngUnsubscribe.asObservable()))
            .subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            if(evt.url.includes('checkout/confirmation')){
                this.isConfirmationView = true;
            }
        })
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.talkService.destroyChatPopup();
    }

    private loadChatWidget(){
        this.talkService.mountXIONChatPopup('Let me know if you have any questions :)');
    }

    // Source: https://www.bennadel.com/blog/3351-closing-secondary-router-outlet-views-from-within-the-named-route-view-components-in-angular-4-4-4.htm
    closeModal(){
        this.router.navigate(
            [
                {
                    outlets: {modal: null}
                }
            ],
            {
                relativeTo: this.route.parent
            }
        )
    }
}
