import { ThirdPartySessionTrackingService } from './../../marketplace/shared/third-party-session-tracking/third-party-session-tracking.service';
import { GlobalEventsService } from './../global-events.service';
import { Observable, from as observableFrom } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { XRDService } from './../xrd/xrd.service';
import { ConstantsService } from './../xion-constants.service';
import { Injectable } from '@angular/core';
import * as Talk from "talkjs";
import { Deferred } from './deffered.util';
import { XRDEntity } from '../xrd';
import { TalkPopupOptions } from './talk-popup-options.model';
import { TalkjsBubblePromptService } from '../talkjs-bubble-prompt/talkjs-bubble-prompt.service';

@Injectable({
  providedIn: 'root'
})
export class TalkService {
    private currentTalkUser: Talk.User;
    private loadedPopups: Talk.Popup[];
    private loggedInSessionDeferred;
    private visitorSessionDeferred = new Deferred<Talk.Session>();
    private entitySessionDeferred = new Deferred<Talk.Session>();
    private fullstoryThirdParty = {
        'fullstory': {
            'org': 'A4MF8'
        }
    };

    // Main one-on-one popup
    chatPopup: Talk.Popup;
    // Keeps track of current conversation 
    // (used to determine whether or not the popup should reload)
    currentChatPopupEntityId:string;

    constructor(private constantsService: ConstantsService, 
                private geService: GlobalEventsService,
                private talkjsBubblePromptService: TalkjsBubblePromptService,
                private tpstService: ThirdPartySessionTrackingService,
                private xrdService: XRDService) { 
        this.loadedPopups = [];
    }


    /**
     ****** popup stuff ******
     */

    /** works with entities that have a parent */
    mountChatPopupForEntityId(entityId, options:TalkPopupOptions, openPromptAfterMS = 0): Observable<any>{
        if(!this.geService.isRunOnBrowser()) return observableFrom(['']);
        return observableFrom(['']);
        // return this.xrdService.entity.getEntityById(entityId)
        //     .pipe(
        //         flatMap((entity:XRDEntity)=>{
        //             if(entity.parententityid !== '1'){
        //                 return this.xrdService.entity.getEntityById(entity.parententityid);
        //             }else{
        //                 return observableFrom([entity]);
        //             }
        //         }),
        //         map(entity=>{
        //             if(this.currentChatPopupEntityId === entity.entityid && options.showImmediately){
        //                 this.openCurrentChatPopup();
        //                 return;
        //             }
        //             this.mountChatPopupForEntity(entity, options, openPromptAfterMS);
        //         })
        //     );
    }

    mountChatPopupForEntity(entity:XRDEntity, options:TalkPopupOptions, openPromptAfterMS = 0){
        // if(!this.geService.isRunOnBrowser()) return;
        // if(this.currentChatPopupEntityId === entity.entityid) return;   
        
        // if(openPromptAfterMS > 0){
        //     this.talkjsBubblePromptService.message = options.welcomeMessage;
        //     this.talkjsBubblePromptService.pic = entity.logo;
        // }
        
        // Super temporary hack
        // let phone = undefined;
        // if(entity.entityid === '125') phone = '+17042022646'; // Skinny Wheels
        // if(entity.entityid === '211') phone = '+14355131984'; // Marty's
        // if(entity.entityid === '75') phone = '+18017986624'; // Nobel
        // if(entity.entityid === '187') phone = '+12622086571'; // Zuzu's
        // if(entity.entityid === '181') phone = '+12484708229'; // CtoF's

        // // @ts-ignore
        // this.mountChatPopup({
        //     id: entity.entityid,
        //     name: entity.name,
        //     email: entity.emails[0].emailaddress,
        //     phone: phone,
        //     custom: {entityseoname: entity.seoname},
        //     welcomeMessage: options.welcomeMessage,
        //     photoUrl: entity.logo,
        // } as Talk.User, options, openPromptAfterMS);
    }

    // Used for seller chat
    private async mountChatPopup(talkUser: Talk.User, options:TalkPopupOptions, openPromptAfterMS = 0) {
        // this.destroyChatPopup();
        // this.currentChatPopupEntityId = talkUser.id;
        // this.chatPopup = await this.createPopup(talkUser, options);
        // if(openPromptAfterMS > 0){
        //     this.chatPopup.on('open', ()=>{
        //         this.talkjsBubblePromptService.isOpen = false;
        //     });
        //     this.chatPopup.mount({ 'show': options.showImmediately}).then(()=>{
        //         this.talkjsBubblePromptService.openAfter(openPromptAfterMS);
        //     });
        // }else{
        //     this.chatPopup.mount({ 'show': options.showImmediately});
        // }
    }

    // Used for XION chat
    async mountXIONChatPopup(message:string) {
        // if(!this.geService.isRunOnBrowser()) return;
        // this.destroyChatPopup();
        // this.currentChatPopupEntityId = '1';
        // // @ts-ignore
        // this.chatPopup = await this.createPopup({
        //     id: '1',
        //     name: 'XION',
        //     email: 'support@xion.store',
        //     phone: ['+12066834583', '+18018396960'], // Dan, Kevin
        //     custom: {entityseoname: 'xion'},
        //     welcomeMessage: message,
        //     photoUrl: '/assets/img/new_logos/venture_beyond_logo.png',
        // } as Talk.User, {} as TalkPopupOptions);
        // this.chatPopup.mount({ 'show': false});
    }

    openChatPopupForEntityId(entityId, options:TalkPopupOptions): Observable<any>{
        return observableFrom([]);
        // if(this.currentChatPopupEntityId === entityId && options.showImmediately){
        //     this.openCurrentChatPopup();
        //     return observableFrom([]);
        // }else{
        //     return this.mountChatPopupForEntityId(entityId, options);
        // }
    }

    // Mostly private, also used for bubble-prompt click
    openCurrentChatPopup(){
        // if(this.chatPopup){
        //     this.chatPopup.show();
        //     this.talkjsBubblePromptService.isOpen = false;
        // }
    }

    // Assumes one popup at a time
    destroyChatPopup(){
        // if(this.chatPopup){
        //     this.chatPopup.destroy();
        //     this.chatPopup = undefined;
        //     this.currentChatPopupEntityId = undefined;
        //     this.talkjsBubblePromptService.reset();
        // }
    }

    /**
     ****** session stuff n stuff ******
     */

    async createLoggedInSession(contactId, username, firstname, lastname) {
        // this.loggedInSessionDeferred = new Deferred<Talk.Session>();

        // await Talk.ready;

        // const currentUser = {
        //     id: contactId,
        //     email: username,
        //     firstname: firstname,
        //     lastname: lastname,
        // };
        // const currentTalkUser = await this.createMainTalkUser(currentUser);

        // this.finishCreatingSession(currentTalkUser)
        //     .subscribe( session=>{
        //         this.loggedInSessionDeferred.resolve(session);
        //     }, error=>{
        //         console.log('getTalkJsUserSignature error:', error);
        //     });
    }

    async createVisitorSession(){
        // await Talk.ready;

        // let user = this.getVisitorUser();
        // let currentTalkUser = new Talk.User(user);
        
        // this.finishCreatingSession(currentTalkUser)
        //     .subscribe( session=>{
        //         this.visitorSessionDeferred.resolve(session);
        //     }, error=>{
        //         console.log('getTalkJsUserSignature error:', error);
        //     });
    }

    // This is for the entity's admin screen
    async createEntitySession(entityId, email, entityName): Promise<Talk.Session> {
        await Talk.ready;

        let entityUser = new Talk.User({
            id: 'xion_seller_' + entityId,
            email: email,
            name: entityName,
            configuration: 'seller',
         });

        return this.xrdService.getTalkJsUserSignature(entityUser.id)
            .pipe(map(sig=>{
                const session = new Talk.Session({
                    appId: this.constantsService.getTalkJsAppId(),
                    me: entityUser,
                    signature: sig,
                });
                this.entitySessionDeferred.resolve(session);
                return session;
            })).toPromise();
    }

    private finishCreatingSession(user: Talk.User): Observable<Talk.Session>{
        return this.xrdService.getTalkJsUserSignature(user.id)
            .pipe(map(sig=>{
                const session = new Talk.Session({
                    appId: this.constantsService.getTalkJsAppId(),
                    me: user,
                    signature: sig,
                });
                this.currentTalkUser = user;
                return session;
            }));
    }

    async createMainTalkUser(user:any): Promise<Talk.User> {
        await Talk.ready;

        return new Talk.User({
            id: 'xion_consumer_' + user.id,
            email: user.email,
            name: `${user.firstname} ${user.lastname}`,
            configuration: 'consumer',
         });
    }

    private async createOtherTalkUser(user:Talk.User): Promise<Talk.User> {
        await Talk.ready;

        return new Talk.User({
            id: 'xion_seller_' + user.id,
            email: user.email,
            custom: user.custom, // contains the entityseoname
            phone: user.phone?user.phone:null,
            name: user.name,
            welcomeMessage: user.welcomeMessage,
            photoUrl: user.photoUrl,
            configuration: 'seller',
         });
    }
    
    async createPopup(otherUser: Talk.User, options:TalkPopupOptions): Promise<Talk.Popup> {

        const session = await this.getUserSession().promise;
        
        const conversationBuilder = await this.getOrCreateConversation(session, otherUser, options);

        const popup = session.createPopup(conversationBuilder, { 
            'keepOpen': false, 
            'messageSuggestion': options.messageSuggestion?options.messageSuggestion:null,
            'thirdparties': this.fullstoryThirdParty,
            'launcher': 'always' 
        });

        const isUserLoggedIn = (this.loggedInSessionDeferred !== undefined);        
        if(isUserLoggedIn === false) this.getHtmlPanel(popup);

        this.loadedPopups.push(popup);
        return popup;
    }

    private getUserSession(): Deferred<Talk.Session> {
        const isUserLoggedIn = (this.loggedInSessionDeferred !== undefined);
        if(isUserLoggedIn) return this.loggedInSessionDeferred;
        else return this.visitorSessionDeferred;
    }

    private async getOrCreateConversation(session: Talk.Session, otherUser: Talk.User, options:TalkPopupOptions) {
        const otherTalkUser = await this.createOtherTalkUser(otherUser);
        
        const isUserLoggedIn = (this.loggedInSessionDeferred !== undefined); 
        let conversationBuilder;
        if(isUserLoggedIn){
            conversationBuilder = this.getOrCreateLoggedInConversation(session, otherTalkUser, options);
        }else{
            conversationBuilder = this.getOrCreateVisitorConversation(session, otherTalkUser, options);
        }
        conversationBuilder.setParticipant(this.currentTalkUser);
        conversationBuilder.setParticipant(otherTalkUser);

        return conversationBuilder;
    }

    private getOrCreateLoggedInConversation(session: Talk.Session, otherTalkUser: Talk.User, options:TalkPopupOptions): Talk.ConversationBuilder{
        let conversationBuilder;
        let convId = Talk.oneOnOneId(this.currentTalkUser, otherTalkUser);
        if(options.subject && options.subjectAddOnId){
            convId += '_'+options.subjectAddOnId;
            conversationBuilder = session.getOrCreateConversation(convId);
            conversationBuilder.setAttributes({
                'subject': options.subject,
            });
        }else{
            conversationBuilder = session.getOrCreateConversation(convId);
        }
        return conversationBuilder;
    }

    private getOrCreateVisitorConversation(session: Talk.Session, otherTalkUser: Talk.User, options:TalkPopupOptions): Talk.ConversationBuilder{
        let conversationBuilder;
        let conEndId = otherTalkUser.id;
        let convId = 'conv-'+this.currentTalkUser.id+'-';
        if(options.subject && options.subjectAddOnId){
            conEndId += '_'+options.subjectAddOnId;
            convId += conEndId;
            conversationBuilder = session.getOrCreateConversation(convId);
            conversationBuilder.setAttributes({
                'subject': options.subject,
            });
        }else{
            convId += conEndId;
            conversationBuilder = session.getOrCreateConversation(convId);
        }
        this.setCookie('seller-id', conEndId);
        return conversationBuilder;
    }

    async createEntityInbox(): Promise<Talk.Inbox> {
        const session = await this.entitySessionDeferred.promise;
        this.destroyChatPopup();
        return session.createInbox({
            'thirdparties': this.fullstoryThirdParty,
        });
    }

    async createUserInbox(): Promise<Talk.Inbox> {
        const isUserLoggedIn = (this.loggedInSessionDeferred !== undefined);
        const session = isUserLoggedIn
            ?await this.loggedInSessionDeferred.promise
            :await this.visitorSessionDeferred.promise;
        this.destroyChatPopup();
        return session.createInbox({
            'thirdparties': this.fullstoryThirdParty,
        });
    }


    /**
     ****** Visitor stuff ******
     */

    private getCookie(ckey) {
        let b = document.cookie.match('(^|;)\\s*' + ckey + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : null;
    }
    
    private setCookie(ckey, cvalue) {
        document.cookie = "talkjs-" + ckey + "=" + cvalue + ";domain=.xion.store;path=/"; // the fix? We'll see
    }
    
    // UUID used to give each visitor their own unique ID
    private uuidv4() {
        let crypto = window.crypto;
        let randomByte = crypto
            ? ()=>{ return (crypto.getRandomValues(new Uint8Array(1)))[0]; }
            : ()=>{ return Math.floor(Math.random() * 255); };
        return ([1e7] as any + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c)=>{
            return (c ^ randomByte() & 15 >> c / 4).toString(16);
        });
    }
    
    // Gets the visitor user values from cookie, if it doesn't exist return default values
    private getVisitorUser() {
        let userId = (this.getCookie("talkjs-user-id") ? this.getCookie("talkjs-user-id") : this.uuidv4());
        let email = (this.getCookie("talkjs-user-email") && decodeURIComponent(this.getCookie("talkjs-user-email")));
        let name = (email !== null ? email.substr(0, email.indexOf('@')) : 'Visitor');
    
        this.setCookie('user-id', userId);
    
        let user = {
            id: userId,
            name: name,
            configuration: 'visitor',
        };
        return user;
    }
    
    private getHtmlPanel(chatObject) {
        // Check if the user email is set in cookie. If it isn't then show the HTML panel
        if (!this.getCookie('talkjs-user-email')) {
            return chatObject.createHtmlPanel({
                url: `/assets/forms/form-signup.html`,
                height: 130
            }).then((htmlPanel)=>{
                // Eventlistener to register when the email has been set and proceed to hide the HTML panel
                htmlPanel.DOMContentLoadedPromise.then(()=>{
                    let registerForm = htmlPanel.window.document.getElementById('register-form');
                    registerForm.addEventListener('submit', (event)=>{
                        if (htmlPanel) {
                            let email = this.getCookie('talkjs-user-email');
                            console.log('talkjs submitted email:', email);
                            if(email) this.tpstService.setDripSessionEmailOnly(email);
                            htmlPanel.hide();
                            htmlPanel.window.saveForm();
                            event.preventDefault();
                        }
                    });
                });
            }).catch((error)=>{
                console.log('HERE ERROR');
                console.error(error);
            });
        };
    }
    
}
