import { Injectable, ErrorHandler, Injector } from '@angular/core';

import {GlobalEventsService} from "./shared/global-events.service";
import {WindowRef} from "./WindowRef";

@Injectable()
export class LoadingChunkErrorHandler extends ErrorHandler {

    constructor(private injector: Injector, private winRef: WindowRef) {
        super();
    }

    private get geService(): GlobalEventsService {
        return this.injector.get(GlobalEventsService, null);
    }

    /**
     * The soul purpose for this error interceptor is to catch the "Error: Loading Chunk ____ Failed" problem.
     * The below code is triggered when that happens. Usually the app is running on an older version that is
     * requesting outdated (cache-busted) js files (lazy-loaded routes). So, once we reload the app,
     * the Service Worker should recognise the change and update it to the current version. Then we
     * Shouldn't see this error again.
     * UPDATE: it seems to be relatively reloading, so baseUrl isn't needed.
     */
    public handleError(error: Error) {
        if (/Error: Loading chunk/.test(error.message)) {
            let geService = this.geService;

            // Get base url
            // let baseUrl = this.winRef.nativeWindow.location.href;
            // let hook = 'xion.store';
            // if(baseUrl.indexOf(hook) >= 0){
            //     baseUrl = baseUrl.substr(0, baseUrl.indexOf(hook)+hook.length);
            // }else{
            //     baseUrl = 'localhost:4200';
            // }
            // console.log('baseUrl:', baseUrl);

            // Go to route (should update the app now)
            console.log('Manual reload to: ', geService.routeToGoTo);
            this.winRef.nativeWindow.location.href = geService.routeToGoTo;
        }
        throw error;
    }
}
