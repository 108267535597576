import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { WindowRef } from './WindowRef';
import { GlobalEventsService } from './shared/global-events.service';

@Injectable({
    providedIn: 'root'
})
export class SellPageGuardGuard implements CanActivate {

    constructor(private window: WindowRef, private geService: GlobalEventsService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // can't redirect on server
        if(this.geService.isRunOnBrowser()){
            this.window.nativeWindow.location.href = "https://retail.xion.store";
            return false;
        }else{
            return true;
        }
    }

}
