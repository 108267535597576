<div *ngIf="!isStorePage" class="lil-carrot"></div> 

<nav id="main" [class.is-open]="isOpenFlag" [class.store-page]="isStorePage" [class.is-main-mobile]="isMainMobile" [class.store-home-page]="isStoreHomePage">
    <div class="mobile store-header-container">
        <h1>{{storeName}}</h1>
        <button mat-button class="cancel-btn" (click)="itemSelected.emit(true)">&#10005;</button>
    </div>

    <h2 class="clickable" (click)="toggleDepartmentsDropDown()">
        Departments
        <i class="fa fa-angle-right right-carrot"
            [class.down-position]="departmentsDroppedDown"
            aria-hidden="true"></i>
    </h2>
    <ul *ngIf="departmentsDroppedDown">
        <li *ngFor="let dep of categories?.departments">
            <a (click)="dep.categories.length?toggleDepartmentDropDown(dep.name):searchForDep(dep.name)"
               [tabindex]="isOpenFlag?'0':'-1'">
                {{dep.name}}
                <i *ngIf="dep.categories.length"
                   class="fa fa-angle-right right-carrot"
                   [class.down-position]="departmentDroppedDownMap[dep.name]"
                   aria-hidden="true"></i>
            </a>
            <ul *ngIf="departmentDroppedDownMap[dep.name]">
                <li *ngFor="let cat of dep.categories">
                    <a (click)="itemSelected.emit(true); categorySelected.emit(true);"
                       [routerLink]="isStorePage?['/'+storeSeoName+'/search']:['/department/'+dep.name+'/category/'+cat.name]"
                       [queryParams]="isStorePage?{category: cat.name}:{}"
                       [tabindex]="isOpenFlag?'0':'-1'">{{cat.name}}</a>
                </li>
            </ul>
        </li>
    </ul>

    <h2 class="clickable" (click)="toggleBrandsDropDown()">
        Brands
        <i *ngIf="brands?.length"
           class="fa fa-angle-right right-carrot"
           [class.down-position]="brandsDroppedDown"
           aria-hidden="true"></i>
    </h2>
    <ul *ngIf="brandsDroppedDown">
        <li *ngFor="let brand of brands">
            <a (click)="itemSelected.emit(true); brandSelected.emit(true);"
               [routerLink]="isStorePage?['/'+storeSeoName+'/search']:['/brand/'+brand ]"
               [queryParams]="isStorePage?{brand:brand}:{}"
               [tabindex]="isOpenFlag?'0':'-1'">{{brand}}</a>
        </li>
    </ul>
    <div class="extra-items">
        <a (click)="itemSelected.emit(true)" 
           [routerLink]="isStorePage?['/'+storeSeoName+'/deals']:['/deals', {query:''}]" 
           [queryParams]="isStorePage?{query:''}:{}"
           [tabindex]="isOpenFlag?'0':'-1'">
            <h2 class="clickable">Deals (10%+ off)</h2>
        </a>
    </div>
    <div *ngIf="!isStorePage">
        <div class="extra-items" *ngFor="let item of extraItems">
            <a *ngIf="item.title === 'Blog'" (click)="itemSelected.emit(true)" [href]="item.routePath" [tabindex]="isOpenFlag?'0':'-1'">
                <h2 class="clickable">{{item.title}}</h2>
            </a>
            <a *ngIf="item.title !== 'Blog'" (click)="itemSelected.emit(true)" [routerLink]="[item.routePath]" [tabindex]="isOpenFlag?'0':'-1'">
                <h2 class="clickable">{{item.title}}</h2>
            </a>
        </div>
    </div>
</nav>
