import {Component, OnInit, Inject, Input} from '@angular/core';
import {SessionService} from "../../session-service/session.service.interface";
import {SESSION_SERVICE_TOKEN} from "../../session-service/index";

@Component({
    selector: 'xion-main-header-greeting',
    templateUrl: './main-header-greeting.component.html',
    styleUrls: [ './main-header-greeting.component.css' ],
})
export class MainHeaderGreetingComponent implements OnInit {
    sessionService: SessionService;

    constructor(@Inject(SESSION_SERVICE_TOKEN) sessionService: SessionService) {
        this.sessionService = sessionService;
    }

    ngOnInit() {
        // console.log('MainHeaderGreetingComponent:ngOnInit()');
    }
}
