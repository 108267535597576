import {Component, OnInit, Input, Inject, ViewChild, ElementRef, AfterViewInit} from '@angular/core';

import {SessionService} from "../session-service/session.service.interface";
import {SESSION_SERVICE_TOKEN} from "../session-service/index";
import {Router, ActivatedRoute, Params} from "@angular/router";
import { XRDProduct } from '../xrd/product/xrd-product.model';

@Component({
    selector: 'xion-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.css'],
})
export class ProductCardComponent implements OnInit, AfterViewInit {
    /** The product to display. */
    @Input() product: XRDProduct;
    /** How much padding to style the card with. */
    @Input() padding: number = 5;
    /** Flags whether to top-align the card content. */
    @Input() topAlign: boolean = false;

    /** Lazy Loading Flags */
    @Input() isLazyLoaded: boolean = false;
    @Input() isScrollObserved: boolean = false;
    @Input() scrollObservable: any;

    /** The title of the card. */
    @ViewChild('cardTitle') cardTitle: ElementRef;

    /** Holds the user session service. */
    private sessionService: SessionService;

    msrp: number;
    price: number;

    constructor(@Inject(SESSION_SERVICE_TOKEN) sessionService: SessionService, private router:Router, private route: ActivatedRoute){
        this.sessionService = sessionService;
    }

    ngOnInit() {
        // console.log('ProductCardComponent:ngOnInit()');

        // Convert to number for comparing
        if(this.product.price) this.price = +this.product.price;
        if(this.product.msrp) this.msrp = +this.product.msrp;
    }

    ngAfterViewInit() {
        // Fix to ensure view is loaded first.
        // setTimeout(()=>{
        //     this.useThreeDots();
        // }, 0);
    }

    /**
     * Checks the card title length, and truncates it if it is too long.
     */
    private useThreeDots(){
        // Initialize everything
        let current = this.cardTitle.nativeElement;
        let words = current.innerText.split(' ');
        current.innerText = words[0];
        let height:number = current.offsetHeight;
        let counter:number = 0;
        let numOfLines:number = 2;

        // slowly add words back into element until two changes in height (two line breaks)
        for(let i = 1; i < words.length && counter<numOfLines; i++) {
            current.innerText = current.innerText + ' ' + words[i];

            if (current.offsetHeight > height) {
                counter++;
                height = current.offsetHeight;
                console.log(words[i]);
                if (counter == 2) {
                    current.innerText = current.innerText.slice( 0, (current.innerText.length - words[i].length - 3)); //3 dots
                    current.innerText = current.innerText + '...';
                }
            }
        }
    }
}
