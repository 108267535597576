export class XRDEmail {
  "emailid": string;
  "emailaddress": string;
  "emailtypeid": string;
  "emailtype": string;

  constructor(){
    this.emailid = '';
    this.emailaddress = '';
    this.emailtypeid = '';
    this.emailtype = '';
  }

}
