<div class="mynavbar-search-group">
    <div class="mynavbar-search-group-item">
        <!--<xion-main-header-search-filter *ngIf="!showStoreSearch"></xion-main-header-search-filter>-->
        <div class="container non-mobile">
            <button id="main-toggle" type="button" (click)="toggleFilterDropdown()" #myButton >
                {{filterText}} <div class="search-filter-down-arrow"></div>
            </button>
            <div id="dropdown-filter"
                 [style.width]="filterDropdownWidth"
                 [style.display]="isFilterDropdownShowing === false ? 'none' : 'block'">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
    <div class="divider non-mobile"></div>
    <div class="mynavbar-search-group-item">
        <button class="mynavbar-search-group-item-search-button" (click)="onSearchButtonClick($event)">
            &nbsp;
        </button>
    </div>

    <div class="mynavbar-search-group-item mynavbar-search-group-item-search">
        <form (ngSubmit)="onSearchInputKeyUpForEnter()">
            <mat-form-field id="main-search">
                <input matInput #mainSearchInput name="search_term_string" [placeholder]="placeholderText"
                    autocomplete="off" type="search" [(ngModel)]="inputText"
                    (keyup.enter)="onSearchInputKeyUpForEnter()">
            </mat-form-field>
        </form>
    </div>
</div>
