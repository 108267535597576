import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from "../../../../../shared/shared.module";
import {StoreHoursDisplayListComponent} from "./store-hours-display-list.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    declarations: [
        StoreHoursDisplayListComponent
    ],
    exports: [
        StoreHoursDisplayListComponent
    ]
})
export class StoreHoursDisplayListModule { }
