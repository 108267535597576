import {Injectable} from "@angular/core";
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import {Observable} from "rxjs";
import {XRDService} from "../../shared/xrd/xrd.service";
import {GlobalEventsService} from "../../shared/global-events.service";
import {map, flatMap} from "rxjs/operators";
import {forkJoin} from "rxjs";
import * as _ from 'underscore';

@Injectable()
export class ProductBySEONameResolver implements Resolve<any> {

    constructor(private xrdService: XRDService, 
        private router: Router,
        private globalEventsService: GlobalEventsService) {}

    resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>|Promise<any>|any {
        this.globalEventsService.showProgressBar = true;

        let oDataRequests = [];

        // Deprecated
        if(route.params['store']){
            oDataRequests.push(this.xrdService.search.getSearchEntitySeonameProductSeoname(route.params['store'], route.params['seoName']))
            oDataRequests.push(this.xrdService.entity.getEntityBySeoName(route.params['store']));
        }else if(route.queryParams['store']){
            oDataRequests.push(this.xrdService.search.getSearchEntitySeonameProductSeoname(route.queryParams['store'], route.params['seoName']))
            oDataRequests.push(this.xrdService.entity.getEntityBySeoName(route.queryParams['store']));
        }else{
            oDataRequests.push(this.xrdService.product.getProductBySEOName(route.params['seoName']));
        }
        return forkJoin(...oDataRequests).pipe(map((res)=>{
            this.globalEventsService.showProgressBar = false;
            return {
                product: res[0],
                entity: res[1] // If store param is missing or doesn't have an entity match in our system, this will be undefined
            };
        }));
    }
}
