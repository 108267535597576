import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {XRDService} from "../../../shared/xrd/xrd.service";
import {GeoLocationService} from "../../../shared/geo-location.service";
import {HomeService} from "../home.service";
import {LocalShopDummyProduct} from "./localShop-dummy.class";
import {GlobalEventsService} from "../../../shared/global-events.service";
import {map} from "rxjs/operators";


@Component({
    selector: 'xion-local-shops',
    templateUrl: './local-shops.component.html',
    styleUrls: [ './local-shops.component.css' ],
})
export class LocalShopsComponent implements OnInit {

    @ViewChild('helloWorld') helloWorld;

    localShops;
    numberOfStores: number = 20;
    isLoading: boolean = true;
    isError: boolean = false;
    errorMsg: string;

    constructor(private xrdService: XRDService,
                private geoLocationService: GeoLocationService,
                private gesService: GlobalEventsService,
                public homeService: HomeService) {
        this.localShops = [new LocalShopDummyProduct(), new LocalShopDummyProduct(),
            new LocalShopDummyProduct(), new LocalShopDummyProduct()];
    };

    ngOnInit() {
        // console.log('LocalShopsComponent:ngOnInit()');

        if(!this.gesService.isRunOnBrowser()) return;

        // 0 for lat and long will use Robert's way of getting GEO location by IP (not as accurate)
        if(this.geoLocationService.currentGeoLocation){
            console.log('Local Shops from geo location :)');
            this.getLocalEntities(this.geoLocationService.currentGeoLocation.lat, this.geoLocationService.currentGeoLocation.long);
        }else {
            console.log('Local Shops from client server :(');
            this.getLocalEntities(0, 0);
        }
    }

    getLocalEntities(lat, long) {
        this.xrdService.entity.getEntitiesByLocation(this.numberOfStores, lat, long)
            .pipe(map((entities:any) => {
                this.isLoading = false;
                if(entities.length == false){
                    this.isError = true;
                    this.errorMsg = 'No local shops nearby :(';
                    return false;
                }
                return entities;
            }))
            .subscribe(shops=>{
                this.localShops = shops;
            })
    }
}
