import {from as observableFrom, throwError as observableThrowError,  Observable , Subject} from 'rxjs';
import {catchError, mergeMap, map, flatMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import * as _ from 'underscore';
import { SessionService } from './session.service.interface';
import { XRDService, XRDEntity, XRDUser, XRDPhone, XRDEmail, XRDAddress, XRDShallowEntity, XRDEntityAccessDescriptor } from '../xrd';
import {ClientSessionTokenService} from "./client-session-token.service";
import {GlobalEventsService} from "../global-events.service";
import { UserIdentityService } from '../user-identity.service';

@Injectable()
export class ClientSessionService implements SessionService {

    private session: any;
    private loadedStoresEvent = new Subject<boolean>();

    constructor (private xrdService: XRDService,
                 private clientSessionTokenService: ClientSessionTokenService,
                 private gesService: GlobalEventsService,
                 private uidService: UserIdentityService) {
        if(this.gesService.isRunOnBrowser()) {
            this.session = JSON.parse(localStorage.getItem('session'));
            // console.log('Session is: ' + JSON.stringify(this.session));

            if (!this.session) {
                console.log('Tyring to create a new session object...');
                this.session = {};
                try{
                    localStorage.setItem('session', JSON.stringify(this.session));
                }catch(error){
                    console.log('FAILED to create new session object');
                }
            }
        }
    }

    signIn(username: string, password: string): Observable<boolean> {
        let userSession;
        let userSuperAdmin;

        return this.xrdService.user.authenticate(username, password).pipe(
            mergeMap(
                (result: XRDUser) => {
                    console.log('Successfully authenticated, executing loadUserDeep()', result);
                    userSession = result.session;
                    userSuperAdmin = result.superadmin;
                    console.log(this.session?'':'d');

                    // Tracking for 3rd party apps
                    this.uidService.setUserSession(
                        result.firstname,
                        result.lastname,
                        result.username,
                        result.contactid,
                    );

                    return this.loadUserDeep(result.contactid, userSuperAdmin);
                }
            ),
            map(
                (result: XRDUser) => {
                    // Must add these fields in to the session because they are only obtained from the authenticate endpoint.
                    // console.log('userSession is: ' + userSession);
                    this.session.user.session = userSession;
                    this.session.user.superadmin = userSuperAdmin;

                    // Set the adminentity to the first deep entity
                    this.session.adminentity = this.session.user.deepentities[0];
                    console.log('user deep entities:', this.session.user.deepentities);

                    // console.log('session.user is: ' + JSON.stringify(this.session.user));
                    this.saveSession();

                    return true;
                }
            ));
    }

    signInWithSession(session: string, contactId: string): Observable<boolean> {

        this.clientSessionTokenService.saveSessionToken(session);

        return this.loadUserDeep(contactId).pipe(
            map(
                (result: XRDUser) => {

                    // Tracking for 3rd party apps
                    this.uidService.setUserSession(
                        result.firstname,
                        result.lastname,
                        result.username,
                        result.contactid
                    );

                    // Must add these fields in to the session because they are only obtained from the authenticate endpoint.
                    // console.log('userSession is: ' + userSession);
                    this.session.user.session = session;

                    // Set super admin flag
                    let userSuperAdmin = false;
                    result.groups.forEach(group=>{
                        if(group.groupid == '1'){
                            userSuperAdmin = true;
                        }
                    });

                    this.session.user.superadmin = userSuperAdmin;

                    // Set the adminentity to the first deep entity
                    this.session.adminentity = this.session.user.deepentities[0];
                    console.log('user deep entities:', this.session.user.deepentities);

                    // console.log('session.user is: ' + JSON.stringify(this.session.user));
                    this.saveSession();

                    return true;
                }
            ));
    }

    signOut() {
        if (this.session.user !== undefined) {
            delete this.session.user;
        }
        if (this.session.adminentity !== undefined) {
            delete this.session.adminentity;
        }
        if (this.session.adminlightspeed !== undefined) {
            delete this.session.adminlightspeed;
        }

        if(this.gesService.isRunOnBrowser()) {
            if(localStorage.getItem('session')){
                localStorage.removeItem('session');
            }
        }
        this.uidService.clearSession();
        this.saveSession();
    }

    loadUserDeep(contactId: string, isSuperAdmin=false): Observable<XRDUser> {
        return this.xrdService.user.getUserByIdDeep(contactId).pipe(
            map(
                (result: XRDUser) => {
                    // console.log('Result of loadUserDeep: ' + JSON.stringify(result));
                    this.session.user = result;

                    this.session.user.session = this.clientSessionTokenService.getSessionToken(); // this fixes checkout
                    this.session.user.superadmin = isSuperAdmin;

                    this.saveSession();

                    this.loadedStoresEvent.next(true);

                    return result;
                }
            ));
    }

    loadUserOwnedEntitiesDeep(contactId: string, shallowEntities?: XRDShallowEntity[]): Observable<XRDEntity[]> {
        return this.xrdService.user.getOwnedEntitiesDeep(contactId, shallowEntities)
            .pipe(map(
                (result) => {
                    if (this.session.user) {
                        this.session.user.deepentities = result;

                        this.saveSession();

                        return result;
                    }
                    else {
                        observableThrowError(new Error('this.session.user is undefined - cannot save the data'));
                    }
                }
            ));
    }

    loadUserOwnedEntitiesAccess(contactId: string): Observable<XRDEntityAccessDescriptor[]> {
        return this.xrdService.user.getOwnedEntitiesAccess(contactId)
            .pipe(map(
                (result) => {
                    if (this.session.user) {
                        this.session.user.entitiesaccess = result;

                        this.saveSession();

                        return result;
                    }
                    else {
                        observableThrowError(new Error('this.session.user is undefined - cannot save the data'));
                    }
                }
            ));
    }

    loadAdminEntity(entityId: string):Observable<XRDEntity> {
        return this.xrdService.entity.getEntityById(entityId)
            .pipe(map( entity =>{
                console.log('loadAdminEntity response', entity);
                this.session.adminentity = entity;
                this.saveSession();
                return entity;
            }));
    }

    removeAdminEntity(){
        if (this.session.adminentity !== undefined) {
            delete this.session.adminentity;
        }
        this.saveSession();
    }

    isUserSignedIn() {
        if (this.session && this.session.user && this.session.user.session) {
            return true;
        }
        else {
            return false;
        }
    }

    isUserSuperAdmin(): boolean {
        if(this.session && this.session.user){
            if (this.session.user.superadmin && this.session.user.superadmin === "true") {
                return true;
            } else {
                return false;
            }
        } else return false;
    }

    isUserDisplayNameSet(){
        if(this.session && this.session.user){
            if (this.session.user.displayname && this.session.user.displayname.length) {
                return true;
            } else {
                return false;
            }
        } else return false;
    }

    getUserContactId() {
        // this.logSession();

        if (!this.session || !this.session.user) {
            return undefined;
        }

        return this.session.user.contactid;
    }

    getLoadedStoresObservable(): Observable<any>{
        return this.loadedStoresEvent.asObservable();
    }

    canUserEditStore(entityId: string){
        if (!this.session || !this.session.user) {
            return false;
        }

        if(this.isUserSuperAdmin()) return true;

        if(this.session.user.deepentities) {
            if (this.session.user.deepentities.length > 0) {
                let result;
                _.each(this.session.user.deepentities, (entity: XRDEntity)=>{
                    if(entity.entityid === entityId){
                        result = true;
                    }
                });
                return result?result:false;
            }else return false;
        }else return false;
    }

    isSessionExpired(): Observable<boolean>{
        console.log('checking for expired session...');
        let sessionIsExpired: boolean = false;
        return this.xrdService.user.getUserById(this.getUserContactId(), true).pipe(
            catchError(err=>{
                if(err.status === 401){
                    console.log('session is expired');
                    sessionIsExpired = true;
                }else{
                    console.log('session is valid');
                    sessionIsExpired = false;
                }
                return observableFrom(['hi']);
            }),
            map(()=>{
                return sessionIsExpired;
            }));
    }

    getUserFirstName() {
        if (!this.session || !this.session.user) {
            return undefined;
        }

        return this.session.user.firstname;
    }

    getUserLastName() {
        if (!this.session || !this.session.user) {
            return undefined;
        }

        return this.session.user.lastname;
    }

    getUserUsername() {
        if (!this.session || !this.session.user) {
            return undefined;
        }

        return this.session.user.username;
    }

    getUserAddress(type: string) {
        if (!this.session || !this.session.user) {
            return undefined;
        }

        return _.filter(this.session.user.deepaddresses, (item: XRDAddress) => {
            return item.addresstype === type;
        });
    }

    getUserAddresses() {
        if (!this.session || !this.session.user) {
            return undefined;
        }

        return this.session.user.deepaddresses;
    }

    getUserPhone(type: string) {
        if (!this.session || !this.session.user) {
            return undefined;
        }

        return _.filter(this.session.user.deepphones, (item: XRDPhone) => {
            return item.phonetype === type;
        });
    }

    getUserPhones() {
        if (!this.session || !this.session.user) {
            return undefined;
        }

        return this.session.user.deepphones;
    }

    getUserEmail(type: string) {
        if (!this.session || !this.session.user) {
            return undefined;
        }
        return _.filter(this.session.user.deepemails, (item: XRDEmail) => {
            return item.emailtype === type;
        });
    }

    getUserEmailByID() {
        if (!this.session || !this.session.user) {
            return undefined;
        }

        return this.session.user.emails[0].emailid;
    }

    getUserEmails() {
        if (!this.session || !this.session.user) {
            return undefined;
        }

        return this.session.user.deepemails;
    }

    doesUserHaveOwnStore() {
        if (this.session && this.session.user && this.session.user.entities.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    getUserStores(): Observable<any[]>  {
        if(this.session && this.session.user){
            // Super admins get all the stores
            if(this.isUserSuperAdmin()){
                return this.xrdService.entity.getEntities(0, 200);
            // Non-super admins get their own entities
            }else if(this.session.user.deepentities) {
                if (this.session.user.deepentities.length > 0) {
                    let entities = this.session.user.deepentities;
                    return observableFrom([entities]); // Gotta wrap in [] for this to work
                } else return observableFrom([[]]);
            } else return observableFrom([[]]);
        }else return observableFrom([[]]);
    }

    getUserStoreSEOName() {
      if(this.session && this.session.user){
        if(this.session.user.deepentities) {
          if (this.session.user.deepentities && this.session.user.deepentities.length > 0) {
            let entity = _.first<XRDEntity>(this.session.user.deepentities);
            return entity.seoname;
          }
          else {
            return undefined;
          }
        }
      }
    }

    getUserStoreEntityId() {
      if(this.session && this.session.user) {
        if(this.session.user.deepentities){
          if (this.session.user.deepentities && this.session.user.deepentities.length > 0) {
            let entity = _.first<XRDEntity>(this.session.user.deepentities);
            return entity.entityid;
          }
          else {
            return undefined;
          }
        }
        }
    }

    getUserStoreName() {
        if(this.session && this.session.user) {
            if(this.session.user.deepentities){
                if (this.session.user.deepentities && this.session.user.deepentities.length > 0) {
                    let entity = _.first<XRDEntity>(this.session.user.deepentities);
                    return entity.name;
                }
                else {
                    return undefined;
                }
            }
        }
    }

    getUserStoreAddress() {
        if(this.session && this.session.user) {
            if(this.session.user.deepentities){
                if (this.session.user.deepentities && this.session.user.deepentities.length > 0) {
                    let entity = _.first<XRDEntity>(this.session.user.deepentities);
                    if(entity.addresses.length > 0){
                        return _.first<XRDAddress>(entity.addresses);
                    }else {
                        return undefined;
                    }
                }
            }
        }
    }

    getAdminEntitySEOName() {
        if(this.session && this.session.adminentity){
            if (this.session.adminentity.seoname) {
                return this.session.adminentity.seoname;
            }
            else {
                return undefined;
            }
        }
    }

    getAdminEntityId() {
        if(this.session && this.session.adminentity){
            if (this.session.adminentity.entityid) {
                return this.session.adminentity.entityid;
            } else return undefined;
        }else return undefined;
    }

    getAdminEntityName() {
        if(this.session && this.session.adminentity){
            if (this.session.adminentity.name) {
                return this.session.adminentity.name;
            }
            else {
                return undefined;
            }
        }
    }

    getAdminEntityType() {
        if(this.session && this.session.adminentity){
            if (this.session.adminentity.entitytype) {
                return this.session.adminentity.entitytype;
            }
            else {
                return undefined;
            }
        }
    }

    getAdminEntity() {
        if(this.session && this.session.adminentity){
            return this.session.adminentity;
        }
        return undefined;
    }

    getAdminEntityAddress() {
        if(!this.gesService.isRunOnBrowser()) {
            return {} as XRDAddress;
        }
        if(this.session && this.session.adminentity){
            if (this.session.adminentity.addresses.length > 0) {
                return _.first<XRDAddress>(this.session.adminentity.addresses);
            }
            else {
                return undefined;
            }
        }
    }

    setLightspeedRedirectToPage(location: string): void{
        if(this.session){
            this.session.adminlightspeed = {
                redirectToPage: location
            };
            this.saveSession();
        }
    }

    getLightspeedRedirectToPage() {
        if(!this.gesService.isRunOnBrowser()) {
            return undefined
        }
        if(this.session
            && this.session.adminlightspeed
            && this.session.adminlightspeed.redirectToPage){
            return this.session.adminlightspeed.redirectToPage;
        }else {
            return undefined;
        }
    }


    private saveSession() {
        if(this.gesService.isRunOnBrowser()) {
            localStorage.setItem('session', JSON.stringify(this.session));
        }
    }

    private logSession() {
        console.log('Session is: ' + JSON.stringify(this.session));
    }
}
