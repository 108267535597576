
import {forkJoin as observableForkJoin, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {XRDProduct} from './xrd-product.model';
import {XRDProductVariant} from './xrd-product-variant.class';
import {XRDProductGender} from './xrd-product-gender.class';
import {XRDProductVariantType} from './xrd-product-variant-type.model';

import {ConstantsService} from "../../xion-constants.service";
import {HttpLocalClient} from "../../../http-client.service";
import {XRDProductReview} from "./xrd-product-review.class";
import {XRDProductReviewHelpful} from "./xrd-product-review-helpful.class";
import { XRDProductImage } from './xrd-product-image.class';

@Injectable()
export class XRDProductService {

    private http;

    constructor(private constantsService: ConstantsService, private httpClient: HttpLocalClient) {
        this.http = this.httpClient;
    }

    productVariantToVariant(productVariant) {
        let variant = new XRDProductVariant();
        variant.productid = productVariant.productid;
        variant.color = productVariant.color;
        variant.size = productVariant.size;
        variant.upc = productVariant.upc;
        variant.productvariantid = productVariant.productvariantid;
        return variant;
    }

    getProductById(productId: string): Observable<XRDProduct> {
        return this.http.get(this.constantsService.getBackendURL() + 'product/' + productId)
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res.products || [];
            }));
    }

    getProductsWithEntityIdFlag(query: string, entityId: number, start: number, count: number): Observable<any> {
        return this.http.get(this.constantsService.getBackendURL() + 'product?query=' + encodeURIComponent(query) + '&entityid='+ entityId + '&start=' + start + '&count=' + count)
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res.products || [];
            }));
    }

    getProductBySEOName(productSEOName: string) {
        return this.http.get(this.constantsService.getBackendURL() + 'product/' + productSEOName)
            .pipe(map((res:any)=>{
                if(!res) return undefined;
                return res.products[0] || undefined;
            }))
    }

    getProductIdVariants(productId: string) {
        return this.http.get(this.constantsService.getBackendURL() + 'product/' + productId + '/variant?start=0&count=100')
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res.variants || [];
            }));
    }

    getProductIdVariantId(productId: string, variantId: string) {
        return this.http.get(this.constantsService.getBackendURL() + 'product/' + productId + '/variant/' + variantId)
            .pipe(map((res:any)=> res.variants || {}));
    }

    getProductDepartments(){
        return this.http.get(this.constantsService.getBackendURL() + 'lookup/ProductDepartment/item')
            .pipe(map((res:any)=> res.items || []));
    }

    getProductCategories(){
        return this.http.get(this.constantsService.getBackendURL() + 'lookup/ProductCategory/item')
            .pipe(map((res:any)=> res.items || [])); 
    }

    getProductSubCategories(){
        return this.http.get(this.constantsService.getBackendURL() + 'lookup/ProductSubCategory/item')
            .pipe(map((res:any)=> res.items || [])); 
    }

    // getProductCategories() {
    //     return observableForkJoin(
    //         this.http.get(this.constantsService.getBackendURL() + 'lookup/ProductCategory/item').pipe(map((res:any)=> res.items)),
    //         this.http.get(this.constantsService.getBackendURL() + 'lookup/ProductSubCategory/item').pipe(map((res:any)=> res.items))
    //     ).pipe(
    //         map((res:any)=> {
    //             console.log('res', res);

    //             // Strange error occurs without "as any[]";
    //             let categories: any[] = res[0] as any[];
    //             let subCategories: any[] = res[1] as any[];

    //             for (let subCat of subCategories) {
    //                 for (let cat of categories) {
    //                     if (cat.productcategory == subCat.productcategory) {
    //                         if (cat.productsubcategories) {
    //                             cat.productsubcategories.push(subCat);
    //                         } else {
    //                             cat.productsubcategories = [subCat];
    //                         }
    //                         break;
    //                     }
    //                 }
    //             }

    //             return categories;
    //         }));
    // }

    getProductVendors() {
        return this.http.get(this.constantsService.getBackendURL() + 'lookup/Vendor/item?start=0&count=1000')
            .pipe(map((res:any)=> res.items || {}));
    }

    addProductVendor(vendorName: string) {
        let payload = {
            items: [{Name: vendorName}]
        };

        return this.http.post(this.constantsService.getBackendURL() + 'lookup/Vendor/item', JSON.stringify(payload))
            .pipe(map((res:any)=> res.items[0].id));
    }

    getProductBrands() {
        return this.http.get(this.constantsService.getBackendURL() + 'lookup/Brand/item?start=0&count=1000')
            .pipe(map((res:any)=> res.items || {}));
    }

    getProductBrandByName(name: string) {
        return this.http.get(this.constantsService.getBackendURL() + 'lookup/Brand/item/' + name)
            .pipe(map((res:any)=>{
                if (!res) {
                    return {};
                }
                return res.items[0] || {};
            }));
    }

    addProductBrand(brandName: string) {
        let payload = {
            items: [{name: brandName.trim()}]
        };

        return this.http.post(this.constantsService.getBackendURL() + 'lookup/Brand/item', JSON.stringify(payload))
            .pipe(map((res:any)=> res.items[0].lookupitemid));
    }

    getProductGenders() {
        return this.http.get(this.constantsService.getBackendURL() + 'lookup/ProductGender/item')
            .pipe(map((res:any)=> {
                let genders = res.items || {};

                if (genders === {}) return genders;

                let result: XRDProductGender[] = [];
                genders.forEach(gender => {
                    result.push(new XRDProductGender(gender.productgenderid, gender.name));
                });
                return result;
            }));
    }

    getProductVariantTypes() {
        let mock: { variantTypes: XRDProductVariantType[] } = {
            variantTypes: [
                {
                    "id": "1",
                    "name": "size",
                },
                {
                    "id": "2",
                    "name": "color",
                },
            ]
        } as { variantTypes: XRDProductVariantType[] };
        return new Promise<XRDProductVariantType[]>(resolve => resolve(mock.variantTypes));
    }

    /**
     * Creates a default productVariant (When no variant is selected) with size "One Size" and the color designated.
     * @param productId
     * @param productVariant
     */
    createDefaultProductVariant(productId: string, productVariant/*: XRDProductVariant*/) {
        // createDefaultProductVariant(productId: string, productVariant){

        let payload = {
            variants: [productVariant]
        };

        return this.http.post(this.constantsService.getBackendURL() + 'product/' + productId + '/variant', JSON.stringify(payload))
            .pipe(map((res:any)=> res.variants[0].id || {}));
    }

    createProduct(product) {
        let payload = {
            products: [product]
        };

        return this.http.post(this.constantsService.getBackendURL() + 'product', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                // Set the product ID to be returned.
                return res.products[0].productid || {};
            }));
    }

    updateProduct(product) {
        let payload = {
            products: [product]
        };
        console.log('updating product: ' + product.productid, payload);

        return this.http.put(this.constantsService.getBackendURL() + 'product/' + product.productid, JSON.stringify(payload))
            .pipe(map(res=>{
                console.log('updating product res: ', res);
            }));
    }


    /**
     * Creates all variants with their given parameters for the product with productId.
     * @param productId
     * @param productVariants
     */
    createVariants(productId: string, productVariants: XRDProductVariant[]) {

        let payload = {
            variants: productVariants
        };

        return this.http.post(this.constantsService.getBackendURL() + 'product/' + productId + '/variant', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                let result = res.variants;
                console.log(result);
                return result;
            }));
    }

    updateVariant(productId, variant: XRDProductVariant) {
        let payload = {
            variants: [variant]
        };

        console.log('Updating at ', this.constantsService.getBackendURL() + 'product/' + productId + '/variant/' + variant.productvariantid);
        console.log(payload);
        return this.http.put(this.constantsService.getBackendURL() + 'product/' + productId + '/variant/' + variant.productvariantid, JSON.stringify(payload))
            .pipe(map((res:any)=> {
                console.log('res', res);
                return res;
            }));
    }

    getProductImages(productID: string): Observable<XRDProductImage[]> {
        // if(!productID) return new Observable();
        return this.http.get(this.constantsService.getBackendURL() + 'product/' + productID + '/image?start=0&count=100')
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res.images || [];
            }));
    }

    addProductImage(productID: string, productImagesToAdd: any[]) {
        let payload = {
            images: []
        };
        for (let image of productImagesToAdd) {
            let imageObject = {imagetype: '3', order: image.order, path: image.path};
            payload.images.push(imageObject);
        }

        console.log('Attempting to add images to product number ' + productID, payload);
        return this.http.post(this.constantsService.getBackendURL() + 'product/' + productID + '/image', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                return res.images;
            }));
    }

    putProductImage(productID: string, imageID: string, imageType: string, path: string) {
        let payload = {
            images: []
        };

        payload.images.push({
            "imagetype": imageType,
            "path": path
        });

        console.log('Attempting to put image to product number ' + productID + ' image number ' + imageID, payload);
        return this.http.put(this.constantsService.getBackendURL() + 'product/' + productID + '/image/' + imageID, JSON.stringify(payload))
            .pipe(map((res:any)=> {
                return 'success';
            }));
    }

    deleteProductImage(productID: string, imageID: string) {
        console.log('Attempting to delete image of product number ' + productID + ' image number ' + imageID);
        return this.http.delete(this.constantsService.getBackendURL() + 'product/' + productID + '/image/' + imageID)
            .pipe(map((res:any)=> {
                return 'success';
            }));
    }

    getProductInventories(entityID: string, productID: string) {
        return this.http.get(this.constantsService.getBackendURL() + 'entity/' + entityID + '/product/' + productID + '/inventory?start=0&count=1000')
            .pipe(map((res:any)=> {
                return res.inventories;
            }));
    }

    /** Contains special user case if parentEntityId is provided */
    getPublicProductVariantLocations(productID: string, productVariantID: string, lat, long, parentEntityId) {

        return this.http.get(this.constantsService.getBackendURL() + 'search/product/' + productID + '/variant/' + productVariantID + '?start=0&count=15&latitude=' + lat + '&longitude=' + long + (parentEntityId?('&parententityid='+parentEntityId):''))
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res.variants;
            }));
    }

    getProductReviews(productID: string, start:number = 0, count:number = 100): Observable<any> {
        return this.http.get(`${this.constantsService.getBackendURL()}product/${productID}/review?start=${start}&count=${count}`)
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res;
            }));
    }

    getProductReview(productID: string, reviewID: string): Observable<XRDProductReview> {
        return this.http.get(`${this.constantsService.getBackendURL()}product/${productID}/review/${reviewID}`)
            .pipe(map((res:any)=> {
                if(!res) return {};
                return res.reviews[0] || {};
            }));
    }

    createProductReview(productID: string, review: XRDProductReview) {
        let payload = {
            reviews: [
                review
            ]
        };

        return this.http.post(this.constantsService.getBackendURL() + 'product/' + productID + '/review', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                return res.reviews[0].productreviewid;
            }));
    }

    createProductReviewHelpful(productID: string, reviewID: string, helpful: XRDProductReviewHelpful) {
        let payload = {
            helpfuls: [
                helpful
            ]
        };

        return this.http.post(`${this.constantsService.getBackendURL()}product/${productID}/review/${reviewID}/helpful`, JSON.stringify(payload))
            .pipe(map((res:any)=> {
                return res.helpfuls[0].helpfulid;
            }));
    }

    uploadProductImage(file:any){

        const formData: FormData = new FormData();
        formData.append('image', file, file.name);

        return this.http.post(this.constantsService.getNodeBackendURL() + 'product/image', formData);
    }
}
