import {Directive, HostListener, EventEmitter, Output, ElementRef} from '@angular/core';
import * as _ from 'underscore';

@Directive({
    selector: '[xionHorizontalScroller]'
})
export class HorizontalScrollerDirective {

    /** For left and right buttons */
    @Output() showLeftUpdate = new EventEmitter();
    @Output() showRightUpdate = new EventEmitter();

    /** For lazy-loading products on horizontal scroll */
    @Output() scrollInitiated = new EventEmitter();

    /** Throttle so function is called at most once every 300 milliseconds */
    scrollThrottler = _.throttle(()=>{
        this.scrolling();
    }, 300);

    constructor(private el: ElementRef) {}

    /**
     * handle scroll event
     */
    @HostListener('scroll')
    onScroll(event){
        this.scrollThrottler();
    }

    scrolling(){
        // console.log('scrollLeft:', this.el.nativeElement.scrollLeft);
        this.scrollInitiated.emit(true);

        let farthestLeftPosition = this.el.nativeElement.scrollWidth - this.el.nativeElement.clientWidth;
        // Left
        if(this.el.nativeElement.scrollLeft === 0){
            this.showLeftUpdate.emit(false);
        }else{
            this.showLeftUpdate.emit(true);
        }

        // Right
        if(farthestLeftPosition <= this.el.nativeElement.scrollLeft){
            this.showRightUpdate.emit(false);
        }else{
            this.showRightUpdate.emit(true);
        }

    }
}
