import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

function _window(): any {
    // return the global native browser window object
    return window;
}

/**
 * Source: https://juristr.com/blog/2016/09/ng2-get-window-ref/#kudo
 * Should allow for window variable when using Universal for SSR
 */
@Injectable()
export class WindowRef {

    constructor(@Inject(PLATFORM_ID) protected platformId: Object) {}

    get nativeWindow(): any {
        if(isPlatformBrowser(this.platformId)){
            return _window();
        }else{
            // Dummy data window object
            return {
                alert: (message)=>{
                    console.log(message);
                },
                location: {
                    href: 'Ha!',
                    reload: ()=>{
                        return 'reload';
                    }
                },
                confirm: (message)=>{
                    console.log(message);
                    return true;
                },
                scrollTo: (x, y)=>{
                    console.log(x + y);
                },
                angularComponentRef: 'Whhooooooaaaaa',
                innerHeight: 0,
                scrollY: 0,
                innerWidth: 0,
                pageYOffset: 0,
                navigator: {
                    geolocation: {
                        getCurrentPosition: (x, y, z)=>{
                            return 'hi';
                        }
                    },
                },
                AWS: {},
            };
        }
    }
}
