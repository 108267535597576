export * from './xrd-address.model';
export * from './xrd-phone.model';
export * from './xrd-email.model';
export * from './xrd-link.model';
export * from './xrd-shallow-entity.model';
export * from './xrd-shallow-address.model';
export * from './xrd-shallow-phone.model';
export * from './xrd-shallow-email.model';
export * from './xrd-shallow-account.model';
export * from './xrd-shallow-group.model';

export * from './xrd-entity-access-descriptor.model';
export * from './xrd-entity-access.model';
