import { Component, OnInit, Input } from '@angular/core';
import {TimePipe} from "../../../../../shared/pipes/time.pipe";

@Component({
    selector: 'xion-store-hours-display-list',
    templateUrl: './store-hours-display-list.component.html',
    styleUrls: ['./store-hours-display-list.component.css'],
})
export class StoreHoursDisplayListComponent implements OnInit{

    public days: any;
    formattedHours: string = '';
    @Input() hoursData: any;
    rows: string[] = [];

    constructor(private timePipe: TimePipe) {
        this.days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    }

    ngOnInit() {
        // console.log('StoreHoursDisplayListComponent:ngOnInit()');

        // Formatting algorithm
        if(this.hoursData){
            for(let i=0; i < this.days.length; i++){
                if(this.hoursData[this.days[i]].isSelected){
                    if(i>0){
                        // Check if same as item previous
                        if(this.hoursData[this.days[i]].isSelected === this.hoursData[this.days[i-1]].isSelected &&
                            this.hoursData[this.days[i]].openTime === this.hoursData[this.days[i-1]].openTime &&
                            this.hoursData[this.days[i]].closeTime === this.hoursData[this.days[i-1]].closeTime){
                            let firstDay = this.rows[this.rows.length-1].substring(0, 3);
                            let thruDay = this.days[i].substring(0, 3);
                            this.rows[this.rows.length-1] = firstDay + '-' + thruDay + ' ' + this.timePipe.transform(this.hoursData[this.days[i]].openTime) + ' - ' + this.timePipe.transform(this.hoursData[this.days[i]].closeTime);
                        }else{
                            this.rows.push(this.days[i] + ' ' + this.timePipe.transform(this.hoursData[this.days[i]].openTime) + ' - ' + this.timePipe.transform(this.hoursData[this.days[i]].closeTime));
                        }
                    }else{
                        this.rows.push(this.days[i] + ' ' + this.timePipe.transform(this.hoursData[this.days[i]].openTime) + ' - ' + this.timePipe.transform(this.hoursData[this.days[i]].closeTime));
                    }
                }else{ // closed
                    this.rows.push(this.days[i] + ' Closed');
                }
            }
        }
    }

}
