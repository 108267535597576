import { XRDProductImageLink} from './xrd-product-image-link.class';

export class XRDProductImage {

    // "links": XRDProductImageLink[];

    imageid: any;
    order: string;
    path: string;

    // constructor(links:XRDProductImageLink[]){
    //     this.links = links;
    // }
}
