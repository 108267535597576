import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { XRDProductSearchResults } from './xrd-product-search-results.model';
import { ConstantsService } from '../../xion-constants.service';
import { HttpLocalClient } from '../../../http-client.service';
import { WindowRef } from '../../../WindowRef';

@Injectable()
export class XRDSearchService {
    private http;

    constructor(
        private constantsService: ConstantsService,
        private winRef: WindowRef,
        private httpClient: HttpLocalClient
    ) {
        this.http = this.httpClient;
    }

    public queryProducts(
        entityID,
        query: string,
        start: number,
        count: number
    ): Observable<XRDProductSearchResults> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/product?query=' +
                    encodeURIComponent(query)
            )
            .pipe(
                map((res: any) => {
                    return res || [];
                }),
                catchError(this.handleError)
            );
    }

    public queryEntityVariantsStartCount(
        query: string,
        entityId: number,
        start: number,
        count: number
    ): Observable<any> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/entity/' +
                    entityId +
                    '/variant?query=' +
                    encodeURIComponent(query) +
                    '&start=' +
                    start +
                    '&count=' +
                    count
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.variants || [];
                }),
                catchError(this.handleError)
            );
    }

    public queryProductsEntityStartCount(
        query: string,
        entityId: number,
        start: number,
        count: number
    ): Observable<any> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/product?query=' +
                    encodeURIComponent(query) +
                    '&entityid=' +
                    entityId +
                    '&start=' +
                    start +
                    '&count=' +
                    count
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.products || [];
                }),
                catchError(this.handleError)
            );
    }

    public queryPublicProductsStartCount(
        query: string,
        start: number,
        count: number
    ): Observable<XRDProductSearchResults> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/product?query=' +
                    encodeURIComponent(query) +
                    '&start=' +
                    start +
                    '&count=' +
                    count
            )
            .pipe(
                map((res: any) => {
                    if (!res) return { products: [] };
                    return res || { products: [] };
                }),
                catchError(this.handleError)
            );
    }

    public queryCategoryStartCount(
        query: string,
        category: string,
        start: number,
        count: number
    ): Observable<XRDProductSearchResults> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/product?category=' +
                    encodeURIComponent(category) +
                    '&query=' +
                    query +
                    '&start=' +
                    start +
                    '&count=' +
                    count
            )
            .pipe(
                map((res: any) => {
                    if (!res) return { products: [] };
                    return res || { products: [] };
                }),
                catchError(this.handleError)
            );
    }

    public oneSearchToRuleThemAll(
        params: any,
        start: number,
        count: number
    ): Observable<XRDProductSearchResults> {
        let paramString = 'start=' + start + '&count=' + count + '&';

        // Concat params
        for (let param in params) {
            if (params.hasOwnProperty(param)) {
                paramString +=
                    param + '=' + encodeURIComponent(params[param]) + '&';
            }
        }
        // Get rid of last '&'
        paramString = paramString.substring(0, paramString.length - 1);

        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/product?' +
                    paramString
            )
            .pipe(
                map((res: any) => {
                    if (!res) return { products: [] };
                    return res || { products: [] };
                }),
                catchError(this.handleError)
            );
    }

    public oneSearchCategoryToRuleThemAll(
        params: any,
        isDealsSearch = false
    ): Observable<any> {
        let paramString = '';

        // Concat params
        for (let param in params) {
            if (params.hasOwnProperty(param)) {
                paramString +=
                    param + '=' + encodeURIComponent(params[param]) + '&';
            }
        }

        if (isDealsSearch) {
            paramString += 'deal=true&';
        }

        // Get rid of last '&'
        paramString = paramString.substring(0, paramString.length - 1);

        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/category?' +
                    paramString
            )
            .pipe(
                map((res: any) => {
                    if (!res) return undefined;
                    return res || undefined;
                }),
                catchError(this.handleError)
            );
    }

    public dealsSearch(
        params: any,
        start: number,
        count: number
    ): Observable<XRDProductSearchResults> {
        let paramString = 'start=' + start + '&count=' + count + '&';

        // Concat params
        for (let param in params) {
            if (params.hasOwnProperty(param)) {
                paramString +=
                    param + '=' + encodeURIComponent(params[param]) + '&';
            }
        }
        // Get rid of last '&'
        paramString = paramString.substring(0, paramString.length - 1);

        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/deal?' +
                    paramString
            )
            .pipe(
                map((res: any) => {
                    if (!res) return { deals: [] };
                    return res || { deals: [] };
                }),
                catchError(this.handleError)
            );
    }

    public oneEntitySearchToRuleThemAll(
        entityId: string,
        params: any,
        start: number,
        count: number
    ): Observable<XRDProductSearchResults> {
        let paramString = 'start=' + start + '&count=' + count + '&';

        // Concat params
        for (let param in params) {
            if (params.hasOwnProperty(param)) {
                paramString +=
                    param + '=' + encodeURIComponent(params[param]) + '&';
            }
        }
        // Get rid of last '&'
        paramString = paramString.substring(0, paramString.length - 1);

        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/entity/' +
                    entityId +
                    '/product?' +
                    paramString
            )
            .pipe(
                map((res: any) => {
                    if (!res) return { products: [] };
                    return res || { products: [] };
                }),
                catchError(this.handleError)
            );
    }

    public oneEntitySearchCategoryToRuleThemAll(
        entityId: string,
        params: any,
        isDealsSearch = false
    ): Observable<any> {
        let paramString = '';

        // Concat params
        for (let param in params) {
            if (params.hasOwnProperty(param)) {
                paramString +=
                    param + '=' + encodeURIComponent(params[param]) + '&';
            }
        }

        if (isDealsSearch) {
            paramString += 'deal=true&';
        }

        // Get rid of last '&'
        paramString = paramString.substring(0, paramString.length - 1);

        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/entity/' +
                    entityId +
                    '/category?' +
                    paramString
            )
            .pipe(
                map((res: any) => {
                    if (!res) return undefined;
                    return res || undefined;
                }),
                catchError(this.handleError)
            );
    }

    public querySubCategoryStartCount(
        query: string,
        category: string,
        subcategory: string,
        start: number,
        count: number
    ): Observable<XRDProductSearchResults> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/product?category=' +
                    encodeURIComponent(category) +
                    '&subcategory=' +
                    encodeURIComponent(subcategory) +
                    '&query=' +
                    query +
                    '&start=' +
                    start +
                    '&count=' +
                    count
            )
            .pipe(
                map((res: any) => {
                    if (!res) return { products: [] };
                    return res || { products: [] };
                }),
                catchError(this.handleError)
            );
    }

    public queryBrandStartCount(
        query: string,
        brand: string,
        start: number,
        count: number
    ): Observable<XRDProductSearchResults> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/product?brand=' +
                    encodeURIComponent(brand) +
                    '&query=' +
                    query +
                    '&start=' +
                    start +
                    '&count=' +
                    count
            )
            .pipe(
                map((res: any) => {
                    if (!res) return { products: [] };
                    return res || { products: [] };
                }),
                catchError(this.handleError)
            );
    }

    public queryPublicEntityProductsStartCount(
        entityId: string,
        query: string,
        start: number,
        count: number
    ): Observable<XRDProductSearchResults> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/entity/' +
                    entityId +
                    '/product?query=' +
                    encodeURIComponent(query) +
                    '&start=' +
                    start +
                    '&count=' +
                    count
            )
            .pipe(
                map((res: any) => {
                    if (!res) return { products: [] };
                    return res || { products: [] };
                }),
                catchError(this.handleError)
            );
    }

    public queryPublicEntityProductsQueryCatStartCount(
        entityId: string,
        query: string,
        category: string,
        start: number,
        count: number
    ): Observable<XRDProductSearchResults> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/entity/' +
                    entityId +
                    '/product?query=' +
                    encodeURIComponent(query) +
                    '&category=' +
                    encodeURIComponent(category) +
                    '&start=' +
                    start +
                    '&count=' +
                    count
            )
            .pipe(
                map((res: any) => {
                    if (!res) return { products: [] };
                    return res || { products: [] };
                }),
                catchError(this.handleError)
            );
    }

    public queryEntityCategoryStartCount(
        entityId: string,
        query: string,
        category: string,
        start: number,
        count: number
    ): Observable<XRDProductSearchResults> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/entity/' +
                    entityId +
                    '/product?category=' +
                    encodeURIComponent(category) +
                    '&query=' +
                    query +
                    '&start=' +
                    start +
                    '&count=' +
                    count
            )
            .pipe(
                map((res: any) => {
                    if (!res) return { products: [] };
                    return res || { products: [] };
                }),
                catchError(this.handleError)
            );
    }

    public queryEntitySubCategoryStartCount(
        entityId: string,
        query: string,
        category: string,
        subCategory: string,
        start: number,
        count: number
    ): Observable<XRDProductSearchResults> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/entity/' +
                    entityId +
                    '/product?category=' +
                    encodeURIComponent(category) +
                    '&subcategory=' +
                    encodeURIComponent(subCategory) +
                    '&query=' +
                    query +
                    '&start=' +
                    start +
                    '&count=' +
                    count
            )
            .pipe(
                map((res: any) => {
                    if (!res) return { products: [] };
                    return res || { products: [] };
                }),
                catchError(this.handleError)
            );
    }

    public queryEntityBrandStartCount(
        entityId: string,
        query: string,
        brand: string,
        start: number,
        count: number
    ): Observable<XRDProductSearchResults> {
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/entity/' +
                    entityId +
                    '/product?brand=' +
                    encodeURIComponent(brand) +
                    '&query=&start=' +
                    start +
                    '&count=' +
                    count
            )
            .pipe(
                map((res: any) => {
                    if (!res) return { products: [] };
                    return res || { products: [] };
                }),
                catchError(this.handleError)
            );
    }

    getSearchEntityProductBySEOName(entityID: string, productSEOName: string) {
        // console.info('Getting product at this address: ' + this.constantsService.getBackendURL() + 'entity/' + entityID + '/product?seoname=' + productSEOName);
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/entity/' +
                    entityID +
                    '/product/' +
                    productSEOName
            )
            .pipe(
                map((res: any) => {
                    if (!res) return [];
                    return res.products || [];
                })
            );
    }

    getSearchEntitySeonameProductSeoname(
        entitySeoname: string,
        productSeoname: string
    ) {
        // console.info('Getting product at this address: ' + this.constantsService.getBackendURL() + 'entity/' + entityID + '/product?seoname=' + productSEOName);
        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/entity/' +
                    entitySeoname +
                    '/product/' +
                    productSeoname
            )
            .pipe(
                map((res: any) => {
                    if (!res) return undefined;
                    return res.products[0] || undefined;
                })
            );
    }

    getEntityDeals(
        entityId: string,
        params: any,
        start: number,
        count: number
    ): Observable<any> {
        let paramString = 'start=' + start + '&count=' + count + '&';

        // Concat params
        for (let param in params) {
            if (params.hasOwnProperty(param)) {
                paramString +=
                    param + '=' + encodeURIComponent(params[param]) + '&';
            }
        }
        // Get rid of last '&'
        paramString = paramString.substring(0, paramString.length - 1);

        return this.http
            .get(
                this.constantsService.getBackendURL() +
                    'search/entity/' +
                    entityId +
                    '/deal?' +
                    paramString
            )
            .pipe(
                map((res: any) => {
                    if (!res) return { deals: [] };
                    return res || { deals: [] };
                })
            );
    }

    private handleError(error: any) {
        // In a real world app, we might use a remote logging infrastructure
        // We'd also dig deeper into the error to get a better message
        // console.log("Inside handleError: error is: " + JSON.stringify(error, null, 2));

        let errMsg = error.message
            ? error.message
            : error.status
            ? `${error.status} - ${error.statusText}`
            : 'Server error';
        console.error(errMsg); // log to console instead
        if (error.status === 403) {
            this.winRef.nativeWindow.alert(
                "Whoa, easy there partner. Looks like you don't have permission to take that action. Try logging out and logging in again. If that doesn't work, please contact our support team."
            );
        }
        return observableThrowError(error);
    }
}
