import {Component, OnInit, ViewChild, Inject, OnDestroy, Input} from '@angular/core';
import {SessionService} from "../../../session-service/session.service.interface";
import {SESSION_SERVICE_TOKEN} from "../../../session-service/index";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {GlobalEventsService} from "../../../global-events.service";

@Component({
    selector: 'xion-store-admin-modal',
    templateUrl: './store-admin-modal.component.html',
    styleUrls: ['./store-admin-modal.component.css']
})
export class StoreAdminModalComponent implements OnInit {

    @ViewChild('modal', { static: true }) modal;
    private sessionService: SessionService;

    /** Observable containing the stores the user has access to */
    @Input() stores: any[] = [];

    isSuperAdmin = false;

    constructor(@Inject(SESSION_SERVICE_TOKEN) sessionService: SessionService,
                public globalEventsService: GlobalEventsService,
                private router: Router) {
        this.sessionService = sessionService;
        this.isSuperAdmin = this.sessionService.isUserSuperAdmin();
    }

    ngOnInit() {}

    swapAdminEntity(entityId) {
        this.sessionService.loadAdminEntity(entityId)
            .subscribe(entity =>{
                console.log('Swapped adminentity to entity: ', entity);
                this.router.navigate(['/' + entity.seoname]);
            });
    }

    open(){
        this.modal.open();
    }
}
