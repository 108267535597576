<div *ngIf="isLoading"
     style='transform:scale(0.99);'
     [class]="class"
     [class.pos-absolute]="absolutePosition">
    <div></div>
</div>

<ng-content>

</ng-content>

<!--<md-spinner *ngIf="isLoading"-->
            <!--[class]="class"-->
            <!--[class.pos-absolute]="absolutePosition">-->
<!--</md-spinner>-->
