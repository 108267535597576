import {XRDEntity} from "../../../shared/xrd/entity/xrd-entity.model";
import {XRDAddress} from "../../../shared/xrd/shared/xrd-address.model";

export class LocalShopDummyProduct extends XRDEntity{
    name: string;
    seoname: string;
    logo: string;
    distance: string;
    background: string;
    addresses: XRDAddress[];

    constructor(){
        super();
        this.name = '';
        this.seoname = '';
        this.logo = 'assets/img/logo_default_image_01.png';
        this.background = '';
        this.addresses = [] as XRDAddress[];
    }
}
