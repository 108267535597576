import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CenteredImageComponent} from "./centered-image.component";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        CenteredImageComponent,
    ],
    exports: [
        CenteredImageComponent,
    ]
})
export class CenteredImageModule { }
