import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OrderSummaryBoxComponent} from "./order-summary-box.component";
import {CartItemSummaryModule} from "../../marketplace/shared/cart-items-summary/cart-item-summary/cart-item-summary.module";
import {ValuesPipeModule} from "../pipes/values-pipe.module";
import {SharedModule} from "../shared.module";
import {CartItemsSummaryModule} from "../../marketplace/shared/cart-items-summary/cart-items-summary.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        CartItemSummaryModule,
        ValuesPipeModule,
        CartItemsSummaryModule,
    ],
    declarations: [
        OrderSummaryBoxComponent
    ],
    exports: [
        OrderSummaryBoxComponent
    ]
})
export class OrderSummaryBoxModule { }
