import { Injectable }    from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable }    from 'rxjs';
export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}
@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    canDeactivate(component: CanComponentDeactivate) {
        return component.canDeactivate ? component.canDeactivate() : true;
    }
}

/** Below is the code for a single component. Above is for all components that use this as a route-guard */

// import { Injectable }    from '@angular/core';
// import {CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
// import { Observable }    from 'rxjs/Observable';
// import {ProductGeneralInfoComponent} from "../product-general-info/product-general-info.component";
// // export interface CanComponentDeactivate {
// //     canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
// // }
// @Injectable()
// export class CanDeactivateGuard implements CanDeactivate<ProductGeneralInfoComponent> {
//     canDeactivate(component: ProductGeneralInfoComponent,
//                   route: ActivatedRouteSnapshot,
//                   state: RouterStateSnapshot): Promise<boolean> | boolean {
//
//         // console.log(currentRoute);
//         // console.log(currentState);
//         // console.log(nextState);
//         // let url: string = state.url;
//         // console.log('URL:', url);
//         console.log('CanDeactivate Parent');
//         // let thisObservable: Observable<boolean> = component.canDeactivate() as Observable<boolean>;
//         // thisObservable.subscribe();
//
//         return component.canDeactivate();
//     }
// }
