import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import {
    BrowserModule,
    BrowserTransferStateModule,
} from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';

// import './shared/rxjs-operators';
// TODO: Kev, this can probably be removed
// import {TransferHttpCacheModule} from "@nguniversal/common";
import { PrebootModule } from 'preboot';
import { CheckoutNewComponent } from './marketplace/checkout-new/checkout-new.component';
import { ExpiredSessionComponent } from './marketplace/expired-session/expired-session.component';
import { PopupComponent } from './marketplace/popup/popup.component';

@NgModule({
    declarations: [
        AppComponent,
        CheckoutNewComponent,
        PopupComponent,
        ExpiredSessionComponent,
    ],
    imports: [
        // BrowserModule,
        BrowserModule.withServerTransition({ appId: 'xion' }),
        // PrebootModule.withConfig({ appRoot: 'xion-root', replay: false }),
        BrowserTransferStateModule,
        // TODO: Kev, this can probably be removed
        // TransferHttpCacheModule,
        CoreModule,
        AppRoutingModule,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
