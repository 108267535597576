
import {from as observableFrom, Observable} from 'rxjs';
import {Injectable, Inject}       from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
}                           from '@angular/router';
import {SessionService} from "./session-service/session.service.interface";
import {SESSION_SERVICE_TOKEN} from "./session-service/index";
import {map} from "rxjs/operators";

@Injectable()
export class SessionExpiredAuthGuard implements CanActivate {

    private sessionService: SessionService;

    redirectUrl;

    constructor(private router: Router,
                @Inject(SESSION_SERVICE_TOKEN) sessionService: SessionService) {
        this.sessionService = sessionService;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        let url: string = state.url;
        if(this.sessionService.isUserSignedIn()){
            return this.sessionService.isSessionExpired()
                .pipe(map(isExpired=>{
                    if(isExpired){
                        // Pop up session expired login modal if session is expired
                        this.redirectUrl = url;
                        this.router.navigate([{outlets: { loginModal: 'expired-session' } }]); // Update: I couldn't get this to work (it's an Angular bug): , { skipLocationChange: true });
                        return false;
                    } else {
                        // Don't pop up expired login modal
                        return true;
                    }
                }));
        }else{
            // Don't pop up expired login modal
            return observableFrom([true]);
        }
    }
}
