import { Component, Input, OnInit } from '@angular/core';
import { ICartItemSummary } from './cart-item-summary.model';
import {CartService} from "../../../cart/cart-service/cart.service";

@Component({
    selector: 'xion-cart-item-summary',
    templateUrl: './cart-item-summary.component.html',
    styleUrls: [ './cart-item-summary.component.css' ],
})
export class CartItemSummaryComponent implements OnInit {

    @Input() item: ICartItemSummary;

    removeItemText = "Remove";
    @Input() isRemovable: boolean = true;

    ngOnInit() {}

    constructor(private cartService:CartService){}

    removeItem(){
        console.log('Yo, lets remove this junk');
        this.cartService.removeItemFromCart(this.item);
    }
}
