import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HomeComponent} from "./home.component";
import {LocalShopsComponent} from "./local-shops/local-shops.component";
import {UserActionsComponent} from "./user-actions/user-actions.component";
import {UserActionBoxComponent} from "./user-actions/user-action-box/user-action-box.component";
import {HomePromotedProductsComponent} from "./home-promoted-products/home-promoted-products.component";
import {HorizontalScrollerWrapperComponent} from "./horizontal-scroller-wrapper/horizontal-scroller-wrapper.component";
import {HorizontalScrollerDirective} from "./horizontal-scroller-wrapper/horizontal-scroller.directive";
import {HorizontalScrollerComponent} from "./horizontal-scroller/horizontal-scroller.component";
import {HomeLocalDealsComponent} from "./home-local-deals/home-local-deals.component";
import {SharedModule} from "../../shared/shared.module";
import {StoreCardModule} from "../../shared/store-card/store-card.module";
import {EmailSubscriptionFormModule} from "../../shared/email-subscription-form/email-subscription-form.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        StoreCardModule,
        EmailSubscriptionFormModule,
    ],
    declarations: [
        HomeComponent,
        LocalShopsComponent,
        UserActionsComponent,
        UserActionBoxComponent,
        HomePromotedProductsComponent,
        HorizontalScrollerWrapperComponent,
        HorizontalScrollerDirective,
        HorizontalScrollerComponent,
        HomeLocalDealsComponent,
    ],
    exports: [
        HomeComponent,
    ]
})
export class HomeModule { }
