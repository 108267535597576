
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class ClientSessionTokenService {

    cachedRequests: any[] = [];

    constructor(@Inject(PLATFORM_ID) protected platformId: Object) {}

    getSessionToken(): string{

        let session;

        if(isPlatformBrowser(this.platformId)) {
            session = JSON.parse(localStorage.getItem('session'));
        }

        if (!session || !session.user || !session.user.session) {
            return undefined;
        }

        return session.user.session;
    }

    saveSessionToken(newSession): void{
        if(isPlatformBrowser(this.platformId)) {
            let session = JSON.parse(localStorage.getItem('session'));
            if (!session) {
                console.log('Tyring to create a new session object...');
                session = {};
                session.user = {}
            }else if(!session.user){
                session.user = {};
            }
            session.user.session = newSession;
            localStorage.setItem('session', JSON.stringify(session));
        }
    }

    cacheRequest(request): void{
        this.cachedRequests.push(request);
    }

    tryAgain(){
        observableForkJoin(...this.cachedRequests)
            .subscribe(res=>{
                console.log('try again res kev', res);
            },
            err=>{
                console.log('try again err kev', err);
            });
    }

}
