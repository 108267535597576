<div class="cart-item-summary-container">
    <div class="flex-1">
        <xion-centered-image [imagePath]="item.imageUrl || 'assets/img/default_product_image1.png'"
                             [imageName]="'Cart Item'"
                             [leftAlign]="true">
        </xion-centered-image>
    </div>
    <div class="flex-2 product-summary">
        <div class="right">{{item?.brand}} {{item.name}} {{item?.modelYear}}</div>
        <div class="right light-gray">
            {{item.color}}{{item.size?(item.color?', ':''):''}}{{item.size}}
        </div>
        <div class="right">Qty {{item.quantity}}</div>
        <div class="right">{{item.itemPrice | currency:'USD':'symbol':'1.2-2'}}</div>
    </div>
    <xion-hover-replace-remove *ngIf="isRemovable" [displayText]="removeItemText" [showTrashCan]="false" (itemReplace)="removeItem()"></xion-hover-replace-remove>
</div>

