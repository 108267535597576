<div id="main"
     *ngIf="show"
     [style.top.px]="absolutePosition?top:(stacked?top:10)"
     [ngClass]="{
        'fadeout': fadeOut,
        'top-animate': true,
        'warning': theme}"
     #main>
        <p>{{ displayText }}</p>
</div>
