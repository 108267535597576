import { XRDProductVariant } from './xrd-product-variant.class';
export class XRDProduct {
    productid:string;
    name:string;
    seoname:string;
    summary:string;
    description:string;
    modelyear:string;
    specs:string;
    primaryimage:string;
    defaultcost:string;
    msrp:string;
    map:string;
    uom:string;
    brand:string;
    brandid:string;
    vendor:string;
    vendorid:string;
    manufacturer:string;
    manufacturerid:string;
    productdepartment:string;
    productdepartmentid:string;
    productcategory:string;
    productcategoryid:string;
    productsubcategory:string;
    productsubcategoryid:string;
    productgender:string;
    productgenderid:string;
    archived:string;
    quantity:string;
    links: {
        rel: string;
        href: string;
    }[];
    images: {
        imageid: string;
        type: string;
        links: {
            rel:string,
            href:string
        }[];
    }[];
    variants: XRDProductVariant[];

    price: string; // this is in the search results // TODO: remove this and make search results their own thing
}
