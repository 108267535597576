
import {forkJoin as observableForkJoin, from as observableFrom, Observable, throwError} from 'rxjs';

import {mergeMap, map, flatMap} from 'rxjs/operators';
import {Injectable, Inject, PLATFORM_ID, ChangeDetectorRef} from '@angular/core';
import {ICartItemSummary} from "../../shared/cart-items-summary/cart-item-summary/cart-item-summary.model";
import * as _ from 'underscore';
import {XRDService} from "../../../shared/xrd/xrd.service";
import {XRDOrderItem} from "../../../shared/xrd/order/xrd-order-item.model";
import {Cart} from "../cart.model";
import {FadeOutNotificationService} from "../../../shared/fade-out-notification-stack/fade-out-notification/fade-out-notification.service";
import {SESSION_SERVICE_TOKEN} from "../../../shared/session-service/index";
import {SessionService} from "../../../shared/session-service/session.service.interface";
import {GlobalEventsService} from "../../../shared/global-events.service";
import {XRDOrder} from "../../../shared/xrd/order/xrd-order.model";
import {ThirdPartySessionTrackingService} from "../../shared/third-party-session-tracking/third-party-session-tracking.service";
import { forkJoin } from 'rxjs';
import { XRDProduct } from '../../../shared/xrd/product/xrd-product.model';

@Injectable()
export class CartService{

    CART_STORAGE_KEY = 'shoppingCart';
    userCart: Cart = this.getCart();
    itemCount: number = 0;
    subtotal: number = 0;

    /** cart to use in checkout when user clicks "Buy Now" on product page. Also used as a flag. */
    userBuyNowCart: Cart;

    sessionService: SessionService;

    constructor(private xrdService: XRDService,
                private geService: GlobalEventsService,
                private tpstService: ThirdPartySessionTrackingService,
                @Inject(SESSION_SERVICE_TOKEN) sessionService: SessionService,
                @Inject(PLATFORM_ID) protected platformId: Object,
                private fadeOutNotificationService: FadeOutNotificationService) {
        this.sessionService = sessionService;

        // If cart has old data (which was stored in a array of ICartItemSummary), remove it.
        if((this.getCart() as any).length >= 1){
            this.deleteCart();
        }
    }

    /** Gets the cart as an array of cart item summaries. */
    getCart(): Cart{
        if(this.geService.isRunOnBrowser()){
            if(this.userBuyNowCart){
                return JSON.parse(JSON.stringify(this.userBuyNowCart));
            }else{
                return JSON.parse(localStorage.getItem(this.CART_STORAGE_KEY)) || {} as Cart;
            }
        }else{
            return {} as Cart;
        }
    }

    /** Gets the cart item by productVariantId */
    getCartItemById(productVariantId, entityId){
        let cart = this.getCart();

        if(_.isEmpty(cart) || !cart || !cart.entities || !cart.entities[entityId]){
            return undefined;
        }
        // First, organize by product variant ids
        let orderItemsByProdVarID = _.groupBy(cart.entities[entityId].orderItems, (item:ICartItemSummary)=>item.productVariantID);
        if(orderItemsByProdVarID[productVariantId]){
            return _.flatten(orderItemsByProdVarID[productVariantId])[0] as ICartItemSummary;
        }else{
            return undefined;
        }
    }

    /** Adds a single cart item summary to the buy-now-cart */
    addItemToBuyNowCart(item:ICartItemSummary): Observable<any>{
        if(!item.entityID || !item.itemPrice || !item.quantity){
            console.error('Error adding item to cart.', item);
            return observableFrom(['Error adding item to cart']);
        }
        let cart = { entities: {} } as Cart;

        return this.addItemLocallyAndRemotely(item, cart).pipe(
            map(()=>{
                this.AddToBuyNowCart(cart);
                console.log('Successfully added item to buy now cart');
            }));
    }

    // Creates order as Buy Now order (not removing the cart)
    // This is for abandoned cart emails
    addOneOffOrder(orderId): Observable<any>{
        let cart = {
            entities: {}
        };

        // entity
        let entityId, entityName, parentEntityID;
        // order
        let orderShippingType

        let cartItems = [] as ICartItemSummary[];
        let oAdditionalDataCalls = [];

        return this.xrdService.order.getOrderByID(orderId)
            .pipe(mergeMap(order=>{
                orderShippingType = order.ordershippingtype;
                entityId = order.entities[0].entityid;
                if(!order.items.length) return throwError(['No order items in order']);
                
                for(let item of order.items){
                    let cartItem: ICartItemSummary = {} as ICartItemSummary;
                    cartItem.productID = item.productid;
                    cartItem.productVariantID = item.productvariantid;
                    // color = item.color;
                    // size = item.size; // Currently, Robert isn't saving these on the order item :(
                    // upc = item.upc;
                    cartItem.itemPrice = Number(item.price);
                    cartItem.quantity = Number(item.quantity);
                    cartItem.orderItemID = item.orderitemid;
                    cartItem.orderShippingType = orderShippingType;;
                    oAdditionalDataCalls.push(
                        forkJoin(
                            this.xrdService.product.getProductIdVariantId(cartItem.productID, cartItem.productVariantID)
                                .pipe(map(variants=>{
                                    let variant = variants[0];
                                    cartItem.color = variant.color;
                                    cartItem.size = variant.size;
                                    cartItem.upc = variant.upc;
                                })),
                            this.xrdService.product.getProductById(cartItem.productID)
                                .pipe(map(products=>{
                                    let product = products[0] as XRDProduct;
                                    cartItem.brand = product.brand;
                                    cartItem.name = product.name;
                                    cartItem.modelYear = product.modelyear;
                                    cartItem.seoName = product.seoname;
                                    cartItem.imageUrl = product.primaryimage;
                                    cartItem.category = product.productcategory;
                                    cartItem.subCategory = product.productsubcategory;
                                    cartItem.entity = entityName;
                                    cartItem.entityID = entityId;
                                    cartItem.parentEntityID = parentEntityID;
                                })),
                        ).pipe(map(()=>{
                            cartItems.push(cartItem);
                        }))
                    );
                }
                return this.xrdService.entity.getEntityById(entityId)
            }),
            mergeMap(entity=>{
                entityName = entity.name;
                parentEntityID = entity.parententityid;
                return forkJoin(...oAdditionalDataCalls);
            }),
            map(res=>{
                cart.entities[entityId] = {
                    orderId: orderId,
                    orderNumber: '',
                    orderItems: cartItems
                }
                this.AddToBuyNowCart(cart);
                return true;
            }));
    }

    /** Adds a single cart item summary to the cart (creates the cart if not already created) */
    addItemToCart(item:ICartItemSummary){
        if(!item.entityID || !item.itemPrice || !item.quantity){
            console.error('Error adding item to cart.', item);
            return 'Error adding item to cart';
        }
        let cart = this.getCart();

        if(_.isEmpty(cart) || !cart){
            cart = {
                entities: {}
            };
        }

        let isFirstItemInCart = _.isEmpty(cart.entities);

        let session = JSON.parse(localStorage.getItem('session'));
        // This is in case they are browsing in incognito mode (localstorage doesn't work)
        if(!session){
            this.fadeOutNotificationService.addNotification('Uh oh, purchases can only be made in a non-private mode browser', 'warning');
            return;
        }

        this.addItemLocallyAndRemotely(item, cart)
            .subscribe(()=>{
                // Pop open cart if first item added
                if(isFirstItemInCart){
                    console.log('First item added');
                    this.geService.goTo('main-app-container');
                    // For some reason, it wouldn't work on some browsers without this setTimeout
                    setTimeout(()=>{
                        this.geService.showCartPopover.value = true;
                    }, 0);
                }
                this.updateCart(cart);
                this.fadeOutNotificationService.addNotification('Item was added to your cart!');
                console.log('Successfully added item to cart.');
            });
    }

    private addItemLocallyAndRemotely(item:ICartItemSummary, cart:Cart): Observable<any>{
        return observableFrom(['creating item remotely']).pipe(
            mergeMap(()=>{
                if(cart.entities[item.entityID]){
                    // Order already exists
                    let oRemoteUpdates = [];
                    let itemMatch = false;
                    for(let cartItem of cart.entities[item.entityID].orderItems) {
                        if (item.productVariantID == cartItem.productVariantID) {
                            // Order Item already exists
                            // Incrementing quantity
                            itemMatch = true;
                            cartItem.quantity += item.quantity;
                            oRemoteUpdates.push(this.xrdService.order.updateOrderItem(cart.entities[item.entityID].orderId, cartItem.orderItemID, <XRDOrderItem>cartItem));
                        }
                    }
                    if(!itemMatch){
                        // Order Item doesn't already exist
                        // create new order item
                        oRemoteUpdates.push(this.createOrderItem(item, cart));
                    }
                    if(item.orderShippingType){
                        let order = {
                              ordershippingtype: item.orderShippingType
                        } as XRDOrder;
                        oRemoteUpdates.push(this.xrdService.order.updateOrder(cart.entities[item.entityID].orderId, order));
                    }
                    return observableForkJoin(...oRemoteUpdates);
                }else{
                    // Create an order
                    cart.entities[item.entityID] = {};
                    return this.xrdService.order.createOrder(item.entityID, item.orderShippingType?{ordershippingtype:item.orderShippingType}:{})
                        .pipe(
                            map((order:XRDOrder)=>{
                                console.log('order created: ', order);
                                cart.entities[item.entityID].orderId = order.orderid;
                                cart.entities[item.entityID].orderItems = [];
                                return order.orderid;
                            }),
                            flatMap(orderId=>{
                                return this.createOrderItem(item, cart);
                            })
                        )
                }
            }),
            map(()=>{
                // if user logged in, tag user as abandoned cart in DRIP
                if(this.sessionService.isUserSignedIn()){
                    this.tpstService.addAbandonedCartDripTag(this.getDripCartObject(this.sessionService.getUserUsername()));
                }
            }))
    }

    /**
     * used in the checkout process if user decides to change their shipping option.
     * Previously it was changed on order, but it needs to be synced locally for the DRIP integration
     */
    updateShippingOptions(entityId, newShippingOption){

        let cart = this.getCart();

        if(_.isEmpty(cart) || !cart){
            return;
        }

        if(cart.entities[entityId]) {
            for (let cartItem of cart.entities[entityId].orderItems) {
                cartItem.orderShippingType = newShippingOption;
            }
        }
        
        if(!this.userBuyNowCart){
            this.updateCart(cart);
        }
    }

    private createOrderItem(item:ICartItemSummary, cart:Cart): Observable<any>{
        return this.xrdService.order.createOrderItem(cart.entities[item.entityID].orderId, item.productID, item.productVariantID, item.itemPrice, item.quantity, item.color, item.size)
            .pipe(map(orderItems=> {
                console.log('order item created: ', orderItems[0].orderitemid);
                item.orderItemID = orderItems[0].orderitemid;
                cart.entities[item.entityID].orderItems.push(item);
                return orderItems[0].orderitemid;
            }));
    }

    removeItemFromCart(item){
        // console.log('Removing item: ', item);
        let cart = this.getCart();

        if(_.isEmpty(cart) || !cart){
            console.log('Nothing to remove');
            return;
        }

        if(cart.entities[item.entityID]){
            for(let i=0; i<cart.entities[item.entityID].orderItems.length; i++){
                if(cart.entities[item.entityID].orderItems[i].productVariantID == item.productVariantID){

                    // remove locally
                    cart.entities[item.entityID].orderItems.splice(i, 1);

                    // remove remotely
                    this.xrdService.order.removeOrderItem(cart.entities[item.entityID].orderId, item.orderItemID)
                        .subscribe(()=>{
                            console.log('Successfully deleted item from cart.');
                        });
                }
            }

            // If no more order Items, remove locally the order
            if(cart.entities[item.entityID].orderItems.length === 0){
                delete cart.entities[item.entityID];
            }

            // Remove cart if empty, else update
            if(_.isEmpty(cart.entities)){
                this.deleteCart();
            }else{
                this.updateCart(cart);
            }
        }
    }

    AddToBuyNowCart(cart){
        this.userBuyNowCart = cart;
    }

    unsetBuyNowCart(){
        this.userBuyNowCart = undefined;
    }

    updateCart(cart?){
        if(!_.isEmpty(cart) && cart){
            if(this.geService.isRunOnBrowser()) {
                localStorage.setItem(this.CART_STORAGE_KEY, JSON.stringify(cart));
                this.userCart = cart;
            }
        }
        this.countItems();
        this.calculateSubTotal();
    }

    deleteCart(){
        if(this.userBuyNowCart){
            this.unsetBuyNowCart();
        }else{
            if(this.geService.isRunOnBrowser()) {
                localStorage.removeItem(this.CART_STORAGE_KEY);
            }
            // Initialize local variables
            this.userCart = {} as Cart;
            this.itemCount = 0;
            this.subtotal = 0;
        }
    }

    countItems(){
        this.itemCount = 0;
        for(let entity in this.userCart.entities){
            if (this.userCart.entities.hasOwnProperty(entity)) {
                this.itemCount += this.userCart.entities[entity].orderItems.reduce((count: number, item: any) => {
                    return count + item.quantity;
                }, 0);
            }
        }
    }

    calculateSubTotal(){
        this.subtotal = 0;
        for(let entity in this.userCart.entities){
            if (this.userCart.entities.hasOwnProperty(entity)) {
                this.subtotal += this.userCart.entities[entity].orderItems.reduce((sum: number, item: any) => {
                    return sum + (item.itemPrice * item.quantity);
                }, 0.0);
            }
        }
    }

    getDripCartObject(email){
        // Currently limited to 1 order at a time because XION's backend does not have a cart object but rather single orders.
        let cart = this.getCart();
        let singleOrder;
        for(let entityId in cart.entities) {
            singleOrder = cart.entities[entityId];
            break;
        }
        if(!singleOrder) return;

        let items = [];
        for(let item of singleOrder.orderItems){
            items.push({
                "product_id": item.productID,
                "product_variant_id": item.productVariantID,
                "name": `${item.name+(item.modelYear?' '+item.modelYear:'')}`,
                "brand": item.brand,
                "categories": [
                    item.category,
                    item.subCategory,
                ],
                "price": Number(item.itemPrice),
                "quantity": Number(item.quantity),
                "product_url": "http://localhost:4200/product/"+item.seoName,
                "image_url": item.imageUrl,
            })
        }

        return {
            "provider": "XION",
            "email": email,
            "action": "created",
            "cart_id": singleOrder.orderId,
            "cart_url": "http://localhost:4200/cart/"+singleOrder.orderId,
            "items": items
        }
    }

}
