import {Component, Input, EventEmitter, Output} from '@angular/core';
import { OnInit } from '@angular/core';

@Component({
    selector: 'xion-hover-replace-remove',
    templateUrl: './hover-replace-remove.component.html',
    styleUrls: ['./hover-replace-remove.component.css'],
})
export class HoverReplaceRemoveComponent implements OnInit {
    @Input() displayText: string = 'Replace';
    @Input() showTrashCan: boolean = true;
    isHovered: boolean = false;
    @Output() itemReplace = new EventEmitter(); // TODO: Rename this to something like CenterButtonClicked
    @Output() itemRemove = new EventEmitter();

    constructor(){}

    emitReplace(e){
        this.itemReplace.emit('replace');
    }
    emitRemove(e){
        this.itemRemove.emit('remove');
        e.stopPropagation();
    }
    addText(){
        // console.log('mouseenter');
        this.isHovered = true;
    }
    removeText(){
        // console.log('mouseleave');
        this.isHovered = false;
    }
    ngOnInit() {
        // console.log('HoverReplaceRemoveComponent:onInit()');
    }

}
