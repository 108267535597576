import {Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import {GlobalEventsService} from "../../../global-events.service";

@Component({
    selector: 'xion-rotating-messages',
    templateUrl: './rotating-messages.component.html',
    styleUrls: ['./rotating-messages.component.css']
})
export class RotatingMessagesComponent implements OnInit, OnDestroy {

    @Input() messages: {
        message: string;
    }[];

    // In milliseconds
    @Input() rotateTime: number = 4000;

    @Output() messageClicked = new EventEmitter();

    private interval;
    index: number = 0;

    constructor(private ngZone: NgZone, private gesService: GlobalEventsService) { }

    /** Rotate the messages */
    /** runOutsideAngular fixes the protractor e2e test */
    ngOnInit() {
        if(this.gesService.isRunOnBrowser()){
            this.interval =
                this.ngZone.runOutsideAngular(() => {
                    setInterval(()=>{
                        this.ngZone.run(() => {
                            if(this.index >= this.messages.length-1){
                                this.index = 0;
                            }else{
                                this.index++;
                            }
                        });
                    }, this.rotateTime);
                });
        }
    }

    // stop rotating the messages
    ngOnDestroy(){
        if(this.interval){
            clearInterval(this.interval);
        }
    }

    onClick(index){
        this.messageClicked.emit(index);
    }
}
