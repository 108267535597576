import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ModalComponent} from "./modal.component";
import {ModalHeaderComponent} from "./modal-header/modal-header.component";
import {ModalFooterComponent} from "./modal-footer/modal-footer.component";
import {ModalBodyComponent} from "./modal-body/modal-body.component";
import {SimpleModalComponent} from "../simple-modal/simple-modal.component";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ModalComponent,
        ModalHeaderComponent,
        ModalFooterComponent,
        ModalBodyComponent,
        SimpleModalComponent,
    ],
    exports: [
        ModalComponent,
        ModalHeaderComponent,
        ModalFooterComponent,
        ModalBodyComponent,
        SimpleModalComponent,
    ]
})
export class ModalModule { }
