import { from as observableFrom, Subject, Observable } from 'rxjs';

import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { XRDService } from '../xrd/xrd.service';

@Component({
    selector: 'xion-email-subscription-form',
    template: `
        <form id="mailchimp-form" (submit)="submitEmail()" ngNativeValidate>
            <p class="sign-up" [class.sell-desc]="isSellPage">{{ descText }}</p>
            <p class="thank-you" *ngIf="submittedEmail">Thank You!</p>

            <mat-form-field *ngIf="!submittedEmail">
                <input
                    type="email"
                    matInput
                    [placeholder]="'Email'"
                    [(ngModel)]="mailchimpEmail"
                    name="mailchimpEmail"
                />
            </mat-form-field>
        </form>
    `,
    styles: [
        `
            :host ::ng-deep .mat-form-field-underline {
                display: none;
            }
            :host
                ::ng-deep
                .mat-form-field-can-float.mat-form-field-should-float
                .mat-form-field-label {
                transform: translateY(-0.5em) translateX(0.3em) scale(0.65)
                    perspective(100px) translateZ(0.001px);
            }
            #mailchimp-form {
                text-align: center;
            }
            #mailchimp-form mat-form-field {
                width: 360px;
                margin: -5px auto;
            }
            #mailchimp-form input {
                border-radius: 8px;
                background-color: rgba(245, 255, 246, 0.82);
                box-shadow: 4px 6px 20px 1px rgba(0, 0, 0, 0.3);
            }
            #mailchimp-form input,
            :host ::ng-deep .mat-form-field-label {
                padding: 25px 10px 17px 20px;
            }
            .sign-up {
                font-size: 17px;
                letter-spacing: 1.7px;
                width: 365px;
                margin: 0 auto;
                position: relative;
                font-weight: 200;
                top: 10px;
                color: white;
            }
            .thank-you {
                color: white;
                font-size: 25px;
                font-weight: 500;
                letter-spacing: 1.7px;
                width: 300px;
                margin: 24px auto;
            }
            .sell-desc {
                font-size: 28px;
                font-weight: 500;
                letter-spacing: 2.7px;
                margin-bottom: 12px;
            }

            @media only screen and (max-width: 700px) {
                .sign-up,
                .thank-you {
                    width: 90%;
                }
                #mailchimp-form mat-form-field {
                    width: 85%;
                }
                .sell-desc {
                    font-size: 24px;
                }
            }
        `,
    ],
})
export class EmailSubscriptionFormComponent implements OnInit, OnDestroy {
    @Input() descText: string = 'Sign Up For XION News, Sales, and Deals';
    @Input() isSellPage: boolean = false;
    @Input() subscriptionType: string = 'Newsletter';

    submittedEmail: boolean = false;
    mailchimpEmail: string;

    ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(private xrdService: XRDService) {}

    ngOnInit() {}

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    /**
     * If the email (username) already exists in our system, just create a subscription for it.
     * Else, create a contact (basically just a user without a password) then create a subscription
     */
    submitEmail() {
        if (!this.mailchimpEmail) return;

        // this.xrdService.user.createUserWithUsernameOnly(this.mailchimpEmail)
        //     .takeUntil(this.ngUnsubscribe.asObservable())
        //     .subscribe(res=>{
        //         this.submittedEmail = true;
        //     });

        this.xrdService.user
            .getUserByUsername(this.mailchimpEmail)
            .pipe(
                mergeMap((user) => {
                    if (user) {
                        return observableFrom([user.contactid]);
                    } else {
                        console.log(
                            'Creating contact by email: ' + this.mailchimpEmail
                        );
                        return this.xrdService.user
                            .createContact(this.mailchimpEmail)
                            .pipe(
                                map((contact) => {
                                    console.log('Contact created:', contact);
                                    return contact.contactid;
                                })
                            );
                    }
                }),
                mergeMap((contactId) => {
                    console.log(
                        'Creating contact subscription for contactid: ' +
                            contactId
                    );
                    return this.xrdService.user.createContactSubscription(
                        contactId,
                        this.subscriptionType
                    );
                }),
                takeUntil(this.ngUnsubscribe.asObservable())
            )
            .subscribe((res) => {
                console.log('Subscription created:', res);
                this.submittedEmail = true;
            });
    }
}
