import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EmailSubscriptionFormComponent} from "./email-subscription-form.component";
import {SharedModule} from "../shared.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        EmailSubscriptionFormComponent
    ],
    exports: [
        EmailSubscriptionFormComponent
    ]
})
export class EmailSubscriptionFormModule { }
