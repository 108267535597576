<a *ngFor="let product of homeService.promotedProducts"
   [routerLink]="product.seoname?'/product/'+product.seoname:''"
   class="col-1-6-and-half">

    <xion-product-card [product]="product"
                       [isLazyLoaded]="true"
                       [isScrollObserved]="true"
                       [scrollObservable]="homeService.horizontalScrollInitiated">
    </xion-product-card>
</a>
