import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {XRDEntityCategory} from "../../../xrd/entity/xrd-entity-category.model";
import {MarketplaceMainNavService} from "../../../../marketplace/marketplace-main-nav.service";
import * as _ from 'underscore';

@Component({
    selector: 'xion-main-header-nav-dropdown-menu',
    templateUrl: './main-header-nav-dropdown-menu.component.html',
    styleUrls: ['./main-header-nav-dropdown-menu.component.css']
})
export class MainHeaderNavDropdownMenuComponent implements OnInit {

    /** Used to ensure the buttons and ancor tags are not tab-able while the dropdown is hidden */
    @Input() isOpenFlag: boolean;

    /** Setter used to select first category initially */
    _categories: XRDEntityCategory;
    get categories(): XRDEntityCategory {
        return this._categories;
    }
    @Input('categories')
    set categories(value: XRDEntityCategory) {
        this._categories = value;
        if(this._categories 
            && !_.isEmpty(this._categories)
            && this._categories.departments.length 
            && this._categories.departments[0].categories.length){
            this.displayCategories(this._categories.departments[0].name, this._categories.departments[0].categories);
            this.displaySubCategories(this._categories.departments[0].categories[0].name, this._categories.departments[0].categories[0].subcategories);
        }
    }

    @Input() brands: string[];
    @Input() stores: any[];
    @Input() availableDealCategories: string[];

    /** Specific to the store dropdown component */
    @Input() isStorePage: boolean = false;
    @Input() storeSeoName: string;
    @Input() isStoreHomePage: boolean = false;

    /** Outputs */
    @Output() itemSelected = new EventEmitter();
    @Output() categorySelected = new EventEmitter();
    @Output() brandSelected = new EventEmitter();

    displayedCategories: { name:string; categoryid:string; subcategories:any[] }[];
    displayedSubCategories: { name:string; subcategoryid:string; }[];
    departmentBeingDisplayed: string;
    categoryBeingDisplayed: string;

    constructor(public marketplaceMainNavService: MarketplaceMainNavService) {}

    ngOnInit() {
        console.log(this.categories);
    }

    displayCategories(dep, cats){
        if(this.departmentBeingDisplayed !== dep){
            this.departmentBeingDisplayed = dep;
            this.displayedCategories = cats;
            if(cats.length && cats[0].subcategories.length){
                this.displaySubCategories(cats[0].name, cats[0].subcategories);
            }else{
                this.displayedSubCategories = [];
            }
        }
    }

    displaySubCategories(cat, subCats){
        if(this.categoryBeingDisplayed !== cat){
            this.categoryBeingDisplayed = cat;
            this.displayedSubCategories = subCats;
        }
    }
}
