import './polyfills.ts';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import {AppModule} from "./app/app.module";

if (environment.production) {
  enableProdMode();
}

// platformBrowserDynamic().bootstrapModule(AppModule)
//     .catch(err => console.log(err));

// platformBrowserDynamic().bootstrapModule(AppModule);
document.addEventListener('DOMContentLoaded', () => {
    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.log(err));
});

// window.document.addEventListener('PrebootComplete', () => {
//     // put your code here that you want to run once preboot is complete
//     console.log('preboot just finished');
// });
