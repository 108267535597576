export class FadeOutNotification {
    displayText: string;
    timeLength: number;
    theme: string;

    constructor(displayText, theme?, timeLength?){
        this.displayText = displayText;
        this.theme = theme ? theme : '';
        this.timeLength = timeLength ? timeLength : 3000;
    };
}
