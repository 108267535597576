import { Component, OnInit } from '@angular/core';
import {XRDService} from "../../../shared/xrd/xrd.service";
import {XRDProduct} from "../../../shared/xrd/product/xrd-product.model";
import {XRDProductSearchResult} from "../../../shared/xrd/search/xrd-product-search-result.model";
import {HomeService} from "../home.service";

@Component({
    selector: 'xion-home-local-deals',
    templateUrl: './home-local-deals.component.html',
    styleUrls: ['./home-local-deals.component.css']
})
export class HomeLocalDealsComponent implements OnInit {

    constructor(public homeService: HomeService) { }

    ngOnInit() {
        if(!this.homeService.isDealProductsLoaded){
            this.homeService.getDealProducts();
        }
    }
}
