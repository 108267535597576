<div id="main">
    <div class="box-img">
        <div class="box-img-container">
            <!--DEFAULT-->
            <img *ngIf="!isLazyLoaded" [src]="product?.primaryimage || 'assets/img/default_product_image1.png'" alt="product image">
            <!--LAZY LOAD-->
            <img *ngIf="isLazyLoaded && !isScrollObserved"
                 [lazyLoad]="product?.primaryimage"
                 [offset]="100"
                 src="assets/img/default_product_image1.png"
                 alt="product image">
            <img *ngIf="isLazyLoaded && isScrollObserved"
                 [lazyLoad]="product?.primaryimage"
                 [offset]="100"
                 [scrollObservable]="scrollObservable"
                 src="assets/img/default_product_image1.png"
                 alt="product image">
        </div>
    </div>
    <p class="hyphenate">{{ product?.brand }}</p>
    <h5 class="hyphenate">{{ product?.name }} {{ product?.modelyear }}</h5>
    <div id="price-container">
        <!-- +.01 to allow one cent difference (499.99 instead of 500.00) -->
        <p class="price" *ngIf="msrp && ((price?price:-1)+.01 < msrp)"
           [class.red-strike-through]="price && (price+.01 < msrp)">
            {{ msrp | currency:'USD'}}
        </p>
        <p class="price" *ngIf="price">
            {{ price | currency:'USD'}}
        </p>
    </div>
</div>
