import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { XRDService } from '../../shared/xrd/xrd.service';
import { XRDProductSearchResult } from '../../shared/xrd/search/xrd-product-search-result.model';
import { GlobalEventsService } from '../../shared/global-events.service';

@Injectable()
export class HomeService {
    /** triggers images to load */
    horizontalScrollInitiated = new Subject();

    backgroundImage: string;

    numberOfProducts: number = 20;

    /** promoted products */
    isPromotedProductsLoaded = false;
    promotedProducts = [];

    /** deals products */
    isDealProductsLoaded = false;
    dealProducts = [];

    constructor(
        private xrdService: XRDService,
        private gesService: GlobalEventsService
    ) {
        // Placeholders
        this.promotedProducts = [{}, {}, {}, {}, {}, {}];
        this.dealProducts = [{}, {}, {}, {}, {}, {}];
    }

    setBannerImage() {
        this.xrdService.entity
            .getEntityById('1')
            .pipe(
                map((entity) => {
                    return entity.background;
                })
            )
            .subscribe(
                (backgroundImage) => {
                    this.backgroundImage = backgroundImage;
                    // TODO: maybe fix this because @ngx-meta/core is deprecated
                    // this.meta.setTag('og:image', this.backgroundImage);
                    // this.changeDetectorRef.detectChanges();
                },
                (error) => {
                    console.log('error', error);
                }
            );
    }

    setPromotedProducts() {
        if (!this.gesService.isRunOnBrowser()) return;

        this.xrdService.entity
            .getEntityPromotedProducts('1', this.numberOfProducts)
            .subscribe((res) => {
                this.promotedProducts = res;
                this.isPromotedProductsLoaded = true;
            });
    }

    getDealProducts() {
        if (!this.gesService.isRunOnBrowser()) return;

        this.xrdService.search
            .dealsSearch({}, 0, this.numberOfProducts)
            .pipe(
                map((data) => {
                    let results = [];
                    // Temporary conversion until Robert returns regular XRDProduct entries instead of XRDProductSearchResult entries.
                    data.deals.forEach((item: XRDProductSearchResult) => {
                        results.push(XRDProductSearchResult.toXRDProduct(item));
                    });
                    return results;
                })
            )
            .subscribe((res) => {
                this.dealProducts = res;
                this.isDealProductsLoaded = true;
            });
    }
}
