import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {GlobalEventsService} from "../global-events.service";

@Component({
    selector: 'xion-modal',
    templateUrl: './modal.component.html',
    styleUrls: [ './modal.component.css' ],
})
export class ModalComponent implements OnInit {

    // This modal defaults to a light theme, but can be set to dark theme with this input.
    @Input() darkTheme: boolean = false;

    // This modal defaults to absolute positioning at the top of the page, but can be set to a relative positioning with
    // this input.
    @Input() position: string = 'absolute';

    // The default is to show a darkened overlay when the modal is shown, but you can disable this feature with this
    // input.
    @Input() darkOverlay: boolean = true;

    @Input() boxShadow: boolean = false;

    // Width of the modal. Defaults to 500px.
    @Input() modalWidth: number = 500;

    // Max-width of modal in percent
    @Input() modalMaxWidth: number = 100;

    // top of modal. Defaults to 50px.
    @Input() modalTop: any = 50;

    // top of modal. Defaults to auto, which is nothing. Must input a number.
    @Input() modalBottom: any = 'nothing';

    // Disable the ability to close the modal
    @Input() closeDisabled: boolean = false;

    // This modal defaults to having a 1px solid border around it for effect. Make false to disable.
    @Input() border:boolean = true;

    @Input() isShowingObj = {
        value: false
    };

    @Input() containerRelative: boolean = false;

    @Input() verticalCenter: boolean = false;

    @Input() scrollToOnOpen: boolean = true;

    @Input() bottomless: boolean = false;

    @Output() justClosed = new EventEmitter();

    // Need this so children components can inject me.
    name = 'modal';

    isShowing: boolean = false;

    constructor(private globalEventsService: GlobalEventsService,
                private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit() {
        // console.log('ModalComponent:ngOnInit()');
    }

    public open() {
        this.isShowing = true;
        this.isShowingObj.value = true;
        // Scroll to the modal if it's not in view
        if(this.scrollToOnOpen){
            this.changeDetectorRef.detectChanges();
            this.globalEventsService.goTo('xion-modal-scroll-to');
        }
    }

    public close() {
        if(!this.closeDisabled) {
            this.isShowing = false;
            this.isShowingObj.value = false;
            this.justClosed.emit(true);
        }
    }

    getStyle(){
        if(this.darkTheme){
            return 'twilight';
        }
    }

    getPosition(){
        if(this.position == 'relative'){
            return 'relative-container';
        }
    }
}
