<div class="non-mobile">
    <div class="relative non-mobile-btn-container">
        <a class="main-btn"
           [class.above-main-overlay]="mainDropdownIsOpen"
           [class.hover]="mainDropdownIsOpen"
           (mouseenter)="showDropDown()"
           (mouseleave)="cancelShowDropDown()"
           (click)="hideDropDown()"
           [routerLink]="['/shop-all']">
            Departments
            <img class="down-arrow" src="/assets/img/svg/down_arrow.svg">
        </a>
        <div *ngIf="mainDropdownIsOpen">
            <div class="main-btn" style="opacity: 0">Shop</div>
            <div id="main-btn-hover-cover">
                <div></div>
                <div></div>
            </div>
        </div>
</div>
    <xion-main-header-nav-dropdown-menu
        [categories]="marketplaceMainNavService.categories"
        [brands]="marketplaceMainNavService.brands"
        [stores]="marketplaceMainNavService.stores"
        [availableDealCategories]="marketplaceMainNavService.availableDealCategories"
        (categorySelected)="marketplaceMainNavService.categorySelected()"
        (brandSelected)="marketplaceMainNavService.brandSelected()"
        (itemSelected)="mainDropdownIsOpen = false;"
        [class.show-me]="mainDropdownIsOpen"
        [isOpenFlag]="mainDropdownIsOpen">
    </xion-main-header-nav-dropdown-menu>
    <div class="main-overlay" *ngIf="mainDropdownIsOpen" (mouseenter)="hideDropDown()"></div>
</div>

<div class="mobile">
    <button class="main-btn" (click)="toggleMainDropdown()">
        Departments
        <img class="down-arrow" src="/assets/img/svg/down_arrow.svg">
    </button>
    <xion-main-header-nav-dropdown-mobile-menu
        [categories]="marketplaceMainNavService.categories"
        [brands]="marketplaceMainNavService.brands"
        [extraItems]="extraItems"
        (categorySelected)="marketplaceMainNavService.categorySelected()"
        (brandSelected)="marketplaceMainNavService.brandSelected()"
        [storeName]="'Departments'"
        [isStorePage]="false"
        [isMainMobile]="true"
        [isStoreHomePage]="true"
        (itemSelected)="hideDropDown()"
        [class.show-me]="mainDropdownIsOpen"
        [isOpenFlag]="mainDropdownIsOpen">
    </xion-main-header-nav-dropdown-mobile-menu>
</div>
