import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { CartService } from '../marketplace/cart/cart-service/cart.service';
import * as _ from 'underscore';
import { GlobalEventsService } from './global-events.service';
import { FadeOutNotificationService } from './fade-out-notification-stack/fade-out-notification/fade-out-notification.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

@Injectable()
export class SessionCartGuard implements CanActivate {
    constructor(
        private router: Router,
        private cartService: CartService,
        private fonService: FadeOutNotificationService,
        private gesService: GlobalEventsService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        let url: string = state.url;
        let cart = this.cartService.getCart();

        if (_.isEmpty(cart)) {
            console.log('Nothing in cart. You are not allowed to come here.');
            // Catch for server-side not having anything in localStorage
            if (!this.gesService.isRunOnBrowser()) {
                console.log('route-initializing url:', url);
                this.router.navigate(['/route-initializing', { goingTo: url }]);
                return false;
            } else {
                if (route.queryParams['orderId']) {
                    return this.loadOneOffItemToCheckout(
                        route.queryParams['orderId']
                    );
                } else {
                    this.sendToDealsPage();
                    return false;
                }
            }
        } else {
            if (route.queryParams['orderId']) {
                return this.loadOneOffItemToCheckout(
                    route.queryParams['orderId']
                );
            } else {
                return true;
            }
        }
    }

    private loadOneOffItemToCheckout(orderId) {
        return this.cartService.addOneOffOrder(orderId).pipe(
            catchError((error) => {
                this.sendToDealsPage();
                return of(false);
            })
        ); // catches when there is no product item on the order (in the case of user removing item before they abandon)
    }

    private sendToDealsPage() {
        this.fonService.addNotification(
            `Nothing in cart, please keep shopping ;)`,
            '',
            5000
        );
        this.router.navigate(['/deals'], { queryParams: { query: '' } });
    }
}
