// This file includes polyfills needed by Angular 2 and is loaded before
// the app. You can add your own extra polyfills to this file.
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/reflect';

import 'core-js/es7/reflect';
import 'zone.js';

// Fix number pipes
// import 'intl';
// import 'intl/locale-data/jsonp/en';

// Fix for geoLocation

// Smoothscrolling when using .scrollIntoView({ behavior: 'smooth' });
// TODO: maybe uncomment once fixed
// import smoothscroll from 'smoothscroll-polyfill/dist/smoothscroll';
// smoothscroll.polyfill();

// Lazy-loading images
import 'intersection-observer';

// Animations across all browsers
// TODO: maybe uncomment once fixed
// import 'web-animations-js/web-animations.min.js';
