<!--used to scrollTo top of page-->
<div id="top-of-page"></div>

<xion-modal #mainModal
            (justClosed)="stopObservable()"
            [modalWidth]="559"
            [darkOverlay]="false"
            [boxShadow]="true"
            [scrollToOnOpen]="false"
            [closeDisabled]="isCloseDisabled"
            [position]="'relative'"
            [bottomless]="true">

    <button id="cancel" class="no-style-btn" (click)="closeThisThang()">Cancel</button>

    <h2>Session Timed Out</h2>
    <h3>Sign in to continue</h3>

    <div class="sign-in-error" *ngIf="hasError">{{ errorMessage }}</div>

    <form ngNativeValidate (submit)="signIn()">
        <mat-form-field>
            <input matInput type="email" required name="username" placeholder="Email" [(ngModel)]="username">
        </mat-form-field>
        <mat-form-field>
            <input #passwordInput matInput type="password" required name="password" placeholder="Password" [(ngModel)]="password">
        </mat-form-field>

        <xion-loader [isLoading]="isLoading"></xion-loader>
        <button class="sign-in-button" *ngIf="!isLoading">
            Sign In
        </button>
    </form>
</xion-modal>
