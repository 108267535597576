import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MainHeaderNavDropdownMenuComponent} from "./main-header-nav-dropdown-menu.component";
import {SharedModule} from "../../../shared.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        MainHeaderNavDropdownMenuComponent,
    ],
    exports: [
        MainHeaderNavDropdownMenuComponent,
    ]
})
export class MainHeaderNavDropdownMenuModule { }
