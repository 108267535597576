import { XRDProductImage } from './../../../shared/xrd/product/xrd-product-image.class';
import {Inject, Injectable, Renderer2} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {XRDProduct, XRDProductVariant} from "../../../shared/xrd/product";
import { XRDEntityProduct } from '../../../shared/xrd/entity/xrd-entity-product.model';
import * as _ from 'underscore';
import { XRDSearchProductVariant } from '../../../shared/xrd/search/xrd-search-product-variant.model';

@Injectable()
export class GoogleStructuredDataService {

    /** Initialized in app.component.ts */
    renderer:any;
    /** The scripts */
    structuredDataScripts = {};

    constructor(@Inject(DOCUMENT) public document: any) {}

    /**
     * Add the Google structured data script so Google can display things nicely
     * Source: https://developers.google.com/search/docs/guides/intro-structured-data
     */
    createScript(key:string, scriptText?:any): void{

        // Create blank script element
        this.structuredDataScripts[key] = this.renderer.createElement('script');
        this.structuredDataScripts[key].type = `application/ld+json`;
        // Set content
        switch(key) {
            case 'Main Search':
                this.structuredDataScripts[key].text = `
                    {
                      "@context": "http://schema.org",
                      "@type": "WebSite",
                      "url": "https://xion.store",
                      "potentialAction": [{
                        "@type": "SearchAction",
                        "target": "https://xion.store/search;query={search_term_string}",
                        "query-input": "required name=search_term_string"
                      }]
                    }
                `;
                break;
            case 'Product':
                this.structuredDataScripts[key].text = scriptText;
                break;
            case 'Business Contact':
                // Phone number belongs to XION with Google Voice
                this.structuredDataScripts[key].text = `
                    {
                      "@context": "http://schema.org",
                      "@type": "Organization",
                      "name": "XION",
                      "url": "https://xion.store",
                      "logo": "https://xion.store/assets/img/new_logos/XION_dot_store_logo_blue[small].jpg",
                      "contactPoint": [{
                        "@type": "ContactPoint",
                        "telephone": "+1-435-922-0046",
                        "contactType": "customer service"
                      }],
                      "sameAs": [
                        "https://www.facebook.com/shopxion",
                        "https://twitter.com/XIONretail",
                        "https://www.instagram.com/xion.store",
                        "https://www.youtube.com/channel/UCb_lGDnFCF8v6p7c7-glGgg",
                        "https://www.linkedin.com/company/xion-inc-",
                        "https://www.pinterest.com/xionstore"
                      ]
                    }
                `;
                break;
        }
        // Append to page
        this.renderer.appendChild(this.document.body, this.structuredDataScripts[key]);
    }

    // TODO: eventually, we should add rating and other data like UPC.
    createProductScript(product:XRDProduct | XRDEntityProduct, images:XRDProductImage[], lowPrice, highPrice, variant: XRDProductVariant, stores: XRDSearchProductVariant[]){

        let imageUrls = [];
        for(let image of images){
            imageUrls.push(image.path);
        };

        let offers = [];
        let offerCount = 0;
        for(let store of stores){
            offers.push({
                "@type": "Offer",
                "availability": store.quantity?'InStock':'OutOfStock',
                "inventoryLevel": String(store.quantity),
                "price": String(store.price),
                "priceCurrency": "USD",
                "offeredBy": {
                    "@type": "Organization",
                    "name": store.name
                },
            })
            offerCount++;
        }

        // TODO: add in aggregateRating when there are enouogh ratings
        let prodScript = {
            "@context": "http://schema.org/",
            "@type": "Product",
            "name": `${product.brand} ${product.name}${product.modelyear?' '+product.modelyear:''}`,
            "sku": variant.productvariantid,
            "itemCondition": "NewCondition",
            "image": imageUrls,
            "description": product.description,
            "brand": {
              "@type": "Thing",
              "name": product.brand
            },
            "offers": {
              "@type": "AggregateOffer",
              "lowPrice": String(lowPrice),
              "highPrice": String(highPrice),
              "availability": variant.quantity?'InStock':'OutOfStock',
              "inventoryLevel": String(variant.quantity),
              "priceCurrency": "USD",
              "offerCount": String(offerCount),
              "offers": offers,
            }
        };

        if(variant.upc) prodScript["gtin12"] = variant.upc;
        if(variant.mpn) prodScript["mpn"] = variant.mpn;

        this.createScript('Product', JSON.stringify(prodScript));
    }

    createGoogleShoppingAdScript(orderGrandTotal){

        // The Data
        this.structuredDataScripts['googleShoppingAd'] = this.renderer.createElement('script');
        this.structuredDataScripts['googleShoppingAd'].type = `text/javascript`;
        this.structuredDataScripts['googleShoppingAd'].text = `
                /* <![CDATA[ */
                var google_conversion_id = 868305243;
                var google_conversion_language = "en";
                var google_conversion_format = "3";
                var google_conversion_color = "ffffff";
                var google_conversion_label = "gcbmCNiql3EQ25KFngM";
                var google_conversion_value = ${orderGrandTotal};
                var google_conversion_currency = "USD";
                var google_remarketing_only = false;
                /* ]]> */
            `;
        this.renderer.appendChild(this.document.body, this.structuredDataScripts['googleShoppingAd']);

        // The Source
        this.structuredDataScripts['googleShoppingAdSrcScript'] = this.renderer.createElement('script');
        this.structuredDataScripts['googleShoppingAdSrcScript'].type = `text/javascript`;
        this.structuredDataScripts['googleShoppingAdSrcScript'].src = `//www.googleadservices.com/pagead/conversion.js`;
        this.renderer.appendChild(this.document.body, this.structuredDataScripts['googleShoppingAdSrcScript']);

        // The NoScript (I don't know if this is working, but to even get to this page, the user has to have javascript enabled so it shouldn't matter)
        this.structuredDataScripts['googleShoppingAdNoScript'] = this.renderer.createElement('noscript');
        this.structuredDataScripts['googleShoppingAdNoScript'].innerHTML = `
                <div style="display:inline;">
                <img height="1" width="1" style="border-style:none;" alt=""
                src="//www.googleadservices.com/pagead/conversion/868305243/?value=${orderGrandTotal}&amp;currency_code=USD&amp;label=gcbmCNiql3EQ25KFngM&amp;guid=ON&amp;script=0"/>
                </div>
            `;
        this.renderer.appendChild(this.document.body, this.structuredDataScripts['googleShoppingAdNoScript']);
    }

    removeGoogleShoppingAdScript(){
        if(this.structuredDataScripts['googleShoppingAd']){
            this.renderer.removeChild(this.document.body, this.structuredDataScripts['googleShoppingAd']);
        }
        if(this.structuredDataScripts['googleShoppingAdSrcScript']){
            this.renderer.removeChild(this.document.body, this.structuredDataScripts['googleShoppingAdSrcScript']);
        }
        if(this.structuredDataScripts['googleShoppingAdNoScript']){
            this.renderer.removeChild(this.document.body, this.structuredDataScripts['googleShoppingAdNoScript']);
        }
    }

    /**
     * Remove the script (usually onDestroy of component)
     */
    removeScript(key:string): void{
        if(this.structuredDataScripts[key]){
            this.renderer.removeChild(this.document.body, this.structuredDataScripts[key]);
            delete this.structuredDataScripts[key];
        }
    }
}
