export class XRDPhone {
  "phoneid": string;
  "phonenumber": string;
  "phonetypeid": string;
  "phonetype": string;

  constructor(){
    this.phoneid = '';
    this.phonenumber = '';
    this.phonetypeid = '';
    this.phonetype = '';
  }
}
