<div class="shadowed"
     [style.top.px]="top"
     [style.right.px]="right"
     [style.display]="showPopover.value ? 'block' : 'none'">

    <div *ngIf="hasLilCarrot" class="lil-carrot">▾</div>

    <ng-content>

    </ng-content>
</div>
