
import {forkJoin as observableForkJoin, from as observableFrom, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import {XRDService} from "../../shared/xrd/xrd.service";
import {GlobalEventsService} from "../../shared/global-events.service";
import * as _ from 'underscore';

@Injectable()
export class EntityBySEONameResolver implements Resolve<any> {

    constructor(private xrdService: XRDService, private router: Router,
                private globalEventsService: GlobalEventsService) {}

    /**
     * Must get entity, entity categories, and entity brands for the meta tags (title and description)
     */
    resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>|Promise<any>|any {
        this.globalEventsService.showProgressBar = true;
        // For some reason this is null when server side rendered... weird
        let entitySeoName = route.params['seoName'];
        let localEntity;
        return this.xrdService.entity.getEntityBySeoName(entitySeoName).pipe( 
            switchMap(entity=>{
                // console.log('entity-seoname-resolver res', entity);
                if(_.isEmpty(entity) && this.globalEventsService.isRunOnBrowser()){
                    this.router.navigate(['/404']);
                    this.globalEventsService.showProgressBar = false;
                    return observableFrom(['404']);
                }else if(_.isEmpty(entity) && !this.globalEventsService.isRunOnBrowser()){
                    let url = '/online-store';
                    console.log('route-initializing url:', url);
                    this.router.navigate(['/route-initializing', {'goingTo':url} ]);
                    return observableFrom(['route-initializing']);
                }
                localEntity = entity;
                let observables = [];
                observables.push(this.xrdService.entity.getEntityProductCategoryList(localEntity.entityid));
                observables.push(this.xrdService.entity.getEntityBrandList(localEntity.entityid));
                return observableForkJoin(...observables);
            }),
            map(finalRes=>{
                this.globalEventsService.showProgressBar = false;
                return {
                    'entity': localEntity,
                    'categories': finalRes[0],
                    'allBrands': finalRes[1],
                };
            }));
    }
}
