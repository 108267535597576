import { XRDAddress, XRDShallowAddress, XRDEmail, XRDShallowEmail, XRDPhone, XRDShallowPhone } from '../shared';

export class XRDEntity {
    id: string;
    entityid: string;

    parententityid: string;
    parentseoname: string;

    name: string;
    officialname: string;
    seoname: string;
    keywords: string;
    description: string;
    logo: string;

    // JSON blob:
    hours: {
        Hours: any;
        Services: string;
        AboutUs: string;
        Policy: string;
        ExternalLinks: {
            name: string;
            url: string;
        }[];
    } | any;

    maxdeliverydistance: string;
    distance: string;
    background: string;
    publishstatus: string;
    theme: string;
    entitystatus: string;
    entitytype: string;
    reviewurl: string;

    addresses: XRDAddress[];
    // deepaddresses: XRDAddress[];

    emails: XRDEmail[];
    // deepemails: XRDEmail[];

    phones: XRDPhone[];
    // deepphones: XRDPhone[];

    images: Object[];
    links: Object[];
    // accounts: Object[];
    // contacts: Object[];
}

