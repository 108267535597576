import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HoverReplaceRemoveComponent} from "./hover-replace-remove.component";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        HoverReplaceRemoveComponent
    ],
    exports: [
        HoverReplaceRemoveComponent
    ]
})
export class HoverReplaceRemoveModule { }
