<div class="flex-my-children">
    <a [routerLink]="['stores']" class="no-margin">
        <xion-user-action-box [title]="'Shop Local'"
                              [description]="'Find local retailers closest to you'"
                              [image]="'/assets/img/homepage/Shop_Local_Background.jpg'">
        </xion-user-action-box>
    </a>
    <a [routerLink]="['about-us']">
        <xion-user-action-box [title]="'Learn'"
                              [description]="'What is XION all about?'"
                              [image]="'/assets/img/homepage/Learn_Background.jpg'">
        </xion-user-action-box>
    </a>
    <a [routerLink]="['deals']">
        <xion-user-action-box [title]="'Deals'"
                              [description]="'Great deals on great gear'"
                              [image]="'/assets/img/homepage/Deals_Background_2.jpg'">
        </xion-user-action-box>
    </a>
</div>
