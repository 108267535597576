import { UserIdentityService } from './shared/user-identity.service';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import {SessionService} from "./shared/session-service/session.service.interface";
import {SESSION_SERVICE_TOKEN} from "./shared/session-service/index";
import {NavigationEnd, Router} from "@angular/router";
import {HttpLocalClient} from "./http-client.service";
import {AuthGuard} from "./auth-guard.service";
import {FadeOutNotificationService} from "./shared/fade-out-notification-stack/fade-out-notification/fade-out-notification.service";
import {GlobalEventsService} from "./shared/global-events.service";
import {GoogleStructuredDataService} from "./marketplace/shared/google-structured-data/google-structured-data.service";
import {ThirdPartySessionTrackingService} from "./marketplace/shared/third-party-session-tracking/third-party-session-tracking.service";

declare var document:any;

@Component({
  selector: 'xion-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

    showMenu: boolean = false;
    private sessionService: SessionService;

    /** Used for the sign-in/sign-up redirect */
    private lastRoute: string;

    constructor(public globalEventsService: GlobalEventsService,
                @Inject(SESSION_SERVICE_TOKEN) sessionService: SessionService,
                public fadeOutNotificationService: FadeOutNotificationService,
                private authGuard: AuthGuard,
                renderer2: Renderer2,
                private gsdService: GoogleStructuredDataService,
                private tpstService: ThirdPartySessionTrackingService,
                private uidService: UserIdentityService,
                private router: Router,
                private httpClient: HttpLocalClient) {
        this.gsdService.renderer = renderer2;
        this.sessionService = sessionService;
        this.globalEventsService.inAdminSide = false;
    }

    ngOnInit() {
        if(!this.globalEventsService.isRunOnBrowser()){
            this.gsdService.createScript('Main Search');
            this.gsdService.createScript('Business Contact');
        }else{
            // Source: https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser/9851769
            // Internet Explorer 6-11
            let isIE = /*@cc_on!@*/false || !!document.documentMode;
            if(isIE){
                this.fadeOutNotificationService.addNotification('This website does not support Internet Explorer. Please use a different browser :)', 'warning', 100000);
            }
        }

        // Tracking for 3rd party apps
        if(this.sessionService.isUserSignedIn()){
            this.uidService.setUserSession(
                this.sessionService.getUserFirstName(),
                this.sessionService.getUserLastName(),
                this.sessionService.getUserUsername(),
                this.sessionService.getUserContactId(),
            );
        }else if(this.globalEventsService.isRunOnBrowser()){
            this.uidService.setVisitorSession();
        }

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }

            // Google Analytics tracking on page change
            this.tpstService.trackPageChange(evt.urlAfterRedirects);

            // On navigation to any non-search page, remove main search text and category
            if(!evt.url.includes('/search')){
                this.globalEventsService.mainSearchQuery = '';
                this.globalEventsService.mainSearchFilter = 'All';
            }
            // save the redirectUrl unless authGuard already picked it up
            if((evt.url.includes('/sign-in') || evt.url.includes('/create-account')) &&
                !this.authGuard.usingAuthGuardUrl){
                this.authGuard.redirectUrl = this.lastRoute;
            }
            // Save route change in case user goes to login or create-account screen
            if(!evt.url.includes('/sign-in') && !evt.url.includes('/create-account')){
                this.lastRoute = evt.url;
            }
        });
    }
}
