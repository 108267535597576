import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FadeOutModule} from "./fade-out-notification-stack/fade-out-notifications.module";
import {RouterModule} from "@angular/router";
import {CustomNgMaterialsModule} from "./custom-ng-materials/custom-ng-materials.module";
import {ModalModule} from "./modal/modal.module";
import {ValuesPipeModule} from "./pipes/values-pipe.module";
import {LoaderModule} from "./loader/loader.module";
import {LazyLoadImageModule} from "ng-lazyload-image";
import {ProductCardComponent} from "./product-card/product-card.component";
import {ModalPositionRelativeComponent} from "./modal-position-relative/modal-position-relative.component";
import {ImportantLoaderComponent} from "./important-loader/important-loader.component";
import { MaxHeightScrollApplierDirective } from '../marketplace/shared/max-height-scroll-applier.directive';
import { TalkjsBubblePromptComponent } from './talkjs-bubble-prompt/talkjs-bubble-prompt.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CustomNgMaterialsModule,
        LoaderModule,
        ModalModule,
        FadeOutModule,
        ValuesPipeModule,
        LazyLoadImageModule,
    ],
    declarations: [
        ProductCardComponent,
        ImportantLoaderComponent,
        ModalPositionRelativeComponent,
        MaxHeightScrollApplierDirective,
        TalkjsBubblePromptComponent,
    ],
    exports: [
        // imports
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CustomNgMaterialsModule,
        LoaderModule,
        ModalModule,
        FadeOutModule,
        ValuesPipeModule,
        LazyLoadImageModule,

        // declarations
        ProductCardComponent,
        ImportantLoaderComponent,
        ModalPositionRelativeComponent,
        MaxHeightScrollApplierDirective,
        TalkjsBubblePromptComponent,
    ]
})
export class SharedModule { }
