import {Injectable, Inject} from '@angular/core';
import {Observable} from "rxjs";
import {flatMap} from "rxjs/operators";
import {SessionService} from "../../shared/session-service/session.service.interface";
import {SESSION_SERVICE_TOKEN} from "../../shared/session-service/index";
import {CartService} from "../cart/cart-service/cart.service";
import {XRDService} from "../../shared/xrd/xrd.service";
import {Router} from "@angular/router";
import {WindowRef} from "../../WindowRef";
import {ThirdPartySessionTrackingService} from "../shared/third-party-session-tracking/third-party-session-tracking.service";

@Injectable()
export class AuthenticationService {

    private sessionService: SessionService;

    constructor(@Inject(SESSION_SERVICE_TOKEN) sessionService: SessionService,
                private winRef: WindowRef,
                private tpstService: ThirdPartySessionTrackingService,
                private cartService: CartService, private xrdService: XRDService,
                private router: Router) {
        this.sessionService = sessionService;
    }

    signIn(username: string, password: string): Observable<any> {
        return this.sessionService.signIn(username, password)
            // .flatMap(()=>{
            //     return this.cartService.syncLocalCartWithBackendUserOrder();
            // });
    }

    mockSignInUsingSession(session, contactId): Observable<any>{
        return this.sessionService.signInWithSession(session, contactId);
    }

    signUp(username: string, password: string, firstName: string, lastName: string): Observable<any> {
        let contactId;
        return this.xrdService.user.create(username, password, firstName, lastName).pipe(
            flatMap( users => {
                contactId = users[0].contactid;
                this.tpstService.completeRegistration();
                console.log('Got user from creation: ', users[0]);
                console.log('Sign UP successful. Attempting to sign IN...');
                return this.sessionService.signIn(username, password);
            }),
            flatMap(user => {
                console.log('5');
                console.log('sign IN successful', user);
                console.log('creating email for user...');
                // return Observable.from(['Hi'])
                return this.xrdService.user.addEmail(contactId, username, 'Home');
            }),
            flatMap(()=>{
                console.log('email created!');
                console.log('loading deep user stuff...');
                // load up the deep email you just created.
                return this.sessionService.loadUserDeep(contactId);
            }));
    }

    signOut(){
        this.sessionService.signOut();
        this.cartService.deleteCart();
        this.winRef.nativeWindow.location.reload();
        // this.router.navigate(['/']);
        // this.router.navigateByUrl(this.router.url+'?refresh=1');
    }
}
