export class XRDProductImageLink {
    "imageid": string;
    "rel": string;
    "href": string;

    constructor(imageID:string, rel:string, href:string){
        this.imageid = imageID;
        this.rel = rel;
        this.href = href;
    }
}
