import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'xion-expired-session',
    template: `        
        <router-outlet></router-outlet>
    `,
    styles: []
})
export class ExpiredSessionComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }
}
