import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {XRDEntity} from "../xrd/entity/xrd-entity.model";
import {GeoLocationService} from "../geo-location.service";

@Component({
    selector: 'xion-store-card',
    templateUrl: './store-card.component.html',
    styleUrls: ['./store-card.component.css']
})
export class StoreCardComponent implements OnInit {

    @Input() entity: XRDEntity;
    @Input() isLazyLoaded: boolean = false;
    @Input() scrollObservable: any;

    @Output() isHovered = new EventEmitter<boolean>();

    isAcurateDistance: boolean = false;

    constructor(private geoLocationService: GeoLocationService) { }

    ngOnInit() {
        this.isAcurateDistance = this.geoLocationService.isLocationAcurate();
    }

}
