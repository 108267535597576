
import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import {Observable} from "rxjs";
import {ClientSessionTokenService} from "../session-service/client-session-token.service";

@Injectable()
export class SessionExpiredInterceptor implements HttpInterceptor {

    constructor(private clientSessionTokenService: ClientSessionTokenService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // do stuff with response if you want
                    // console.log('http response:', event);
                }
            },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    // if (err.status === 401) {
                    //     console.log('401 Kev...');
                    //     console.log('401 req...', req);
                    //     console.log('401 err...', err);
                    //     // redirect to the login route
                    //     // or show a modal
                    //     this.clientSessionTokenService.cacheRequest(req);
                    //     this.clientSessionTokenService.tryAgain();
                    // }
                }
            }));
    }
}
