import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class TalkjsBubblePromptService {

    isOpen: boolean = false;
    message: string;
    pic: string;
    openTimeout;
    closeTimeout;

    // Flag to only show once perSession (until they refresh the page)
    alreadyHappenedOnce: boolean = false;

    constructor(private router:Router) { }

    openAfter(miliseconds:number): void{
        if(this.router.url.includes('checkout')) return; // FIX because on desktop, the popup would appear on refresh of product -> checkoutmodal page
        this.openTimeout = setTimeout(()=>{
            // Only popup if there is a message and pic
            if(this.message && this.pic){
                this.isOpen = true;
                this.closeAfter(10000);
            }
        }, miliseconds)
    }

    private closeAfter(miliseconds:number): void{
        this.closeTimeout = setTimeout(()=>{
            this.alreadyHappenedOnce = true;
        }, miliseconds)
    }

    reset(){
        this.isOpen = false;
        this.message = undefined;
        this.pic = undefined;
        clearTimeout(this.openTimeout);
        clearTimeout(this.closeTimeout);
    }
}
