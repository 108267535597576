import {Component, OnInit, Input, HostListener, ViewChild} from '@angular/core';
import {HomeService} from "../home.service";

@Component({
    selector: 'xion-horizontal-scroller-wrapper',
    templateUrl: './horizontal-scroller-wrapper.component.html',
    styleUrls: ['./horizontal-scroller-wrapper.component.css']
})
export class HorizontalScrollerWrapperComponent implements OnInit {

    @ViewChild('scrollableContainer', { static: true }) scrollableContainer;
    @Input() padding: any;

    showLeft: boolean = false;
    showRight: boolean = true;

    constructor(public homeService: HomeService) { }

    ngOnInit() {
    }

    sayHi(){
        console.log('scrollableContainer:');
    }
}
