import {Component, OnInit, OnDestroy, ViewChild, Inject, PLATFORM_ID} from '@angular/core';
import * as _ from 'underscore';
import {HomeService} from "./home.service";
import {WindowRef} from "../../WindowRef";
import {DOCUMENT} from "@angular/common";
import {isPlatformBrowser} from "@angular/common";

@Component({
    selector: 'xion-home',
    templateUrl: './home.component.html',
    styleUrls: [ './home.component.css' ]
})
export class HomeComponent implements OnInit, OnDestroy {

    @ViewChild('animationReferenceElement', { static: true }) animationReferenceElement;

    @ViewChild('pp', { static: true }) ppElement;
    @ViewChild('shops', { static: true }) shopsElement;
    @ViewChild('deals', { static: true }) dealsElement;

    sunTop: number;
    bikeTop: number;
    bikeLeft: number;
    bikeAngle: number = 0;
    showFace: boolean = false;

    submittedEmail: boolean = false;

    mailchimpEmail: string;

    backgroundImage: string;
    brandLogos: any = [
        {
            name: 'Sensus',
            imagePath: '/assets/img/brands/sensus_logo_grey.svg',
            routePath: '/SENSUS',
        },
        {
            name: 'Specialized',
            imagePath: '/assets/img/brands/Specialized_Logo_Grey.svg',
            routePath: '/brand/Specialized',
        },
        {
            name: 'Santa Cruz',
            imagePath: '/assets/img/brands/Santa_Cruz_Logo_Grey.svg',
            routePath: '/brand/Santa Cruz',
        },
        {
            name: 'BMC',
            imagePath: '/assets/img/brands/BMC_Logo_Grey.svg',
            routePath: '/brand/BMC',
        },
        {
            name: 'Felt',
            imagePath: '/assets/img/brands/Felt_Logo_Grey.svg',
            routePath: '/brand/Felt',
        },
        {
            name: 'Cannondale',
            imagePath: '/assets/img/brands/Cannondale_Logo_Grey.svg',
            routePath: '/brand/Cannondale',
        },
    ];

    ppIO: any;
    shopsIO: any;
    dealsIO: any;

    constructor(public homeService: HomeService,
                @Inject(PLATFORM_ID) protected platformId: Object,
                @Inject(DOCUMENT) private document: any,
                private winRef: WindowRef) {
        if(!this.homeService.backgroundImage){
            this.homeService.setBannerImage();
        }
    }

    ngOnInit() {
        // console.log('HomeComponent:ngOnInit()');
        if(isPlatformBrowser(this.platformId)) {
            this.listenToLoadImagesWhenInView();
        }
    }

    ngOnDestroy() {
        if(isPlatformBrowser(this.platformId)) {
            this.ppIO.disconnect();
            this.shopsIO.disconnect();
            this.dealsIO.disconnect();
        }
    }

    /**
     * Initiates the load of images in the viewport for lazy-loaded images on this page
     */
    listenToLoadImagesWhenInView(){
        // Promoted Products
        this.ppIO = new IntersectionObserver(
            () => {
                // console.log('Scrolled to PP. Initiating next()... ');
                this.homeService.horizontalScrollInitiated.next();
            }
        );
        this.ppIO.observe(this.ppElement.nativeElement);
        // Local Bike Shops
        this.shopsIO = new IntersectionObserver(
            () => {
                // console.log('Scrolled to SHOPS. Initiating next()... ');
                this.homeService.horizontalScrollInitiated.next();
            }
        );
        this.shopsIO.observe(this.shopsElement.nativeElement);
        // Deals
        this.dealsIO = new IntersectionObserver(
            () => {
                // console.log('Scrolled to DEALS. Initiating next()... ');
                this.homeService.horizontalScrollInitiated.next();
            }
        );
        this.dealsIO.observe(this.dealsElement.nativeElement);
    }

    animationThrottler = _.throttle(()=>{
                this.animateFunStuff();
            }, 5);
    /**
     * animate the sun and the bike :)
     */
    // @HostListener('window:scroll', ['$event'])
    // onScroll(event){
    //     this.animationThrottler();
    // }

    animateFunStuff():void {
        console.log('animate');
        let windowHeight = this.winRef.nativeWindow.innerHeight || this.document.body.clientHeight;

        // This is how JQuery does it.
        // Source: https://stackoverflow.com/questions/1145850/how-to-get-height-of-entire-document-with-javascript
        let body = this.document.body,
            html = this.document.documentElement;
        let height = Math.max( body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight );

        // Calc sun position
        let scrollTopForBottom = height - windowHeight;
        if(this.winRef.nativeWindow.scrollY >= scrollTopForBottom-700){ // offset from bottom
            // 255 is the offset to get it to start at 155 (behind mountains)
            // 3 is making the animation 3 times as slow as the scroll
            this.sunTop = (scrollTopForBottom - this.winRef.nativeWindow.scrollY - 255)/3;
        }else{
            this.sunTop = 155; // default position (behind mountains)
        }

        // Calc bike position
        if(this.winRef.nativeWindow.scrollY >= scrollTopForBottom-600) {
            this.bikeLeft = (scrollTopForBottom - this.winRef.nativeWindow.scrollY) + 856;
            if (this.bikeLeft >= 1172) {
                this.bikeAngle = -16;
                this.bikeTop = -(scrollTopForBottom - this.winRef.nativeWindow.scrollY) / 3 + 417;
            }else if(this.bikeLeft >= 1100){
                this.bikeAngle = -7;
            }else{
                this.bikeAngle = 0;
            }
            // this.bikeTop = 317;
        }else{
            this.bikeLeft = 856;
            this.bikeTop = 317;
        }

        // Show face
        if(this.winRef.nativeWindow.scrollY === scrollTopForBottom){
            this.showFace = true;
        }else{
            this.showFace = false;
        }

        // console.log(this.winRef.nativeWindow.scrollY >= scrollTopForBottom-130);
    }
}
