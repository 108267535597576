<!--If has parent entity other than XION entity, go to parent-->
<a [routerLink]="(entity.parententityid==='1')?['/'+entity.seoname]:['/'+entity.parentseoname]"
    [queryParams]="(entity.parententityid==='1')?{}:{storefront:entity.entityid}"
    (mouseenter)="isHovered.emit(true)"
    (mouseleave)="isHovered.emit(false)"
    class="store">
    <!--DEFAULT-->
    <div *ngIf="!isLazyLoaded" class="hero-container" [ngStyle]="{'background-image': 'url(' + entity.background + ')'}">
        <div class="gradient"></div>

        <span *ngIf="isAcurateDistance" class="miles-away">~{{ entity.distance }} mi</span>

        <div class="bottom-left">

            <div class="logo-container-container">
                <div class="logo-container">
                    <img class="logo" [src]="entity.logo" [alt]="entity.name"/>
                </div>
            </div>

            <div class="name-and-address">
                <h2>{{ entity.name }}</h2>
                <div *ngIf="entity?.addresses?.length">
                    <p>{{ entity.addresses[0].address1 }}</p>
                    <p>{{ entity.addresses[0].city }}, {{ entity.addresses[0].state }} {{ entity.addresses[0].zip }}</p>
                </div>
            </div>

        </div>

    </div>

    <!--LAZY LOADED-->
    <div *ngIf="isLazyLoaded" class="hero-container" [lazyLoad]="entity.background" [scrollObservable]="scrollObservable">
        <div class="gradient"></div>

        <span *ngIf="isAcurateDistance" class="miles-away">~{{ entity.distance }} mi</span>

        <div class="bottom-left">

            <div class="logo-container-container">
                <div class="logo-container">
                    <img class="logo" [lazyLoad]="entity.logo" [scrollObservable]="scrollObservable" src="assets/img/logo_default_image_01.png" [alt]="entity.name"/>
                </div>
            </div>

            <div class="name-and-address">
                <h2>{{ entity.name }}</h2>
                <div *ngIf="entity?.addresses?.length">
                    <p>{{ entity.addresses[0].address1 }}</p>
                    <p>{{ entity.addresses[0].city }}, {{ entity.addresses[0].state }} {{ entity.addresses[0].zip }}</p>
                </div>
            </div>

        </div>

    </div>
</a>
