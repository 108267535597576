import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {CartService} from "../../../marketplace/cart/cart-service/cart.service";
import {Router} from "@angular/router";
import {GlobalEventsService} from "../../global-events.service";
import {DOCUMENT} from "@angular/common";

@Component({
    selector: 'xion-main-header-cart',
    templateUrl: './main-header-cart.component.html',
    styleUrls: ['./main-header-cart.component.css']
})
export class MainHeaderCartComponent implements OnInit {

    maxContentHeight: number = 0;

    constructor(public cartService:CartService,
                @Inject(DOCUMENT) private document: any,
                public geService: GlobalEventsService,
                private router:Router) {
        this.cartService.updateCart();
    }

    ngOnInit(){
        this.setMaxHeightOfScrollableContent();
    }

    clickCartButtonEventHandler() {
        this.geService.showCartPopover.value = !this.geService.showCartPopover.value;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setMaxHeightOfScrollableContent();
    }

    setMaxHeightOfScrollableContent(){
        let paddingAboveAndBelow = 268;
        let browserHeight = this.document.body.clientHeight;
        // console.log('browserHeight', browserHeight);
        this.maxContentHeight = browserHeight - paddingAboveAndBelow;
    }
}
