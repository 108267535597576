<section id="main-app-container" class="foxygrid">
    <mat-progress-bar *ngIf="globalEventsService.showProgressBar" mode="indeterminate"></mat-progress-bar>

    <div *ngIf="!globalEventsService.inAdminSide">

        <!-- DEFAULT NAV -->
        <xion-main-header *ngIf="!globalEventsService.showCheckoutHeader && !globalEventsService.showResourceHeader && !globalEventsService.hideHeader"></xion-main-header>

        <!-- CHECKOUT NAV -->
        <div id="checkout-header" *ngIf="globalEventsService.showCheckoutHeader">
            <a [routerLink]="['']"><img src="/assets/img/new_logos/xion_logo_outdoor_retailer_collective.png" alt="XION logo"></a>
        </div>

        <!-- RESOURCES NAV -->
        <div id="resource-header" *ngIf="globalEventsService.showResourceHeader">
            <a [routerLink]="['']"><img src="/assets/img/svg/logo_white.svg" alt="XION logo"></a>
            <span>Resources</span>
        </div>

        <xion-expired-session-login></xion-expired-session-login>

        <!--if special deal banner component is being used then 68, otherwise 18-->
        <xion-fade-out-notification-stack [top]="fadeOutNotificationService.top || 68" [notifications]="fadeOutNotificationService.notifications"></xion-fade-out-notification-stack>
    </div>

    <section class="hamburger-animation-container">
        <div class="main-content" [class.out]="showMenu" [class.checkout-min-height]="globalEventsService.showCheckoutHeader">
            <!--<div *ngIf="showMenu" id="invisible-cover"></div>-->
            <router-outlet></router-outlet>
        </div>
    </section>
    <xion-main-footer *ngIf="!globalEventsService.inAdminSide"></xion-main-footer>
</section>

<!-- TalkJS prompt bubble -->
<!-- <xion-talkjs-bubble-prompt></xion-talkjs-bubble-prompt> -->

<router-outlet name="modal"></router-outlet>
<router-outlet name="loginModal"></router-outlet>

<div id="xion-fb-customerchat"
     class="fb-customerchat"
     page_id="1708922919397211"
     logged_in_greeting="Hi, what can I help you with?"
     logged_out_greeting="Hi, what can I help you with?">
</div>
