import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'values'
})
export class ValuesPipe implements PipeTransform {
    /**
     * Allows *ngFor to iterate over objects
     * Reference: http://plnkr.co/edit/heyPxN?p=preview
     * Example shows bandMember.key, but it's really bandMember.k
     *
     * Warning: Doesn't really work asynchronously
     *
     * Implementation: obj | values:'key':true"
     */
    transform(value: any, args?: any[]): Object[] {
        let keyArr: any[] = Object.keys(value),
            dataArr = [],
            keyName = args[0];

        keyArr.forEach((key: any) => {
            // Catch in case just string value instead of object
            if(typeof value[key] === 'string'){
                value[key] = {
                    value: value[key]
                };
            }
            value[key][keyName] = key;
            dataArr.push(value[key]);
        });

        if(args[1]) {
            dataArr.sort((a: Object, b: Object): number => {
                return a[keyName] > b[keyName] ? 1 : -1;
            });
        }
        return dataArr;
    }
}
