import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'xion-modal-position-relative',
    template: `
        <div *ngIf="isShowing">
            
            <div class="overlay" (click)="close()"></div>
            
            <div class="container" 
                 [style.width]="width" 
                 [style.max-width]="maxWidth">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styles: [`
        :host{
            display: block;
            width: 100%;
            position: relative;
        }
        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 3;
            background-color: transparent;
        }
        .container{
            z-index: 4;
            left: 0;
            right: 0;
            margin: 0 auto;
            position: absolute;
            background: white;
            border-radius: 8px;
            box-shadow: 4px 6px 20px 1px rgba(0, 0, 0, 0.5);
        }
        @media only screen and (max-width: 1000px){
            .container{
                width: 94% !important;
            }
        }
        @media only screen and (max-width: 700px){
            .container{
                width: 100% !important;
            }
        }
    `],
})
export class ModalPositionRelativeComponent implements OnInit {

    isShowing: boolean = false;
    @Input() width: string = '500px';
    @Input() maxWidth: string = '700px';
    @Output() justClosed = new EventEmitter();

    constructor() { }

    ngOnInit() {}

    close(){
        this.isShowing = false;
        this.justClosed.emit(true);
    }

    open(){
        this.isShowing = true;
    }

}
