import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './marketplace/home/home.component';
import { AuthGuard } from './auth-guard.service';
import { ProductBySEONameResolver } from './marketplace/resolvers/product-seoname-resolver.service';
import { EntityBySEONameResolver } from './marketplace/resolvers/entity-seoname-resolver.service';
import { SessionExpiredAuthGuard } from './shared/session-expired-auth-guard.service';
import { SelectivePreloadingStrategy } from './selective-preloading-strategy';
import { SessionCartGuard } from './shared/session-cart-guard.service';
import { CheckoutNewComponent } from './marketplace/checkout-new/checkout-new.component';
import { ExpiredSessionComponent } from './marketplace/expired-session/expired-session.component';
import { PopupComponent } from './marketplace/popup/popup.component';
import { SellPageGuardGuard } from './sell-page-guard.guard';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        data: {
            meta: {
                title: 'XION.store - Shop Local Online',
                description:
                    'Shop independent local retailers online and support local businesses. Choose In-store pick up or ship to anywhere in the US.',
            },
        },
    },
    {
        path: 'sign-in',
        loadChildren: () =>
            import('./marketplace/sign-in/sign-in.module').then(
                (m) => m.SignInModule
            ),
        data: {
            meta: {
                title: 'XION - Sign In',
                description: 'Sign in to the XION marketplace',
            },
        },
    },
    {
        path: 'create-account',
        loadChildren: () =>
            import('./marketplace/sign-up/sign-up.module').then(
                (m) => m.SignUpModule
            ),
        data: {
            meta: {
                title: 'XION - Sign Up',
                description: 'Sign-up to shop local retail companies near you',
            },
        },
    },
    {
        path: 'account',
        loadChildren: () =>
            import('./marketplace/account/account.module').then(
                (m) => m.AccountModule
            ),
        canActivate: [AuthGuard],
        data: {
            meta: {
                title: 'XION - Account Settings',
                description: 'Manage your account settings here',
            },
        },
    },
    {
        path: 'your-orders',
        loadChildren: () =>
            import('./marketplace/your-orders/your-orders.module').then(
                (m) => m.YourOrdersModule
            ),
        canActivate: [AuthGuard],
        data: {
            meta: {
                title: 'XION - Your Orders',
                description: 'See all your orders here',
            },
        },
    },
    // {
    //     path: 'your-messages',
    //     loadChildren: './marketplace/your-messages/your-messages.module#YourMessagesModule',
    //     canActivate: [AuthGuard],
    //     data: {
    //         meta: {
    //             title: 'XION - Your Messages',
    //             description: 'See all your chat conversations here'
    //         }
    //     }
    // },
    {
        path: 'create-new-store',
        loadChildren: () =>
            import(
                './marketplace/create-new-store/create-new-store.module'
            ).then((m) => m.CreateNewStoreModule),
        data: {
            meta: {
                title: 'XION - Create a Store',
                description: 'Create a store on XION',
            },
        },
    },
    {
        path: 'search',
        loadChildren: () =>
            import(
                './marketplace/search/search-container/search-container.module'
            ).then((m) => m.SearchContainerModule),
        data: {
            meta: {
                title: 'XION - Search',
                description: 'Search the XION marketplace',
            },
        },
    },
    {
        path: 'stores',
        loadChildren: () =>
            import('./marketplace/stores/stores.module').then(
                (m) => m.StoresModule
            ),
        data: {
            meta: {
                title: 'XION - Local Retailers',
                description: 'Find local outdoor retailers near you',
            },
        },
    },
    {
        path: 'about-us',
        loadChildren: () =>
            import('./marketplace/about-us/about-us.module').then(
                (m) => m.AboutUsModule
            ),
        data: {
            meta: {
                title: 'XION - About Us',
                description:
                    'XION is a marketplace that connects the independent retailer with the community allowing the customer to easily shop the products they want locally.',
            },
        },
    },
    {
        path: 'contact-us',
        loadChildren: () =>
            import('./marketplace/contact-us/contact-us.module').then(
                (m) => m.ContactUsModule
            ),
        data: {
            meta: {
                title: 'XION - Contact Us',
                description:
                    'Need help or have questions about using XION? Email us at support@xion.store. Interested in putting your retail store on XION? Email us at sales@xion.store.',
            },
        },
    },
    {
        path: 'policies',
        loadChildren: () =>
            import('./marketplace/policies/policies.module').then(
                (m) => m.PoliciesModule
            ),
        data: {
            meta: {
                title: 'XION - Policies',
                description: 'XION policies information',
            },
        },
    },
    {
        path: 'product/:seoName',
        loadChildren: () =>
            import(
                './marketplace/product/product-container/product-container.module'
            ).then((m) => m.ProductContainerModule),
        resolve: {
            xrd: ProductBySEONameResolver,
        },
    },
    {
        path: 'resources',
        loadChildren: () =>
            import('./marketplace/resources/resources.module').then(
                (m) => m.ResourcesModule
            ),
    },
    {
        path: 'deals',
        loadChildren: () =>
            import('./marketplace/deals/deals.module').then(
                (m) => m.DealsModule
            ),
        data: {
            meta: {
                title: 'XION - Deals',
                description: 'Products organized by discounted price',
            },
        },
    },
    {
        path: 'sell',
        loadChildren: () =>
            import('./marketplace/sell/sell.module').then((m) => m.SellModule),
        canActivate: [SellPageGuardGuard],
    },
    {
        path: 'request-invite',
        loadChildren: () =>
            import(
                './marketplace/sell/sell-request-invite/sell-request-invite.module'
            ).then((m) => m.SellRequestInviteModule),
        data: {
            meta: {
                title: 'XION - Request Invite',
                description:
                    'Request an invite to become a badass outdoor retailer using XION software.',
            },
        },
    },
    {
        path: 'pricing',
        loadChildren: () =>
            import('./marketplace/pricing/pricing.module').then(
                (m) => m.PricingModule
            ),
        data: {
            meta: {
                title: 'Pricing | XION',
                description: 'Learn more about pricing on XION',
            },
        },
    },
    {
        path: 'schedule-demo',
        loadChildren: () =>
            import('./marketplace/schedule-demo/schedule-demo.module').then(
                (m) => m.ScheduleDemoModule
            ),
    },
    {
        path: 'shop-all',
        loadChildren: () =>
            import('./marketplace/shop-all/shop-all.module').then(
                (m) => m.ShopAllModule
            ),
        data: {
            meta: {
                title: 'XION - Shop All',
                description: 'Shop all XION brands and categories',
            },
        },
    },
    {
        path: 'admin',
        loadChildren: () =>
            import('./marketplace/admin/admin.module').then(
                (m) => m.AdminModule
            ),
    },
    {
        path: '404',
        loadChildren: () =>
            import('./marketplace/page-missing/page-missing.module').then(
                (m) => m.PageMissingModule
            ),
        data: {
            meta: {
                title: 'XION - 404',
                description: 'Looks like this page is missing',
            },
        },
    },
    {
        path: 'checkout',
        component: CheckoutNewComponent,
        // canActivate: [AuthGuard, SessionExpiredAuthGuard, SessionCartGuard],
        canActivate: [SessionCartGuard, SessionExpiredAuthGuard],
        outlet: 'modal',
        children: [
            {
                path: '',
                loadChildren: () =>
                    import(
                        './marketplace/checkout-new/checkout-new.module'
                    ).then((m) => m.CheckoutNewModule),
            },
        ],
    },
    {
        path: 'popup',
        component: PopupComponent,
        outlet: 'modal',
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./marketplace/popup/popup.module').then(
                        (m) => m.PopupModule
                    ),
            },
        ],
    },
    {
        path: 'cart',
        canActivate: [SessionCartGuard, SessionExpiredAuthGuard],
        loadChildren: () =>
            import(
                './marketplace/checkout-new-mobile/checkout-new-mobile.module'
            ).then((m) => m.CheckoutNewMobileModule),
    },
    {
        path: 'expired-session',
        component: ExpiredSessionComponent,
        outlet: 'loginModal',
        children: [
            {
                path: '',
                loadChildren: () =>
                    import(
                        './marketplace/expired-session/expired-session.module'
                    ).then((m) => m.ExpiredSessionModule),
            },
        ],
    },
    // {
    //     path: 'category',
    //     loadChildren: './marketplace/category/category.module#CategoryModule',
    // },
    {
        path: 'department',
        loadChildren: () =>
            import('./marketplace/department/department.module').then(
                (m) => m.DepartmentModule
            ),
    },
    {
        path: 'department-coming-soon',
        loadChildren: () =>
            import(
                './marketplace/department-coming-soon/department-coming-soon.module'
            ).then((m) => m.DepartmentComingSoonModule),
        data: {
            meta: {
                title: 'XION - Department Coming Soon',
                description: 'This department is coming soon',
            },
        },
    },
    {
        path: 'brand',
        loadChildren: () =>
            import('./marketplace/brand/brand.module').then(
                (m) => m.BrandModule
            ),
    },
    // This route is only used when server-side rendering tries to go to auth protected route
    {
        path: 'route-initializing',
        loadChildren: () =>
            import(
                './marketplace/serverside-route-loading/serverside-route-loading.module'
            ).then((m) => m.ServersideRouteLoadingModule),
    },
    // This route is used for Shopify integration redirect and also XPOS admin routes without the nav showing
    {
        path: 'auth-and-redirect',
        loadChildren: () =>
            import(
                './marketplace/auth-and-redirect/auth-and-redirect.module'
            ).then((m) => m.AuthAndRedirectModule),
    },
    // retailer review
    {
        path: 'review',
        loadChildren: () =>
            import('./marketplace/review/review.module').then(
                (m) => m.ReviewModule
            ),
        data: {
            meta: {
                title: 'Retailer Review',
                description: 'Review a XION retailer.',
            },
        },
    },
    // Must be last for other routes to work
    {
        path: ':seoName',
        loadChildren: () =>
            import('./marketplace/store/store.module').then(
                (m) => m.StoreModule
            ),
        data: { preload: true },
        resolve: {
            entCatAndBrands: EntityBySEONameResolver,
        },
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabled',
            preloadingStrategy: SelectivePreloadingStrategy,
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
    providers: [SelectivePreloadingStrategy],
})
export class AppRoutingModule {}
