import {Component, OnInit, ViewChild, Input, Inject} from '@angular/core';
import {WindowRef} from "../../../WindowRef";
import {DOCUMENT} from "@angular/common";

@Component({
    selector: 'xion-horizontal-scroller',
    templateUrl: './horizontal-scroller.component.html',
    styleUrls: ['./horizontal-scroller.component.css']
})
export class HorizontalScrollerComponent implements OnInit {


    // TODO: wrap all this in it's own component, then you can use the hostListener for scroll events

    @Input() elementToScroll;

    @Input() showLeft: boolean = false;
    @Input() showRight: boolean = true;

    constructor(private winRef: WindowRef,
                @Inject(DOCUMENT) private document: any) { }

    ngOnInit() {
    }

    /**
     * Scrolls to the right one length of the window width
     */
    scrollRight(): void{
        console.log('d', this.elementToScroll);
        console.log('scroll right');
        this.elementToScroll.scrollBy({
            top: 0,
            left: this.getWindowWidth(),
            behavior: 'smooth'
        });
        // this.showHideLeftRight();
    }

    /**
     * Scrolls to the left one length of the window width
     */
    scrollLeft(): void{
        console.log('scroll left');
        this.elementToScroll.scrollBy({
            top: 0,
            left: -this.getWindowWidth(),
            behavior: 'smooth'
        });
        // this.showHideLeftRight();
    }

    getWindowWidth(): number{
        let width = this.winRef.nativeWindow.innerWidth || this.document.body.clientWidth;
        return width;
    }

    showHideLeftRight(): void{
        // Wait one second for the scroll to finish first
        // setTimeout(()=>{
            let farthestLeftPosition = this.elementToScroll.scrollWidth - this.elementToScroll.clientWidth;
            let scrollLeftPosition = this.elementToScroll.scrollLeft;

            // Left
            if(scrollLeftPosition === 0){
                this.showLeft = false;
            }else{
                this.showLeft = true;
            }

            // Right
            if(farthestLeftPosition <= scrollLeftPosition){
                this.showRight = false;
            }else{
                this.showRight = true;
            }
        // }, 400);
    }
}
