import { Component, OnInit } from '@angular/core';
import { ModalComponent } from '../modal.component';

@Component({
    selector: 'xion-modal-body',
    templateUrl: './modal-body.component.html',
    styleUrls: [ './modal-body.component.css' ],
})
export class ModalBodyComponent implements OnInit {
    constructor(private modal: ModalComponent) { }
    ngOnInit() {
        // console.log('ModalBodyComponent:ngOnInit()');
    }
}
