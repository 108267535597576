import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {CartService} from "../../marketplace/cart/cart-service/cart.service";
import * as _ from "underscore";
import {CheckoutService} from "../../marketplace/checkout-new/shared/checkout.service";
import {Cart} from "../../marketplace/cart/cart.model";

@Component({
    selector: 'xion-order-summary-box',
    templateUrl: './order-summary-box.component.html',
    styleUrls: ['./order-summary-box.component.css']
})
export class OrderSummaryBoxComponent implements OnInit {

    @Input() tax: number = 0;
    @Input() discount: number = 0;
    @Input() subtotal: number;
    @Input() userCart: Cart;
    @Input() shipping: number = 0;
    @Input() showItemDetails: boolean = true;
    @Input() isCalculating: boolean = false;
    @Input() showTopBox: boolean = true;
    @Input() itemsRemovable: boolean = false;
    @Input() isCartView: boolean = false;

    @Input() showButton: boolean = false;
    @Input() formValid: boolean = true;
    @Output() buttonClicked = new EventEmitter();

    constructor() { }

    ngOnInit() {}
}
