import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'xion-simple-modal',
  templateUrl: './simple-modal.component.html',
  styleUrls: ['./simple-modal.component.css']
})
export class SimpleModalComponent implements OnInit {

    isShowing: boolean = false;

    ngOnInit() {
        // console.log('ModalComponent:ngOnInit()');
    }

    open() {
        this.isShowing = true;
    }

    close() {
        this.isShowing = false;
    }

}
