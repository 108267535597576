import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from "rxjs";
import {ClientSessionTokenService} from "../session-service/client-session-token.service";

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

    constructor(@Inject(PLATFORM_ID) protected platformId: Object,
                private clientSessionTokenService: ClientSessionTokenService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Get the session.
        const session = this.clientSessionTokenService.getSessionToken();
        if(session){
            // console.log('Adding session: ', session);
            // console.log('To call: ', req.url);
            // Clone the request to add the new header.
            const reqWithSession = req.clone({headers: req.headers.set('session', session)});
            // Pass on the cloned request instead of the original request.
            return next.handle(reqWithSession);
        }else{
            return next.handle(req);
        }
    }
}
