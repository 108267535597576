import {Component, OnInit} from '@angular/core';
import {XRDService} from "../../../shared/xrd/xrd.service";
import {XRDProduct} from "../../../shared/xrd/product/xrd-product.model";
import {HomeService} from "../home.service";
import {XRDPromotedProduct} from "../../../shared/xrd/product/xrd-promoted-product.class";

@Component({
    selector: 'xion-home-promoted-products',
    templateUrl: './home-promoted-products.component.html',
    styleUrls: ['./home-promoted-products.component.css']
})
export class HomePromotedProductsComponent implements OnInit {

    constructor(public homeService: HomeService) { }

    ngOnInit() {
        if(!this.homeService.isPromotedProductsLoaded){
            this.homeService.setPromotedProducts();
        }
    }
}
