
import {from as observableFrom, forkJoin as observableForkJoin,  Observable } from 'rxjs';

import {map, mergeMap, flatMap} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';

import { XRDUser } from './xrd-user.model';
import {ConstantsService} from "../../xion-constants.service";
import {XRDAddress} from "../shared/xrd-address.model";
import {XRDPhone} from "../shared/xrd-phone.model";
import {XRDEmail} from "../shared/xrd-email.model";
import {XRDEntityAccess} from "../shared/xrd-entity-access.model";
import {HttpLocalClient} from "../../../http-client.service";
import {ClientSessionTokenService} from "../../session-service/client-session-token.service";
import { XRDEntityService } from '../entity/xrd-entity.service';
import { XRDEntity } from '../entity/xrd-entity.model';
import { XRDShallowEntity } from '../shared/xrd-shallow-entity.model';
import { XRDOrder } from '../order/xrd-order.model';

@Injectable()
export class XRDUserService {

    private http;

    constructor (private constantsService:ConstantsService,
                 private xrdEntityService: XRDEntityService,
                 private clientSessionTokenService: ClientSessionTokenService,
                 private httpClient: HttpLocalClient) {
        this.http = httpClient;
    }

    public authenticate(username: string, password: string): Observable<XRDUser> {
        let payload = {
            username: username,
            password: password,
        };

        return this.http.post(this.constantsService.getBackendURL() + 'user/authenticate', JSON.stringify(payload), true) // Don't handle error for 401
            .pipe(map((res:any)=> {
                // Save the sessionId to use for all other requests
                this.clientSessionTokenService.saveSessionToken(res.session);
                console.log('SESSSSSSSSION', res.session);

                return res || { };
            }));
    }

    public create(username: string, password: string, firstName: string, lastName: string, options?: any) {

        let contactType: string = 'User';
        if(options){
            contactType = options.contactType;
        }

        let payload = {
            users: [
                {
                    entityid: 1,
                    contacttype: contactType,
                    contactstatus: 'Active',
                    username: username,
                    password: password,
                    firstname: firstName,
                    lastname: lastName,
                }
            ]
        };

        if (options) {
            Object.getOwnPropertyNames(options).forEach((value, index) => {
                payload[value] = options[value];
            });
        }

        return this.http.post(this.constantsService.getBackendURL() + 'user', JSON.stringify(payload), true) // Don't handle error for 409
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res.users || [];
            }));
    }

    public createContact(username: string): Observable<XRDUser> {
        let payload = {
            "contacts":[
                {
                    "username": username
                }]
        };
        return this.http.post(this.constantsService.getBackendURL() + 'contact', payload)
            .pipe(map((res:any)=>{
                if(!res) return {};
                return res.contacts[0] || {};
            }));
    }

    public createContactSubscription(contactId: string, subscriptionType: string = 'Newsletter'): Observable<any> {
        let payload = {
        "subscriptions":[
            {
                "subscriptiontype": subscriptionType
            }]
        };
        return this.http.post(this.constantsService.getBackendURL() + 'contact/' + contactId + '/subscription', payload)
            .pipe(map((res:any)=>{
                if(!res) return {};
                return res.subscriptions[0] || {};
            }));
    }
 
    public getUserByUsername(username: string): Observable<XRDUser> {
        return this.http.get(this.constantsService.getBackendURL() + 'user?username=' + username)
            .pipe(map((res:any)=>{
                if(!res) return undefined;
                return res.users[0] || undefined;
            }));
    }

    createUserWithUsernameOnly(username: string){
        let payload = {
            users: [{ 
                username: username
            }]
        };

        return this.http.post(this.constantsService.getBackendURL() + 'user', JSON.stringify(payload), true) // Don't handle error for 409
            .pipe(map((res:any)=> {
                return res.users || { };
            }));
    }

    public getUserById(contactId: string, dontHandleError?:boolean): Observable<XRDUser> {
        if(dontHandleError){
            return this.http.get(this.constantsService.getBackendURL() + 'user/' + contactId, true)
                .pipe(map((res:any)=> res.users || []));
        }else{
            return this.http.get(this.constantsService.getBackendURL() + 'user/' + contactId)
                .pipe(map((res:any)=> res.users || []));
        }
    }

    public getUserByIdDeep(contactId: string): Observable<XRDUser> {
        return this.getUserById(contactId).pipe(
            mergeMap(
                (result: XRDUser) => {
                    console.log('Got shallow user, loading deep data...', result[0]);
                    let user: XRDUser = result[0];

                    // console.log('user is: ' + JSON.stringify(user));
                    // Queue up calls to get the rest of the user data.
                    let observables = [];

                    observables.push(this.getAddressesDeep(user.contactid));
                    observables.push(this.getPhonesDeep(user.contactid));
                    observables.push(this.getEmailsDeep(user.contactid));
                    observables.push(this.getOwnedEntitiesDeep(user.contactid, user.entities));
                    observables.push(this.getOwnedEntitiesAccess(user.contactid));

                    return observableForkJoin(
                        observables
                    ).pipe(
                        map(
                            results => {
                                return { user: user, innerResults: results };
                            }
                        ));
                }
            ),
            map(
                (results) => {
                    results.user.deepaddresses = <XRDAddress[]>results.innerResults[0];
                    results.user.deepphones = <XRDPhone[]>results.innerResults[1];
                    results.user.deepemails = <XRDEmail[]>results.innerResults[2];
                    results.user.deepentities = <XRDEntity[]>results.innerResults[3];
                    results.user.entitiesaccess = <XRDEntityAccess[]>results.innerResults[4];

                    // console.log("User deep data loaded. Results: " + JSON.stringify(results));

                    return results.user;
                }
            ));
    }

    public updatePassword(contactId: string, newPassword: string) {
        let payload = {
            users: [
                {
                    Password: newPassword,
                }
            ]
        };

        return this.http.put(this.constantsService.getBackendURL() + 'user/' + contactId, JSON.stringify(payload))
            .pipe(map((res:any)=>{
                return res || { };
            }));
    }

    public updateDisplayName(contactId: string, displayName: string) {
        let payload = {
            users: [
                {
                    displayname: displayName,
                }
            ]
        };

        return this.http.put(this.constantsService.getBackendURL() + 'user/' + contactId, JSON.stringify(payload))
            .pipe(map((res:any)=>{
                return res || { };
            }));
    }

    public getAddressesDeep(contactId: string) {
        return this.http.get(this.constantsService.getBackendURL() + 'user/' + contactId + '/address?start=0&count=20')
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res.addresses || [];
            }));
    }

    public getAddressByID(contactID:string, addressID:string){
        return this.http.get(this.constantsService.getBackendURL() + 'user/' + contactID + '/address/' + addressID)
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res.addresses[0] || [];
            }));
    }

    public getPhonesDeep(contactId: string) {
        return this.http.get(this.constantsService.getBackendURL() + 'user/' + contactId + '/phone?start=0&count=100')
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res.phones || [];
            }));
    }

    /**
     * Get all emails associated with the user with contactId (up to 100).
     */
    public getEmailsDeep(contactId: string) {
        return this.http.get(this.constantsService.getBackendURL() + 'user/' + contactId + '/email?start=0&count=100')
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res.emails || [];
            }));
    }

    public addEmail(userID: string, email: string, emailType?: string){
        let payload = {
            emails: [{
                emailaddress: email,
                emailtype: emailType ? emailType : 'Home'
            }]
        };
        return this.http.post(this.constantsService.getBackendURL() + 'user/' + userID + '/email', JSON.stringify(payload))
            .pipe(map(emailResult =>{
                console.log('Email result: ', emailResult);
                return emailResult;
            }));
    }

    public getOwnedEntitiesDeep(contactId: string, shallowEntities?: XRDShallowEntity[]) {
        let createGetEntitiesDeepStream = function(shallowEntities: XRDShallowEntity[]) {
            if (shallowEntities.length > 0) { 
                // Queue up calls to get each entity data.
                let observables = [];

                shallowEntities.forEach(
                    (item: XRDShallowEntity) => {
                        observables.push(this.xrdEntityService.getEntityById(item.entityid));
                    }
                );

                return observableForkJoin<XRDEntity[]>(observables);
            } else {
                console.log('Returning empty');
                return Observable.create(
                    (observer) => {
                        observer.next([]);
                        observer.complete();
                    }
                );
            }
        }.bind(this);

        if (shallowEntities) {
            return createGetEntitiesDeepStream(shallowEntities);
        } else {
            return this.getUserById(contactId).pipe(mergeMap((result: XRDUser) => createGetEntitiesDeepStream(result.entities)));
        }
    }

    public getOwnedEntitiesAccess(contactId: string) {
        return this.http.get(this.constantsService.getBackendURL() + 'user/' + contactId + '/entity?start=0&count=1000')
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res.entities || [];
            }));
    }

    public createAddressForUserId(
        userId: string,
        addressType: string,
        fullName: string,
        line1: string,
        line2: string,
        city: string,
        state: string,
        zip: string,
        country: string,
        region: string,
        latitude: string,
        longitude: string,
        params?: Object): Observable<XRDAddress> {
        // Defaults.
        let payload = {
            addresses: [
                {
                    addresstype: addressType,
                    fullname: fullName,
                    address1: line1,
                    address2: line2,
                    city: city,
                    state: state,
                    zip: zip,
                    country: country,
                    region: region,
                    latitude: latitude,
                    longitude: longitude,
                }
            ]
        };

        return this.http.post(this.constantsService.getBackendURL() + 'user/' + userId + '/address', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                if(!res) return [];
                let result = res.addresses[0] || [];
                return result;
            }));
    }

    public updateAddressIdForUserId(
        addressId: string,
        userId: string,
        addressType: string,
        fullName: string,
        line1: string,
        line2: string,
        city: string,
        state: string,
        zip: string,
        country: string,
        region: string,
        latitude: string,
        longitude: string,
        params?: Object) {
        // Defaults.
        let payload = {
            addresses: [
                {
                    addresstype: addressType,
                    fullname: fullName,
                    address1: line1,
                    address2: line2,
                    city: city,
                    state: state,
                    zip: zip,
                    country: country,
                    region: region,
                    latitude: latitude,
                    longitude: longitude,
                }
            ]
        };

        return this.http.put(this.constantsService.getBackendURL() + 'user/' + userId + '/address/' + addressId, JSON.stringify(payload))
            .pipe(map((res:any)=> {
                return res;
            }));
    }

    public createUserPhone(userId: string, phoneNumber: string, phoneType: string) {

        let payload = {
            phones: [
                {
                    phonenumber: phoneNumber,
                    phonetype: phoneType,
                }
            ]
        };

        return this.http.post(this.constantsService.getBackendURL() + 'user/' + userId + '/phone', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                if(!res) return {};
                return res.phones[0] || {};
            }));
    }

    public updateUserPhone(userId: string, phoneId: string, phoneNumber: string, phoneType: string) {

        let payload = {
            phones: [
                {
                    phonenumber: phoneNumber,
                    phonetype: phoneType,
                }
            ]
        };

        return this.http.put(this.constantsService.getBackendURL() + 'user/' + userId + '/phone/' + phoneId, JSON.stringify(payload))
            .pipe(map((res:any)=> {
                return res.phones || { };
            }));
    }

    /**
     * These methods are for ProPay's HPP (Hosted Payment Page)
     */
    public createUserCart(userId: string) {
        return this.http.post(this.constantsService.getBackendURL() + 'user/' + userId + '/cart', {})
            .pipe(map((res:any)=> {
                return res.carts[0].cartid || '';
            }));
    }

    public createUserCartPaymentMethod(userId: string, cartId: string) {
        return this.http.put(this.constantsService.getBackendURL() + 'user/' + userId + '/cart/' + cartId, {})
            .pipe(map((res:any)=> {
                return res.paymentmethodid || { };
            }));
    }

    public getUserOrders(userId: string, orderCount: number): Observable<XRDOrder[]> {
        return this.http.get(this.constantsService.getBackendURL() + 'user/' + userId + '/order?start=0&count=' + orderCount)
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res.orders || [];
            }));
    }

    public getUserOrder(userId: string, orderId: string) {
        return this.http.get(this.constantsService.getBackendURL() + 'user/' + userId + '/order/' + orderId)
            .pipe(map((res:any)=> {
                if(!res) return {};
                return res.orders[0] || {};
            }));
    }

    public createUserOrder(entityId: string, userId: string, options?: any) {

        let payload = {
            orders: [
                {
                    entityid: entityId,
                    contactid: userId,
                    ordertype: 'WebSite',
                    // addressid: addressId,
                    // paymentmethodid: paymentMethodId,
                    // phoneid: phoneId,
                    // emailid: emailId,
                }
            ]
        };

        if (options) {
            Object.getOwnPropertyNames(options).forEach((value, index) => {
                payload.orders[0][value] = options[value];
            });
        }

        return this.http.post(this.constantsService.getBackendURL() + 'user/' + userId + '/order', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                return res.orders[0].orderid || '';
            }));
    }

    public updateUserOrder(userId: string, orderId: string, order) {

        let payload = {
            orders: [
                order
            ]
        };

        return this.http.put(this.constantsService.getBackendURL() + 'user/' + userId + '/order/' + orderId, JSON.stringify(payload))
            .pipe(map((result:any)=>{
                return result;
            }));
    }

    /**
     * Assigns a user to a store.
     *
     * See http://leanfold.com/api/docs/user/user_doc.php#path__v1_user_userId_-entity-.html
     * for a full list of parameters that can be included in params.
     */
    public assignUserByIdToEntityId(contactId: string, entityId: string, params?: Object) {
        // Defaults.
        let payload = {
            features: [
                {
                    entityfeature: "All", // Update!!!!
                    accesstype: "Owner",
                    // entityid: entityId,
                }
            ]
        };

        if (params) {
            Object.getOwnPropertyNames(params).forEach((value, index) => {
                payload.features[0][value] = params[value];
            });
        }

        return this.http.post(this.constantsService.getBackendURL() + 'user/' + contactId + '/entity/' + entityId + '/feature', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                return res.entities || [];
            }));
    }

    public getUserPaymentMethod(contactId: string, start: number, count: number){
        return this.http.get(this.constantsService.getBackendURL() + 'user/' + contactId + '/paymentmethod?start=' + start + '&count=' + count)
            .pipe(map((res:any)=> {
                if(!res) return [];
                return res.paymentmethods || [];
            }));
    }

    public getUserPaymentMethodById(contactId: string, paymentMethodId: string){
        return this.http.get(this.constantsService.getBackendURL() + 'user/' + contactId + '/paymentmethod/' + paymentMethodId)
            .pipe(map((res:any)=> {
                if(!res) return {};
                return res.paymentmethods[0] || {};
            }));
    }

    createUserPaymentMethod(userId, propayResponseBody:any, ccInfo:any, billingAddress:XRDAddress, billingPhone:XRDPhone, email:XRDEmail){
        /**
         * Action:"Complete"
         Address1:"439 E Center st"
         Address2:"undefined"
         Address3:""
         CardholderName:"undefined"
         City:"Lindon"
         Country:"USA"
         Echo:" "
         ExpireDate:"1220"
         ObfuscatedAccountNumber:"474747******4747"
         PayerID:"1093238283323700"
         PaymentMethodId:"e2b24e5b-f236-4919-98ad-7df12c9500c5"
         PostalCode:"84042"
         State:"UT"
         */

        let payload = {
            "paymentmethods":[
                {
                    "paymentmethodtype":ccInfo.type,
                    "contactid":userId,
                    "ProcessorType":"ProPay",
                    "name":propayResponseBody.CardholderName,
                    "month":ccInfo.expMonth, // two digits
                    "year":ccInfo.expYear, // two digits
                    "externalpaymentmethodid":propayResponseBody.PaymentMethodId,
                    "displaycardnumber":propayResponseBody.ObfuscatedAccountNumber,
                    "addresses": [
                        {
                            "addresstype":"Billing" ,
                            fullname: billingAddress.fullname,
                            address1: billingAddress.address1,
                            address2: billingAddress.address2,
                            city: billingAddress.city,
                            state: billingAddress.state,
                            zip: billingAddress.zip,
                            country: "USA",
                            region: "North America",
                            latitude: "0",
                            longitude: "0",
                        }
                    ],
                    "phones": [
                        {
                            "phonetype":"Billing",
                            "phonenumber":billingPhone.phonenumber
                        }
                    ],
                    "emails": [
                        {
                            "emailtype":"Home",
                            "emailaddress":email.emailaddress
                        }
                    ]
                }
            ]
        };

        return this.http.post(this.constantsService.getBackendURL() + 'user/' + userId + '/paymentmethod', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                if(!res) return {};
                return res.paymentmethods[0] || {};
            }));
    }

    createPropayPaymentMethod(userId, ccInfo, billingAddress:XRDAddress){
        let tempToken;
        return this.getUserOrderTempToken(userId)
            .pipe(
            flatMap((ttResponse:any)=>{
                tempToken = ttResponse.temptoken;
                return this.sendFormDataToPropay(
                    ttResponse.credentialid,
                    ttResponse.base64block,
                    ccInfo,
                    billingAddress
                );
            }),
            flatMap(responseCipher=>{
                // if(responseCipher.ErrMsg){
                //     endResult.status = 'FAILURE';
                //     endResult.error = responseCipherObject.ProcErrMsg;
                // }
                return this.decryptResponseCipher(userId, tempToken, responseCipher);
            }),
            map((responseCipherObject:any)=>{
                let endResult = {
                    status: 'SUCCESS',
                    error: '',
                    body: responseCipherObject
                };
                if(responseCipherObject.ProcErrMsg){
                    endResult.status = 'FAILURE';
                    endResult.error = responseCipherObject.ProcErrMsg;
                }
                if(responseCipherObject.StoreErrMsg){
                    endResult.status = 'FAILURE';
                    endResult.error = responseCipherObject.StoreErrMsg;
                }
                if(responseCipherObject.ErrMsg){
                    endResult.status = 'FAILURE';
                    endResult.error = responseCipherObject.ErrMsg;
                }
                if(responseCipherObject.ErrMsg0){
                    endResult.status = 'FAILURE';
                    endResult.error = responseCipherObject.ErrMsg0;
                }
                return endResult;
            }));
    }
    private getUserOrderTempToken(userId){
        return this.http.post(this.constantsService.getBackendURL() + 'user/' + userId + '/order/get_temp_token', {})
            .pipe(map((res:any)=> {
                if(!res) return {};
                return res || {};
            }));
    }
    private sendFormDataToPropay(CID, settingsCipherEncription, ccInfo, billingAddress:XRDAddress){

        let payload = 'CardHolderName=' + ccInfo.fullName
            + '&PaymentTypeId=' + ccInfo.type
            + '&CardNumber=' + ccInfo.cardNumber
            + '&ExpMonth=' + ccInfo.expMonth
            + '&ExpYear=' + ccInfo.expYear
            + '&CVV=' + ccInfo.cvv
            + '&Address1=' + billingAddress.address1
            + '&Address2=' + billingAddress.address2
            + '&City=' + billingAddress.city
            + '&State=' + billingAddress.state
            + '&PostalCode=' + billingAddress.zip
            + '&Country=USA'
            + '&CID=' + CID
            + '&SettingsCipher=' + encodeURIComponent(settingsCipherEncription);

        let thePromise = new Promise((resolve, reject) => {
            let xhttp = new XMLHttpRequest();
            if (xhttp != null) {
                xhttp.open("POST", this.constantsService.getPropayURL() + "pmi/spr.aspx", true);
                xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                xhttp.onreadystatechange = ()=>{
                    if (xhttp.readyState === 4) {
                        if (xhttp.status == 200) {

                            let resp = xhttp.responseText;
                            // This might not be a robust solution...
                            let responseCipher = resp.substring(
                                resp.indexOf('id=\"ResponseCipher\" value=\"')+27,
                                resp.indexOf('\" /></form>')
                            );
                            console.log('responseCipher:', responseCipher);
                            resolve(responseCipher);

                            // TEMPLATE DOESN'T WORK IN IE BROWSER :(
                            // try{
                            //     let resp = xhttp.responseText;
                            //     let template = document.createElement('template');
                            //     if ('content' in template){
                            //         template.innerHTML = resp;
                            //         let responseCipherElement = template.content.querySelector('#ResponseCipher') as any;
                            //         let responseCipher = responseCipherElement.value;
                            //         console.log('responseCipher:', responseCipher);
                            //         resolve(responseCipher);
                            //     }
                            // }catch(error){
                            //     console.log('Error from PMI POST:', error);
                            //     reject(xhttp.responseText);
                            // }
                        } else {
                            reject(xhttp.response);
                        }
                    }
                };
                xhttp.send(payload);
            }
        });

        return observableFrom(thePromise);
    }
    private decryptResponseCipher(userId, tempToken, responseCipher){

        let payload = {
            "TempToken": tempToken,
            "ResponseCipher": responseCipher
        };

        return this.http.post(this.constantsService.getBackendURL() + 'user/' + userId + '/order/decrypt_response', JSON.stringify(payload))
            .pipe(map((res:any)=> {
                if(!res) return {};
                let queryString = res.decryptedblock;

                // parse queryString into javascript object and return object
                let parsedResult = this.parseStringToObject(queryString);

                console.log('parsed responseCipher:', parsedResult);
                return parsedResult;
            }));
    }
    private parseStringToObject(theString:string): any{
        let query = {};
        let pairs = (theString[0] === '?' ? theString.substr(1) : theString).split('&');
        for (let i = 0; i < pairs.length; i++) {
            let pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

    // Decrypted response:

    // Action=Complete
    // &Echo=
    // &PayerID=4636012067808861
    // &ObfuscatedAccountNumber=474747******4747
    // &ExpireDate=1220
    // &CardholderName=John Q. Test
    // &Address1=123 A. Street
    // &Address2=
    // &Address3=
    // &City=Orem
    // &State=UT
    // &PostalCode=84058
    // &Country=USA
    // &PaymentMethodId=6e660681-7e1a-4f7e-84a3-b67404680e20

    // Error

    // Action=Complete
    // &Echo=
    // &PayerID=4636012067808861
    // &ExpireDate=1220
    // &CardholderName=John Q. Test
    // &Address1=123 A. Street
    // &Address2=
    // &Address3=
    // &City=Orem
    // &State=UT
    // &PostalCode=84058
    // &Country=USA
    // &StoreErrCode=308
    // &StoreErrMsg=CreditCard number is invalid for specified type

}
