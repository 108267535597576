
import {takeUntil} from 'rxjs/operators';
import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {GlobalEventsService} from "../global-events.service";
import {MarketplaceMainNavService} from "../../marketplace/marketplace-main-nav.service";
import {Subject} from "rxjs";
import {GoogleStructuredDataService} from "../../marketplace/shared/google-structured-data/google-structured-data.service";
import { GlobalStoreEventsService } from '../global-store-events.service';

@Component({
    selector: 'xion-main-header',
    templateUrl: './main-header.component.html',
    styleUrls: ['./main-header.component.css']
})
export class MainHeaderComponent implements OnInit, OnDestroy {

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    placeholderText: string;

    constructor(private router: Router, public globalEventsService: GlobalEventsService,
                private changeDetectorRef: ChangeDetectorRef,
                private route: ActivatedRoute,
                public gseService: GlobalStoreEventsService,
                public marketplaceMainNavService: MarketplaceMainNavService) {
        this.gseService.useStoreSearch.asObservable().pipe(
            takeUntil(this.ngUnsubscribe.asObservable()))
            .subscribe((mode)=>{
                this.gseService.isStoreSearch = mode;
                this.togglePlaceholderText();
                this.changeDetectorRef.detectChanges();
            });
    }

    ngOnInit() {
        // console.log('MainHeaderComponent:ngOnInit()');
        this.togglePlaceholderText();
    }

    ngOnDestroy(){
        // Unsubscribe to observables
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    togglePlaceholderText(){
        console.log('using store search? :', this.gseService.isStoreSearch);
        if(this.gseService.isStoreSearch){
            this.placeholderText = 'Search ' + this.gseService.storeName + ' for awesome stuff...';
        }else{
            this.placeholderText = 'Search XION for awesome stuff...';
        }
    }

    doSearch() {
        // in this case searchTerms is already bound to this.globalEventsService.mainSearchQuery
        let query = this.globalEventsService.mainSearchQuery;
        let filter = this.globalEventsService.mainSearchFilter;
        if(filter === 'All') filter = '';
        // this.searchFiltersService.resetFilterData();
        let params = {};
        if(query) params['query'] = query;
        if(filter) params['department'] = filter;
        if(this.gseService.isStoreSearch){
            this.router.navigate(['/'+this.gseService.storeSeoName+'/search'], {queryParams: params});
        } else
            this.router.navigate(['search'], {queryParams: params});
    }
}
