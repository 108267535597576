import { Component, OnInit } from '@angular/core';

import { ModalComponent } from '../modal.component';

@Component({
    selector: 'xion-modal-footer',
    templateUrl: './modal-footer.component.html',
    styleUrls: [ './modal-footer.component.css' ],
})
export class ModalFooterComponent implements OnInit {
    constructor(private modal: ModalComponent) { }
    ngOnInit() {
        // console.log('ModalFooterComponent:ngOnInit()');
    }
}
