import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MainHeaderYourAccountComponent} from "./main-header-your-account.component";
import {SharedModule} from "../../shared.module";
import {StoreAdminModalComponent} from "./store-admin-modal/store-admin-modal.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    declarations: [
        StoreAdminModalComponent,
        MainHeaderYourAccountComponent
    ],
    exports: [
        MainHeaderYourAccountComponent
    ]
})
export class MainHeaderYourAccountModule { }
