import {Component, Input, OnInit} from '@angular/core';

import { ModalComponent } from '../modal.component';

@Component({
    selector: 'xion-modal-header',
    templateUrl: './modal-header.component.html',
    styleUrls: [ './modal-header.component.css' ],
})
export class ModalHeaderComponent implements OnInit {

    @Input() showXButton = true;

    constructor(private modal: ModalComponent) { }

    ngOnInit() {
        // console.log('ModalHeaderComponent:ngOnInit()');
    }

    onCloseButtonClick() {
      this.modal.close();
    }
}
