import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {XRDEntityCategory} from "../../../xrd/entity/xrd-entity-category.model";
import {Router} from "@angular/router";
import * as _ from 'underscore';

@Component({
  selector: 'xion-main-header-nav-dropdown-mobile-menu',
  templateUrl: './main-header-nav-dropdown-mobile-menu.component.html',
  styleUrls: ['./main-header-nav-dropdown-mobile-menu.component.css']
})
export class MainHeaderNavDropdownMobileMenuComponent implements OnInit {

    /** Used to ensure the buttons and ancor tags are not tab-able while the dropdown is hidden */
    @Input() isOpenFlag: boolean;
    /** Setter used to select first category initially */
    _categories: XRDEntityCategory;
    get categories(): XRDEntityCategory {
        return this._categories;
    }
    @Input('categories')
    set categories(value: XRDEntityCategory) {
        this._categories = value;
        if(this._categories 
            && !_.isEmpty(this._categories)
            && this._categories.departments.length 
            && this._categories.departments[0].categories.length){
            this.createDepartmentDropDownMap();
        }
    }
    @Input() brands: string[] = [];
    @Input() extraItems: { routePath: string, title: string}[];
    @Input() extraItemsHeader: string = 'XION Info';

    /** Specific to the store dropdown component */
    @Input() isStorePage: boolean = false;
    @Input() isMainMobile: boolean = false;
    @Input() storeSeoName: string;
    @Input() storeName: string;
    @Input() isStoreHomePage: boolean = false;

    /** Outputs */
    @Output() itemSelected = new EventEmitter();
    @Output() categorySelected = new EventEmitter();
    @Output() brandSelected = new EventEmitter();

    departmentsDroppedDown = true;
    brandsDroppedDown = false;
    departmentDroppedDownMap = {};

    constructor(private router: Router) {}

    ngOnInit() {}

    createDepartmentDropDownMap(){
        this.categories.departments.forEach(dep=>{
            this.departmentDroppedDownMap[dep.name] = false;
        });
    }

    toggleDepartmentsDropDown(){
        this.departmentsDroppedDown = !this.departmentsDroppedDown;
    }

    toggleDepartmentDropDown(department){
        this.departmentDroppedDownMap[department] = !this.departmentDroppedDownMap[department];
    }

    toggleBrandsDropDown(){
        this.brandsDroppedDown = !this.brandsDroppedDown;
    }

    searchForDep(department){
        this.router.navigate(['/department-coming-soon/'+department]);
        this.itemSelected.emit(true);
    }
}
