import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'xion-main-header-special-deal-banner',
    template: `
        <a [routerLink]="['deals', {filtercategory:'',filtersubcategory:'',filterbrand:'BMC'}]">
            BMC Bikes Clearance Sale <span class="non-mobile">|</span><span class="mobile"><br></span> Up to 46% OFF | Free Shipping
        </a>
    `,
    styles: [`
        .mobile{
            /*For some reason this works*/
            /*display: inline-block;*/
        }
        .non-mobile{
            display: inline-block;    
        }
        a{
            font-size: 20px;
            width: 100%;
            font-weight: 500;
            color: white;
            display: block;
            padding: 11.5px 0;
            letter-spacing: 1.4px;
            text-align: center;
            background-color: #152438;
        }

        @media only screen and (max-width: 900px) {
            .mobile{
                line-height: .2;
            }
            a{
                padding: 8px 0;
                line-height: 1.4;
                letter-spacing: 0.8px;
                font-size: 12px;                
            }
        }
    `]
})
export class MainHeaderSpecialDealBannerComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
