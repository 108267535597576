<div class="container">
    <div class="filter-list" [ngClass]="{ 'less-border-rad' : gseService.currentStoreCategories }">
        <h5>XION</h5>
        <ul>
            <li>
                <button (click)="onClickXionFilterItem('All')">All</button>
            </li>
            <li *ngFor="let dep of marketplaceMainNavService?.categories?.departments">
                <button (click)="onClickXionFilterItem(dep.name)">{{ dep.name }}</button>
            </li>
        </ul>
    </div>
    <div *ngIf="gseService.currentStoreCategories && gseService.storeName">
        <div class="filter-list additional">
            <h5>{{gseService.storeName}}</h5>
            <ul>
                <li>
                    <button (click)="onClickStoreFilterItem('All')">All</button>
                </li>
                <li *ngFor="let dep of gseService.currentStoreCategories.departments">
                    <button (click)="onClickStoreFilterItem(dep.name)">{{ dep.name }}</button>
                </li>
            </ul>
        </div>
        <div class="vertical-line"></div>
    </div>
</div>
