import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'xion-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

    @Input() absolutePosition: boolean = false;
    @Input() isLoading: boolean = false;
    @Input() class: string = 'loading';

    constructor() { }

    ngOnInit() {
        // console.log('LoaderComponent:ngOnInit()');
    }

}
