import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'xion-admin-important-loader',
    templateUrl: './important-loader.component.html',
    styleUrls: ['./important-loader.component.css'],
})
export class ImportantLoaderComponent implements OnInit {
    @Input() show: boolean = false;
    @Input() showWhiteBox: boolean = false;

    constructor() {}

    ngOnInit(){
        // console.log('ImportantLoaderComponent:ngOnInit()');
    }
}
