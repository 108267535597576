import { TalkService } from './talk/talk.service';
import { ThirdPartySessionTrackingService } from './../marketplace/shared/third-party-session-tracking/third-party-session-tracking.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UserIdentityService {

    constructor(private tpstService: ThirdPartySessionTrackingService, 
                private talkService: TalkService) { }

    setUserSession(firstname, lastname, username, contactid){
        // Drip, Sentry, Fullstory
        this.tpstService.setSession(firstname, lastname, username, contactid);
        // TalkJS
        this.talkService.createLoggedInSession(contactid, username, firstname, lastname);
    }

    setVisitorSession(){
        this.talkService.createVisitorSession();
    }

    clearSession(){
        this.tpstService.clearSession();
    }
}
