import { XRDShallowEntity, XRDShallowAddress, XRDShallowPhone, XRDShallowAccount, XRDShallowGroup, XRDShallowEmail, XRDEmail, XRDAddress, XRDPhone, XRDEntity, XRDEntityAccess } from '../';

export class XRDUser {
    contactid: string;
    username: string;
    firstname: string;
    lastname: string;
    contactstatus: string;
    contacttype: string;
    session: string;
    superadmin: string;
    displayname: string;
    addresses: XRDShallowAddress[];
    deepaddresses: XRDAddress[];

    emails: XRDShallowEmail[];
    deepemails: XRDEmail[];

    phones: XRDShallowPhone[];
    deepphones: XRDPhone[];

    accounts: XRDShallowAccount[];

    entities: XRDShallowEntity[];
    deepentities: XRDEntity[];
    entitiesaccess: XRDEntityAccess[];
    subscriptions: any[];
    groups: XRDShallowGroup[];
}
