import {Injectable} from "@angular/core";
import {Subject, Observable} from "rxjs";
import {forkJoin} from "rxjs/index";
import {map} from "rxjs/operators";
import { XRDService, XRDEntityCategory, XRDEntity } from "./xrd";

@Injectable()
export class GlobalStoreEventsService {
    
    /** Store page stuff */
    useStoreSearch: Subject<boolean> = new Subject<boolean>();
    isStoreSearch: boolean = false;
    storeName: string;
    storeSeoName: string;
    storeNameForFooter: string;
    storePhone: string;
    storeEmail: string; 
    isStorePage: boolean;
    dontShowStoreFooter: boolean = false;
    storeHours: any;
    availableStoreDealCategories: string[];
    currentStoreCategories: XRDEntityCategory;

    constructor(private xrdService: XRDService) {}

    getCategoriesAndBrandsForEntity(entityId): Observable<any>{
        let observables = [];
        observables.push(this.xrdService.entity.getEntityProductCategoryList(entityId));
        observables.push(this.xrdService.entity.getEntityBrandList(entityId));
        return forkJoin(...observables);
    }

    getPromotedProductsForEntity(entityId, numOfProducts): Observable<any>{
        return this.xrdService.entity.getEntityPromotedProducts(entityId, numOfProducts);
    }

    setDealCategoriesForEntity(entityId){
        this.getDealCategories(entityId).subscribe(availableDealCategories=>{
            this.availableStoreDealCategories = availableDealCategories;
            // console.log('availableDealCategories:', this.availableStoreDealCategories);
        });
    }

    private getDealCategories(entityId): Observable<string[]>{
        return this.xrdService.search.oneEntitySearchCategoryToRuleThemAll(entityId, {}, true).pipe(map((categories: XRDEntityCategory)=>{
            let availableCategories = [];
            if(!categories) return [];
            categories.departments.forEach((dep, i)=>{
                dep.categories.forEach((cat, ii)=>{
                    availableCategories.push(cat.name);
                })
            })
            return availableCategories.sort();
        }));
    }

    populateFooterData(entity: XRDEntity){
        if(entity.hours && entity.hours.Hours){
            this.storeHours = entity.hours.Hours;
        }else{
            this.storeHours = undefined;
        }
        if(entity.phones.length){
            this.storePhone = entity.phones[0].phonenumber;
        }else{
            this.storePhone = undefined;
        }
        if(entity.emails.length){
            this.storeEmail = entity.emails[0].emailaddress;
        }else{
            this.storeEmail = undefined;
        }
        this.storeNameForFooter = entity.name;
    }
}
