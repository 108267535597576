import {Injectable, Inject, PLATFORM_ID} from "@angular/core";
import {DOCUMENT, isPlatformBrowser} from "@angular/common";
import {NavigationStart, Router} from "@angular/router";
import { XRDEntityService } from "./xrd/entity/xrd-entity.service";

@Injectable()
export class GlobalEventsService {
    // For MainHeaderSearchComponent
    // public useStoreFilter: EventEmitter<boolean> = new EventEmitter<boolean>();

    /** Global variables for main search and filter component */
    mainSearchFilter: any = 'All';
    mainSearchQuery: string = '';

    /** makes sub-routes full screen for the admin side of the app */
    inAdminSide: boolean;
    /** Flag to use different header during checkout */
    showCheckoutHeader: boolean = false;

    /** Flag to use different header on resource pages */
    showResourceHeader: boolean = false;

    /** Flag to hide main header (used on retailer review page) */
    hideHeader: boolean = false;

    /** Flag to show progress bar */
    showProgressBar: boolean = false;

    /** Shows or hides special deal banner */
    showSpecialDealBanner: boolean = true;

    /** Shows the items in the cart (triggered on click and when first item is added) */
    showCartPopover:{value: boolean} = { value: false } as {value: boolean};

    /** for LoadingChunkErrorHandler in case user is on old version of app */
    routeToGoTo: string;

    /** Main footer element. Used in the max-height-scroll-applier-directive */
    mainFooter: any;

    /** main media query width for mobile */
    MOBILEWIDTH: number = 700;

    constructor(private router: Router,
                private xrdEntityService: XRDEntityService,
                @Inject(PLATFORM_ID) protected platformId: Object,
                @Inject(DOCUMENT) private document: any) {
        router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationStart)) {
                return;
            }
            this.routeToGoTo =  evt.url;
        });
    }

    stripNewlines(str):string{
        return str.replace(/\\n/g, '\n');
    }

    /**
     * Handles auto scrolling for menu items
     * (scrolls the menu items into view if they are hidden)
     */
    showMenuItem(elementId, parentScrollerId){
        // Initial variables
        let theElement = this.document.querySelector('#' + elementId);
        let theScrollerElement = this.document.querySelector('#' + parentScrollerId);
        let topLeftCornerHiddenAmount = theElement.offsetLeft - (theScrollerElement.clientWidth + theScrollerElement.scrollLeft);

        // The amount of pixels that are hidden from theElement in theScrollerElement
        let scrollLeftAmount = topLeftCornerHiddenAmount + theElement.clientWidth;
        let scrollRightAmount = theScrollerElement.scrollLeft - theElement.offsetLeft;

        // Smoothly scroll the required amount of pixels to show the menu item
        if(scrollLeftAmount > 0){
            theScrollerElement.scrollBy({
                top: 0,
                left: scrollLeftAmount,
                behavior: 'smooth'
            });
        }else if(scrollRightAmount > 0){
            theScrollerElement.scrollBy({
                top: 0,
                left: -scrollRightAmount,
                behavior: 'smooth'
            });
        }
    }

    /**
     * RESOURCES:
     * http://iamdustan.com/smoothscroll/
     * https://stackoverflow.com/a/42982811/5758986
     * Fix for ALL browsers = must explicitly give behavior, block, and inline
     */
    goTo(location): void {
        if(this.isRunOnBrowser()){
            this.document.querySelector('#' + location).scrollIntoView(
                { behavior: 'smooth' , block: 'start', inline: 'nearest'}
            );
        }
    }

    isRunOnBrowser(){
        return isPlatformBrowser(this.platformId);
    }

    /**
     * Returns the child most route as a string
     */
    snagChildMostRoute(): string{
        let temp = this.router.url.split('/').pop();
        if(temp.indexOf(';') != -1){
            return temp.substring(0, temp.indexOf(';'));
        }else return temp;
    }

    /**
     * Returns whether or not an HTML5 form is valid
     */
    isFormValid(formElement){
        let inputElements = formElement.getElementsByTagName('input');
        for(let i=0; i<inputElements.length; i++){
            if(inputElements[i].checkValidity() === false){
                return false;
            }
        }
        return true;
    }

    getMainHeaderHeight(): number{
        return this.document.querySelector('xion-main-header>header').offsetHeight;
    }
}
