import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MainHeaderGreetingComponent} from "./main-header-greeting.component";
import {RouterModule} from "@angular/router";

@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
    declarations: [
        MainHeaderGreetingComponent
    ],
    exports: [
        MainHeaderGreetingComponent
    ]
})
export class MainHeaderGreetingModule { }
