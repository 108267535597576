import { Injectable } from '@angular/core';
import {FadeOutNotification} from "./fade-out-notification.class";

@Injectable()
export class FadeOutNotificationService{
    public notifications: FadeOutNotification[] = [];
    public fadeOutNotification: FadeOutNotification;

    /** Sets the top of the fadeout component and the scrollTo */
    public top: number;

    /**
     * Push a new notification object onto the stack
     */
    public addNotification(message: string, theme?: string, displayTime?: number) {
        this.notifications.unshift(
            new FadeOutNotification( message, theme, displayTime )
        );
    }
}
