import {Injectable, Inject}       from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
}                           from '@angular/router';
import {SESSION_SERVICE_TOKEN} from "./shared/session-service/index";
import {SessionService} from "./shared/session-service/session.service.interface";
import {GlobalEventsService} from "./shared/global-events.service";

@Injectable()
export class AuthGuard implements CanActivate {
    private sessionService: SessionService;
    public redirectUrl: string;
    public usingAuthGuardUrl: boolean = false;

    constructor(@Inject(SESSION_SERVICE_TOKEN) sessionService: SessionService,
                private gesService: GlobalEventsService,
                private router: Router) {
        this.sessionService = sessionService;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let url: string = state.url;

        return this.checkLogin(url);
    }

    checkLogin(url: string): boolean {
        // User is logged in :)
        if(this.sessionService.isUserSignedIn()) {
            this.redirectUrl = undefined;
            this.usingAuthGuardUrl = false;
            return true;
        }

        // Catch for server-side never being able to go to AuthGuarded routes. Show loader temporarily.
        if(!this.gesService.isRunOnBrowser()) {
            console.log('route-initializing url:', url);
            this.router.navigate(['/route-initializing', {'goingTo':url} ]);
            return false;
        }

        // Store the attempted URL for redirecting
        this.redirectUrl = url;
        this.usingAuthGuardUrl = true;

        // As per Dan's request for the Sell page or Checkout default to create-account page
        if(url === '/create-new-store' || url === '/checkout/confirm'){
            this.router.navigate(['/create-account']);
        }else{
            this.router.navigate(['/sign-in']);
        }
        return false;
    }
}
