import { GlobalEventsService } from './../global-events.service';
import {Component, OnInit, ViewChild} from '@angular/core';
import { GlobalProductPageEventsService } from '../global-product-page-events.service';
import { GlobalStoreEventsService } from '../global-store-events.service';

@Component({
    selector: 'xion-main-footer',
    templateUrl: './main-footer.component.html',
    styleUrls: [ './main-footer.component.css' ],
})
export class MainFooterComponent implements OnInit {

    @ViewChild('mainFooter', { static: true }) mainFooter;

    constructor(public gseService: GlobalStoreEventsService,
                private geService: GlobalEventsService,
                public gppeService: GlobalProductPageEventsService){}

    ngOnInit() {
        // console.log('MainFooterComponent:ngOnInit()');
        this.geService.mainFooter = this.mainFooter;
    }

}
