import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PopoverComponent} from "../popover/popover.component";
import {MainHeaderComponent} from "./main-header.component";
import {MainHeaderCartComponent} from "./main-header-cart/main-header-cart.component";
import {MainHeaderSearchFilterComponent} from "./main-header-search/main-header-search-filter/main-header-search-filter.component";
import { RotatingMessagesComponent } from './main-header-rotating-messages/rotating-messages/rotating-messages.component';
import { MainHeaderRotatingMessagesComponent } from './main-header-rotating-messages/main-header-rotating-messages.component';
import {SharedModule} from "../shared.module";
import {MainHeaderGreetingModule} from "./main-header-greeting/main-header-greeting.module";
import {MainHeaderYourAccountModule} from "./main-header-your-account/main-header-your-account.module";
import {MainHeaderSearchModule} from "./main-header-search/main-header-search.module";
import {MainHeaderNavDropdownMenuModule} from "./main-header-nav-dropdown/main-header-nav-dropdown-menu/main-header-nav-dropdown-menu.module";
import {MainHeaderNavDropdownComponent} from "./main-header-nav-dropdown/main-header-nav-dropdown.component";
import {MainHeaderNavDropdownMobileMenuModule} from "./main-header-nav-dropdown/main-header-nav-dropdown-mobile-menu/main-header-nav-dropdown-mobile-menu.module";
import {CartItemSummaryModule} from "../../marketplace/shared/cart-items-summary/cart-item-summary/cart-item-summary.module";
import { MainHeaderSpecialDealBannerComponent } from './main-header-special-deal-banner/main-header-special-deal-banner.component';
import {OrderSummaryBoxModule} from "../order-summary-box/order-summary-box.module";
import { MainHeaderXionMenuComponent } from './main-header-xion-menu/main-header-xion-menu.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MainHeaderGreetingModule,
        MainHeaderYourAccountModule,
        MainHeaderSearchModule,
        CartItemSummaryModule,
        MainHeaderNavDropdownMenuModule,
        MainHeaderNavDropdownMobileMenuModule,
        OrderSummaryBoxModule,
    ],
    declarations: [
        PopoverComponent,
        MainHeaderComponent,
        MainHeaderCartComponent,
        MainHeaderSearchFilterComponent,
        RotatingMessagesComponent,
        MainHeaderRotatingMessagesComponent,
        MainHeaderNavDropdownComponent,
        MainHeaderSpecialDealBannerComponent,
        MainHeaderXionMenuComponent,
    ],
    exports: [
        PopoverComponent,
        MainHeaderComponent,
        MainHeaderCartComponent,
        MainHeaderSearchFilterComponent,
        CartItemSummaryModule,
        MainHeaderNavDropdownComponent,
    ]
})
export class HeaderModule { }
