import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'xion-user-action-box',
    templateUrl: './user-action-box.component.html',
    styleUrls: ['./user-action-box.component.css']
})
export class UserActionBoxComponent implements OnInit {

    @Input() image: string;
    @Input() title: string;
    @Input() description: string;

    showDescription: boolean = false;

    constructor() { }

    ngOnInit() {
    }

}
