<div xionHorizontalScroller
     (scrollInitiated)="homeService.horizontalScrollInitiated.next()"
     (showLeftUpdate)="showLeft=$event"
     (showRightUpdate)="showRight=$event"
     [ngStyle]="{ 'padding': padding?padding: '10px'}"
     class="horizontal-scroller"
     #scrollableContainer>

    <ng-content></ng-content>

</div>

<xion-horizontal-scroller class="non-mobile"
                          [showLeft]="showLeft"
                          [showRight]="showRight"
                          [elementToScroll]="scrollableContainer">
</xion-horizontal-scroller>
