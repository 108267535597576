import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ExpiredSessionLoginComponent} from "./expired-session-login.component";
import {CustomNgMaterialsModule} from "../custom-ng-materials/custom-ng-materials.module";
import {LoaderModule} from "../loader/loader.module";
import {ModalModule} from "../modal/modal.module";
import {FormsModule} from "@angular/forms";
import {SharedModule} from "../shared.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    declarations: [
        ExpiredSessionLoginComponent,
    ],
    exports: [
        ExpiredSessionLoginComponent,
    ]
})
export class ExpiredSessionLoginModule { }
