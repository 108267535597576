<!--<xion-main-header-special-deal-banner *ngIf="globalEventsService.showSpecialDealBanner"></xion-main-header-special-deal-banner>-->

<header>

    <a [routerLink]="['']" id="main-logo" class="block">
        <img src="/assets/img/new_logos/XION_store_shop_local_online_website_logo.png">
    </a>

    <xion-main-header-nav-dropdown></xion-main-header-nav-dropdown>

    <xion-main-header-search #mainSearch
                             (searchThis)="doSearch()"
                             [filterText]="globalEventsService.mainSearchFilter"
                             [(inputText)]="globalEventsService.mainSearchQuery"
                             [filterDropdownWidth]="gseService.currentStoreCategories?'465px':'auto'"
                             [placeholderText]="placeholderText">
        <xion-main-header-search-filter
            (initiateSearch)="doSearch()"
            (focusOnInput)="mainSearch.focusOnInput()"
            class="non-mobile">
        </xion-main-header-search-filter>
    </xion-main-header-search>

    <xion-main-header-rotating-messages></xion-main-header-rotating-messages>

    <div class="greeting">
        <xion-main-header-greeting></xion-main-header-greeting>
        <xion-main-header-your-account></xion-main-header-your-account>
    </div>

    <xion-main-header-xion-menu class="non-mobile"></xion-main-header-xion-menu>

    <xion-main-header-cart></xion-main-header-cart>

</header>
