import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MainFooterComponent} from "./main-footer.component";
import {SharedModule} from "../shared.module";
import {StoreHoursDisplayListModule} from "../../marketplace/store/store-home/store-hours/store-hours-display-list/store-hours-display-list.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        StoreHoursDisplayListModule,
    ],
    declarations: [
        MainFooterComponent
    ],
    exports: [
        MainFooterComponent
    ]
})
export class MainFooterModule { }
